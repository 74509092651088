import { compose, withHooks } from "enhancers";
import {
  Box,
  Grid,
  Typography,
  Form,
  Field,
  TextField,
  Button,
  Modal,
  Select,
} from "components";
import { ReactComponent as TrashIcon } from "assets/icon/trash_icon.svg";
import { FieldArray } from "formik";
import { getErrorMessage, notifyError, paths, gql } from "utils/helper";
import { fishDepreciationList } from "common/fishDepreciation";
import { find, uniqWith, isEqual } from "lodash";

const boxStyle = {
  height: "100%",
};

const iconStyle = {
  width: "20px",
  height: "20px",
  cursor: "pointer",
};

const FishDepreciation = (props) => (
  <Form>
    <Box display="flex" flex={1} flexDirection="row" fullWidth>
      <Grid item xs={12}>
        <Typography variant="h4" mb={6}>
          ข้อมูลราคารับซื้อและค่าเสื่อมปลา
        </Typography>

        <Grid container spacing={6} mb={6}>
          <Grid item xs={4}>
            <Typography variant="h5" pl={4}>
              ชื่อปลา
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="h5">ราคารับซื้อ/กก.</Typography>
          </Grid>
          {fishDepreciationList.map((item) => {
            return (
              <Grid item xs={1}>
                <Typography variant="h5">{item.label}</Typography>
              </Grid>
            );
          })}
          <Grid item xs={1}></Grid>

          <Grid
            item
            xs={12}
            style={{
              borderBottom: "1px solid #E0E0E0",
              padding: 0,
              margin: "0px 12px",
            }}
          ></Grid>
        </Grid>

        <FieldArray name="fishDepreciation" render={props.renderArray} />
      </Grid>
    </Box>
  </Form>
);

const API = {
  FETCH_FISHES: gql`
    query FETCH_FISHES {
      fishes {
        id
        code
        name
        availableStatus
      }
    }
  `,
};

const enhancer = compose(
  withHooks((props, hooks) => {
    const { fishDepreciation } = props;
    const { useMemo, useQuery, useEffect, useCallback, useState } = hooks;
    const { loading, data, error, refetch } = useQuery(API.FETCH_FISHES);

    const [fishDepList, setFishDepList] = useState([]);

    useEffect(() => {
      refetch();
    }, [refetch]);

    const fishByStatusOptions = useMemo(() => {
      if (loading) {
        return null;
      }
      if (error) {
        const message = getErrorMessage(error);
        notifyError(message);
        paths.fishManagementPath().push();
        return null;
      }

      const fishList = data.fishes
        .filter((fish) => fish.availableStatus === true)
        .map((fb, index) => {
          return {
            label: fb.name,
            value: fb.id,
          };
        });

      return fishList;
    }, [loading, data, error]);

    const fishOptions = useMemo(() => {
      if (loading) {
        return null;
      }
      if (error) {
        const message = getErrorMessage(error);
        notifyError(message);
        paths.fishManagementPath().push();
        return null;
      }

      const fishList = data.fishes.map((fb, index) => {
        return {
          label: fb.name,
          value: fb.id,
        };
      });

      return fishList;
    }, [loading, data, error]);

    const fishCustomOptions = useCallback(
      (fishDep) => {
        if (fishByStatusOptions) {
          let fishArray = [...fishByStatusOptions];
          if (fishDep) {
            fishArray.push(fishDep?.fishId?.label);
          }
          const uniqueFishArray = uniqWith(fishArray, isEqual);
          return uniqueFishArray;
        }
      },
      [fishByStatusOptions]
    );

    // const fishDepList = useMemo(() => {
    //   let response = [];
    //   if (fishDepreciation)
    //     response = fishDepreciation.map((item, index) => {
    //       return {
    //         fishId: {
    //           label: find(fishOptions, { value: item.fishId }),
    //           value: item.fishId,
    //         },
    //         gradeADepreciationPct: item.gradeADepreciationPct,
    //         gradeBDepreciationPct: item.gradeBDepreciationPct,
    //         gradeCDepreciationPct: item.gradeCDepreciationPct,
    //         gradeDDepreciationPct: item.gradeDDepreciationPct,
    //         purchasePrice: item.purchasePrice,
    //       };
    //     });

    //   return response;
    // }, [fishDepreciation, fishOptions]);

    // useEffect(() => {
    //   const t0 = performance.now();
    //   console.log("#1", t0);

    //   let response = [];
    //   if (fishDepreciation)
    //     response = fishDepreciation.map((item, index) => {
    //       return {
    //         fishId: {
    //           label: find(fishOptions, { value: item.fishId }),
    //           value: item.fishId,
    //         },
    //         gradeADepreciationPct: item.gradeADepreciationPct,
    //         gradeBDepreciationPct: item.gradeBDepreciationPct,
    //         gradeCDepreciationPct: item.gradeCDepreciationPct,
    //         gradeDDepreciationPct: item.gradeDDepreciationPct,
    //         purchasePrice: item.purchasePrice,
    //       };
    //     });
    //   const t1 = performance.now();
    // }, [JSON.stringify(fishDepreciation), JSON.stringify(fishOptions)]);

    const deleteFishDepreciation = useCallback((data, arrayHelpers, index) => {
      const { fishId } = data || {};
      const { label } = fishId || {};
      Modal.open({
        title: `ลบข้อมูลค่าเสื่อม ${label?.label ?? ""}`,
        children: `ข้อมูลจะไม่สามารถกู้คืนได้`,
        cancelButtonLabel: "ปิด",
        okButtonLabel: "ลบข้อมูล",
        onOk: async ({ close }) => {
          close();
          arrayHelpers.remove(index);
        },
      });
    }, []);

    useEffect(() => {
      let response = [];
      if (fishDepreciation)
        response = fishDepreciation.map((item, index) => {
          return {
            fishId: {
              label: find(fishOptions, { value: item.fishId }),
              value: item.fishId,
            },
            gradeADepreciationPct: item.gradeADepreciationPct,
            gradeBDepreciationPct: item.gradeBDepreciationPct,
            gradeCDepreciationPct: item.gradeCDepreciationPct,
            gradeDDepreciationPct: item.gradeDDepreciationPct,
            purchasePrice: item.purchasePrice,
          };
        });

      // return response;
      setFishDepList(response);
    }, [JSON.stringify(fishDepreciation), fishOptions]);

    const getFieldName = useCallback((index, fieldName) => {
      return `fishDepreciation[${index}].${fieldName}`;
    }, []);

    const renderArray = useCallback(
      (arrayHelpers) => {
        return (
          <div>
            {fishDepList && fishDepList.length > 0 ? (
              fishDepList.map((fb, index) => (
                <Grid container spacing={6} mb={6}>
                  <Grid item xs={4} pr="6px !important">
                    <Field
                      component={Select}
                      name={getFieldName(index, "fishId")}
                      type="text"
                      label=""
                      options={fishCustomOptions(fb ?? null)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={3} pr="6px !important" pl="6px !important">
                    <Field
                      component={TextField}
                      type="number"
                      name={getFieldName(index, "purchasePrice")}
                      label=""
                      fullWidth
                      unit="บาท"
                    />
                  </Grid>
                  <Grid item xs={1} pr="6px !important" pl="6px !important">
                    <Field
                      component={TextField}
                      type="number"
                      fullWidth
                      name={getFieldName(index, "gradeADepreciationPct")}
                      label=""
                      unit="%"
                    />
                  </Grid>
                  <Grid item xs={1} pr="6px !important" pl="6px !important">
                    <Field
                      component={TextField}
                      type="number"
                      fullWidth
                      name={getFieldName(index, "gradeBDepreciationPct")}
                      label=""
                      unit="%"
                    />
                  </Grid>
                  <Grid item xs={1} pr="6px !important" pl="6px !important">
                    <Field
                      component={TextField}
                      type="number"
                      fullWidth
                      name={getFieldName(index, "gradeCDepreciationPct")}
                      label=""
                      unit="%"
                    />
                  </Grid>
                  <Grid item xs={1} pr="6px !important" pl="6px !important">
                    <Field
                      component={TextField}
                      type="number"
                      fullWidth
                      name={getFieldName(index, "gradeDDepreciationPct")}
                      label=""
                      unit="%"
                    />
                  </Grid>
                  <Grid item xs={1} pl="6px !important">
                    <Box
                      display="flex"
                      flex={1}
                      alignItems="center"
                      fullWidth
                      style={boxStyle}
                    >
                      <TrashIcon
                        name={getFieldName(index, "delete")}
                        style={iconStyle}
                        onClick={() =>
                          deleteFishDepreciation(fb, arrayHelpers, index)
                        }
                      />
                    </Box>
                  </Grid>
                </Grid>
              ))
            ) : (
              <Typography
                variant="body1"
                display="flex"
                alignItems="center"
                justifyContent="center"
                mb={6}
              >
                ไม่มีรายการค่าเสื่อมปลา กรุณาเพิ่มรายการ
              </Typography>
            )}
            <Grid
              item
              xs={12}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Button
                startIcon="add"
                width={171}
                onClick={() => arrayHelpers.push({})}
              >
                เพิ่มรายการ
              </Button>
            </Grid>
          </div>
        );
      },
      [getFieldName, deleteFishDepreciation, fishDepList, fishCustomOptions]
    );

    return {
      // fishOptions,
      // fishDepList,
      // fishCustomOptions,
      // deleteFishDepreciation,
      renderArray,
    };
  })
);

export default enhancer(FishDepreciation);

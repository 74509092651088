import {
  Box,
  Button,
  DatePicker,
  Field,
  Grid,
  Select,
  Table,
} from "components";
import { compose, withHooks, withFormik } from "enhancers";
import { find, isEmpty } from "lodash";
import { gql } from "utils/helper";

const boxStyle = { background: "#F6F9FC", borderRadius: 8 };

const FactoryFishDemandModal = (props) => (
  <>
    <Box style={boxStyle} p={4} mt={0}>
      <Grid container spacing={6} mb={0}>
        <Grid item xs={12}>
          <Field
            component={Select}
            name="factoryName"
            type="text"
            label="รหัส/ชื่อโรงงาน"
            options={props.factoryOptions}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            component={Select}
            name="fishName"
            type="text"
            label="ชนิดปลา"
            options={props.fishOptions}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            component={Select}
            name="orderBy"
            type="text"
            label="เรียงตาม"
            options={props.sort}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            component={DatePicker}
            name="startDate"
            label="ช่วงวันส่งปลาตั้งแต่วันที่"
            placeholder=""
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            component={DatePicker}
            name="endDate"
            label="ถึงวันที่"
            placeholder=""
            fullWidth
          />
        </Grid>
      </Grid>

      <Box display="flex" alignItems="center" fullWidth mb={2}>
        <Box display="flex" flex={1} flexDirection="row" fullWidth></Box>
        <Button
          color=""
          mt={6}
          mr={4}
          width={125}
          onClick={props.useResetFilter}
        >
          รีเซ็ตตัวกรอง
        </Button>
        <Button
          className="FactoryFishDemandModalFilterButton"
          onClick={props.useHandleFilter}
          color="primary"
          mt={6}
          width={125}
        >
          กรอง
        </Button>
      </Box>
    </Box>
    <Table
      className="FactoryFishDemandTable"
      columns={props.columns}
      rows={props.tableData}
      loading={props.loading}
      density="compact"
      autoHeight
      disableSelectionOnClick
      displaySelectAll={false}
      onSelectionModelChange={props.onSelectionChange}
      isRowSelectable={props.handleRowSelectable}
      keepNonExistentRowsSelected={true}
      selectionModel={props.selectedId ?? []}
      checkboxSelection={true}
      rowCount={props.rowCount}
      initialFilter={{
        items: [],
      }}
    />
  </>
);

export const API = {
  FACTORY_FISH_DEMANDS_BY_FILTER: gql`
    query FACTORY_FISH_DEMANDS_BY_FILTER($filters: JSON) {
      factoryFishDemandsByFilter(filters: $filters) {
        id
        factory {
          id
          name
          phoneNumber
          remark
          googleMapLink
          availableStatus
          address {
            address
            district
            subDistrict
            province
            zipCode
          }
          fishDepreciation {
            fishId
            gradeADepreciationPct
            gradeBDepreciationPct
            gradeCDepreciationPct
            gradeDDepreciationPct
            purchasePrice
          }
        }
        fish {
          id
          name
          availableStatus
        }
        purchasePrice
        minPurchaseVolume
        maxPurchaseVolume
        fishDeliverDate
      }
    }
  `,
};

const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({
      factoryName: null,
      fishName: null,
      orderBy: "amountMoreToLess",
      startDate: null,
      endDate: null,
    }),
  }),
  withHooks((props, hooks) => {
    const { useQuery, useMemo, useEffect, useCallback, useState } = hooks;
    const {
      setSelectedData,
      values,
      setValues,
      factoryFishDemandData,
      fishData,
      factoryData,
    } = props;
    const { loading, data, error, refetch } = useQuery(
      API.FACTORY_FISH_DEMANDS_BY_FILTER,
      {
        variables: {
          filters: { orderBy: "amountMoreToLess" },
        },
      }
    );

    const { id: factoryId } = factoryFishDemandData || {};

    const [selectedId, setSelectedId] = useState(factoryId);

    const columns = useMemo(() => {
      return [
        { width: 180, field: "factoryName", headerName: "ชื่อโรงงาน" },
        { width: 150, field: "fishName", headerName: "ชนิดปลา" },
        {
          width: 130,
          field: "amount",
          headerName: "ปริมาณ",
          type: "currency",
        },
        {
          width: 150,
          type: "dateOnly",
          field: "receiveDate",
          headerName: "วันที่รับปลา",
        },
      ];
    }, []);

    useEffect(() => {
      refetch();
    }, [refetch]);

    const factoryOptions = useMemo(() => {
      return factoryData?.factories.map((factory) => {
        const { id, name } = factory;

        const label = `${name}`;

        return {
          value: id,
          label,
        };
      });
    }, [factoryData]);

    const fishOptions = useMemo(() => {
      return fishData?.fishes.map((fish) => {
        const { id, name } = fish;

        return {
          value: id,
          label: name,
        };
      });
    }, [fishData]);

    const sort = [
      {
        value: "amountMoreToLess",
        label: "จำนวนที่ต้องการจากมากไปน้อย ",
      },
      {
        value: "amountLessToMore",
        label: "จำนวนที่ต้องการจากน้อยไปมาก ",
      },
      {
        value: "newFishDeliverDate",
        label: "วันที่ต้องการจากล่าสุด ",
      },
      {
        value: "oldFishDeliverDate",
        label: "วันที่ต้องการจากเก่าสุด ",
      },
    ];

    const useResetFilter = useCallback(async () => {
      setValues({
        factoryName: null,
        fishName: null,
        orderBy: "amountMoreToLess",
        startDate: null,
        endDate: null,
      });
      refetch({
        filters: {
          factoryId: null,
          fishId: null,
          orderBy: "amountMoreToLess",
          startDate: null,
          endDate: null,
        },
      });
      setSelectedId(null);
      setSelectedData(undefined);
    }, [refetch, setValues, setSelectedData, setSelectedId]);

    const handleRowSelectable = useCallback(
      (params) => {
        return !selectedId || params.row.id === selectedId;
      },
      [selectedId]
    );

    const useHandleFilter = useCallback(async () => {
      await refetch({
        filters: {
          factoryId: values.factoryName,
          fishId: values.fishName,
          orderBy: values.orderBy,
          startDate: values.startDate,
          endDate: values.endDate,
        },
      });
      setSelectedId(null);
      setSelectedData(undefined);
    }, [values, refetch, setSelectedData, setSelectedId]);

    const tableData = useMemo(() => {
      if (loading || error) {
        return [];
      }

      return data.factoryFishDemandsByFilter
        .filter(
          (factoryFishDemand) =>
            factoryFishDemand.factory?.availableStatus === true &&
            factoryFishDemand.fish?.availableStatus === true
        )
        .map((factoryFishDemand) => {
          const {
            id,
            factory,
            fish,
            purchasePrice,
            minPurchaseVolume,
            maxPurchaseVolume,
            fishDeliverDate,
          } = factoryFishDemand;

          const addressInfo = `
          ${factory?.address?.address ?? ""} 
          ต.${factory?.address?.district ?? ""} 
          อ.${factory?.address?.subDistrict ?? ""} 
          จ.${factory?.address?.province ?? ""} 
          ${factory?.address?.zipCode ?? ""}`;
          return {
            id,
            factoryId: factory.id,
            factoryPhoneNumber: factory.phoneNumber,
            factoryName: factory.name,
            address: addressInfo,
            remark: factory.remark,
            googleMapLink: factory.googleMapLink,
            fishId: fish.id,
            fishName: "ปลา" + fish.name,
            purchasePrice,
            minPurchaseVolume,
            amount: maxPurchaseVolume,
            receiveDate: fishDeliverDate,
            factory: factory,
            fish: fish,
          };
        });
    }, [loading, data, error]);

    const onSelectionChange = useCallback(
      (id) => {
        const selectedId = Array.isArray(id) ? id[0] : id;
        const factoryFishDemand = find(tableData, { id: selectedId });
        const factoryFishDemandDataIdOrNull = !isEmpty(factoryFishDemand)
          ? factoryFishDemand?.id
          : null;
        const factoryFishDemandDataOrNull = !isEmpty(factoryFishDemand)
          ? factoryFishDemand
          : null;

        setSelectedId(factoryFishDemandDataIdOrNull);
        setSelectedData(factoryFishDemandDataOrNull);

        // const factoryFishDemand = find(tableData, { id: id[0] });

        // if (!id.length) {
        //   console.log("Gotcha#1");
        //   setSelectedId(null);
        //   setSelectedData(null);
        // }
        // if (!isEmpty(factoryFishDemand)) {
        //   console.log("Gotcha#2");
        //   setSelectedId(factoryFishDemand?.id);
        //   setSelectedData(factoryFishDemand);
        // }

        // else {
        //   console.log("Gotcha#1 set null");
        //   setSelectedId(null);
        //   setSelectedData(null);
        // }
      },
      [tableData, setSelectedData, setSelectedId]
    );

    useEffect(() => {
      if (!factoryId) {
        return;
      }

      onSelectionChange(factoryId);
    }, [factoryId, onSelectionChange]);

    return {
      tableData,
      onSelectionChange,
      factoryOptions,
      fishOptions,
      selectedId,
      sort,
      loading: loading,
      useHandleFilter,
      useResetFilter,
      columns,
      handleRowSelectable,
    };
  })
);

export default enhancer(FactoryFishDemandModal);

import { connect } from "react-redux";
import deepmerge from "deepmerge";

import appStore from "stores/appStore";

const storeMapping = {
  appStore,
};

const withStores = (mapStoresToProps) =>
  connect((state, props) => {
    const stores = deepmerge(storeMapping, state.data, { clone: false });
    return mapStoresToProps(stores, props);
  });

export default withStores;

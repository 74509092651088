import { compose, withHooks, withFormik } from "enhancers";
import { PageContent } from "layouts";
import { Modal, Typography, Box } from "components";
import {
  gql,
  notifyError,
  notifySuccess,
  paths,
  cleanTypename,
  getErrorMessage,
  Yup,
} from "utils/helper";
import { FishermanForm } from "./new";

const FishermanEditPage = (props) => (
  <PageContent title={props.title} breadcrumbs={props.breadcrumbs}>
    <FishermanForm
      fishermanAvailableStatus={props.fishermanAvailableStatus}
      googleMapLink={props.googleMapLink}
      handleGoogleMapButtonClick={props.handleGoogleMapButtonClick}
      handleCancel={props.handleCancel}
      submit={props.submit}
      submitButtonLabel={props.submitButtonLabel}
      cancelButtonLabel={props.cancelButtonLabel}
      fishOptions={props.fishOptions}
    />
  </PageContent>
);

const API = {
  FETCH_FISHES: gql`
    query FETCH_FISHES {
      fishes {
        id
        code
        name
        factoryPurchasePricePct
        remark
        availableStatus
      }
    }
  `,
  FETCH_FISHERMAN: gql`
    query FETCH_FISHERMAN($id: ID!) {
      fisherman(id: $id) {
        id
        code
        name
        phoneNumber
        lineId
        remark
        googleMapLink
        availableStatus
        affordableFish
        address {
          address
          district
          subDistrict
          province
          zipCode
        }
        bankAccount {
          bankAccountName
          bankAccountNumber
          bankName
        }
      }
    }
  `,
  UPDATE_FISHERMAN: gql`
    mutation UPDATE_FISHERMAN(
      $id: String!
      $name: String
      $phoneNumber: String
      $lineId: String
      $remark: String
      $googleMapLink: String
      $availableStatus: Boolean
      $affordableFish: [String!]
      $address: AddressArguments
      $bankAccount: BankAccountArguments
    ) {
      updateFisherman(
        input: {
          id: $id
          name: $name
          phoneNumber: $phoneNumber
          lineId: $lineId
          remark: $remark
          googleMapLink: $googleMapLink
          availableStatus: $availableStatus
          affordableFish: $affordableFish
          address: $address
          bankAccount: $bankAccount
        }
      ) {
        fisherman {
          id
          name
        }
      }
    }
  `,
};

const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({
      // fishermanStatus: "true",
      // googleMapLink: null,
    }),
    validationSchema: Yup.object().shape({
      name: Yup.string().required("ต้องไม่เว้นว่างไว้"),
      phoneNumber: Yup.string().required("ต้องไม่เว้นว่างไว้"),
      googleMapLink: Yup.string().required("ต้องไม่เว้นว่างไว้"),
      address: Yup.object().shape({
        address: Yup.string().required("ต้องไม่เว้นว่างไว้"),
        district: Yup.string().required("ต้องไม่เว้นว่างไว้"),
        subDistrict: Yup.string().required("ต้องไม่เว้นว่างไว้"),
        province: Yup.string().required("ต้องไม่เว้นว่างไว้"),
        zipCode: Yup.string().required("ต้องไม่เว้นว่างไว้"),
      }),
    }),
  }),
  withHooks((props, hooks) => {
    const { setValues, values, errors } = props;
    const {
      useMemo,
      useHandleSubmit,
      useQuery,
      useMutation,
      useEffect,
      useParams,
      useCallback,
    } = hooks;

    const { id } = useParams();

    const { loading, data, error, refetch } = useQuery(API.FETCH_FISHERMAN, {
      variables: { id },
    });
    const {
      loading: fishLoading,
      data: fishData,
      error: fishError,
      refetch: fishRefetch,
    } = useQuery(API.FETCH_FISHES);
    const [updateFisherman] = useMutation(API.UPDATE_FISHERMAN);

    useEffect(() => {
      refetch();
    }, [refetch]);

    const fisherman = useMemo(() => {
      if (loading) {
        return null;
      }
      if (error) {
        const message = getErrorMessage(error);
        notifyError(message);
        paths.fishermenPath().push();
        return null;
      }
      return data.fisherman;
    }, [loading, data, error]);

    const title = fisherman?.code;
    const breadcrumbs = useMemo(() => {
      return [
        { path: paths.homePath(), label: "หน้าแรก" },
        { path: paths.fishermenPath(), label: "จัดการชาวประมง" },
        { path: null, label: title },
      ];
    }, [title]);

    const fishOptions = useMemo(() => {
      if (fishLoading) {
        return null;
      }
      if (fishError) {
        const message = getErrorMessage(fishError);
        notifyError(message);
        return null;
      }

      const fishList = fishData.fishes.map((fb, index) => {
        return {
          label: fb.name,
          value: fb.id,
        };
      });

      return fishList;
    }, [fishLoading, fishData, fishError]);

    useEffect(() => {
      if (fisherman) {
        const values = cleanTypename(fisherman);
        values.fishermanStatus = values.availableStatus.toString();
        setValues(values);
      }
    }, [setValues, fisherman]);

    const isDirty = useCallback((values, fisherman) => {
      if (
        values.availableStatus !== fisherman.availableStatus ||
        values.name !== fisherman.name ||
        values.phoneNumber !== fisherman.phoneNumber ||
        values.lineId !== fisherman.lineId ||
        values.remark !== fisherman.remark ||
        values.googleMapLink !== fisherman.googleMapLink
      )
        return true;
      if (fisherman.address && values.address) {
        if (
          values.address.address !== fisherman.address.address ||
          values.address.district !== fisherman.address.district ||
          values.address.subDistrict !== fisherman.address.subDistrict ||
          values.address.province !== fisherman.address.province ||
          values.address.zipCode !== fisherman.address.zipCode
        )
          return true;
      }
      if (fisherman.bankAccount && values.bankAccount) {
        if (values.bankAccount.bankName !== fisherman.bankAccount.bankName)
          return true;
        if (
          values.bankAccount.bankAccountName !==
          fisherman.bankAccount.bankAccountName
        )
          return true;
        if (
          values.bankAccount.bankAccountNumber !==
          fisherman.bankAccount.bankAccountNumber
        )
          return true;
      } else if (!fisherman.bankAccount && values.bankAccount) {
        return true;
      }
      return false;
    }, []);

    const handleCancel = useCallback(() => {
      values.availableStatus = values.fishermanStatus === "true";
      if (isDirty(values, fisherman)) {
        Modal.open({
          title: "ยกเลิกการแก้ไขข้อมูล",
          children: (
            <Typography color="Other/Danger">
              ข้อมูลจะไม่ถูกบันทึกและไม่สามารถกู้คืนได้
            </Typography>
          ),
          cancelButtonLabel: "ปิด",
          okButtonLabel: "ยกเลิกการแก้ไขข้อมูล",
          onOk: ({ close }) => {
            close();
            paths.fishermenPath().push();
          },
        });
      } else {
        paths.fishermenPath().push();
      }
    }, [values, fisherman, isDirty]);

    const submit = useCallback(async () => {
      values.availableStatus = values.fishermanStatus === "true";
      try {
        if (Object.keys(errors).length !== 0) {
          Modal.alert({
            title: "เกิดข้อผิดพลาด",
            children: (
              <Box>
                <Typography color="Text/Dark Grey" mb={2}>
                  กรุณากรอกข้อมูลต่อไปนี้ให้ครบ
                </Typography>
                <Typography color="Other/Danger">
                  {errors.name ? " • ชื่อ-นามสกุล" : ""}
                </Typography>
                <Typography color="Other/Danger">
                  {errors.phoneNumber ? " • เบอร์โทรศัพท์" : ""}
                </Typography>
                <Typography color="Other/Danger">
                  {errors.address && errors.address.address ? " • ที่อยู่" : ""}
                </Typography>
                <Typography color="Other/Danger">
                  {errors.address && errors.address.subDistrict
                    ? " • แขวง/ตำบล"
                    : ""}
                </Typography>
                <Typography color="Other/Danger">
                  {errors.address && errors.address.district
                    ? " • เขต/อำเภอ"
                    : ""}
                </Typography>
                <Typography color="Other/Danger">
                  {errors.address && errors.address.province
                    ? " • จังหวัด"
                    : ""}
                </Typography>
                <Typography color="Other/Danger">
                  {errors.address && errors.address.zipCode
                    ? " • รหัสไปรษณีย์"
                    : ""}
                </Typography>
                <Typography color="Other/Danger">
                  {errors.googleMapLink ? " • Google map link" : ""}
                </Typography>
              </Box>
            ),
            okButtonLabel: "ปิด",
          });
        } else {
          await updateFisherman({ variables: values });
          paths.fishermenPath().push();
          notifySuccess("แก้ไขข้อมูลสำเร็จ");
        }
      } catch (e) {
        if (
          getErrorMessage(e) ===
          "Validation failed: Phone number has already been taken"
        ) {
          Modal.alert({
            title: "เกิดข้อผิดพลาด",
            children: (
              <Box>
                <Typography color="Text/Dark Grey" mb={2}>
                  {"ข้อมูลต่อไปนี้ต้องไม่ซ้ำกับที่มีอยู่ในระบบ"}
                </Typography>
                <Typography color="Other/Danger">
                  {" • เบอร์โทรศัพท์"}
                </Typography>
              </Box>
            ),
            okButtonLabel: "ปิด",
            okButtonVariant: "button",
            onOk: ({ close }) => {
              close();
              paths.fishermanEditPath(id).push();
            },
          });
        } else {
          Modal.alert({
            title: "เกิดข้อผิดพลาด",
            children: `${getErrorMessage(e)}`,
            okButtonLabel: "ปิด",
            okButtonVariant: "button",
            onOk: ({ close }) => {
              close();
            },
          });
        }
      }
    }, [values, errors, updateFisherman, id]);

    useHandleSubmit(
      async (values) => {
        values.availableStatus = values.fishermanStatus === "true";
        const closeModal = ({ close }) => {
          close();
          paths.fishermanEditPath(id).push();
        };
        try {
          await updateFisherman({ variables: values });
          paths.fishermenPath().push();
          notifySuccess("แก้ไขข้อมูลสำเร็จ");
        } catch (e) {
          Modal.alert({
            title: "เกิดข้อผิดพลาด",
            children: `${getErrorMessage(e)}`,
            okButtonLabel: "ปิด",
            okButtonVariant: "button",
            onOk: closeModal,
            onClose: closeModal,
          });
        }
      },
      [updateFisherman]
    );

    const fishermanAvailableStatus = useMemo(() => {
      return [
        { label: "ใช้งาน", value: "true" },
        { label: "ไม่ใช้งาน", value: "false" },
      ];
    }, []);

    const googleMapLink = values.googleMapLink;

    const handleGoogleMapButtonClick = useCallback(() => {
      window.open(googleMapLink, "_blank", "noreferrer");
    }, [googleMapLink]);

    return {
      title,
      breadcrumbs,
      cancelButtonLabel: "ยกเลิก",
      submitButtonLabel: "บันทึก",
      handleCancel,
      submit,
      googleMapLink,
      fishermanAvailableStatus,
      handleGoogleMapButtonClick,
      fishOptions,
    };
  })
);

export default enhancer(FishermanEditPage);

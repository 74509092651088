import React, { useEffect, useState } from "react";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { compose, withHooks, withStores } from "enhancers";
import { get } from "lodash";
import appStore from "stores/appStore";

const ExcelGeneratorComponent = ({ setExporter, ...rest }) => (
  <ExcelExport {...rest} ref={setExporter} />
);

const enhancer = compose(
  withStores((stores, props) =>
    props.storeName ? get(stores, props.storeName) : {}
  ),
  withHooks((props) => {
    const { fileName, columns, data, triggeredGenerator, onGenerated } = props;
    const [exporter, setExporter] = useState();

    useEffect(() => {
      if (triggeredGenerator) {
        exporter.save();
        if (onGenerated) {
          onGenerated();
        }
      }
    }, [exporter, triggeredGenerator, onGenerated]);

    return {
      setExporter,
      fileName,
      columns,
      data,
    };
  })
);

const ExcelGenerator = enhancer(ExcelGeneratorComponent);

ExcelGenerator.generate = appStore.generateExcel;

export default ExcelGenerator;

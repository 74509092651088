import paths from "routes/paths";

import { RouteType } from "types/routes";

// Menu Icon
import { ReactComponent as BookingIcon } from "assets/icon/menu_icon/booking.svg";
import { ReactComponent as ApartmentIcon } from "vendor/menu_icon/factory.svg";
import { ReactComponent as DirectionsBoatIcon } from "vendor/menu_icon/directions_boat.svg";
import { ReactComponent as LocalShippingIcon } from "vendor/menu_icon/local_shipping.svg";
import { ReactComponent as FishManage } from "vendor/menu_icon/manage_fish.svg";
import { ReactComponent as FactoryOrderScheduleManage } from "vendor/menu_icon/factory_order_schedule.svg";
import { ReactComponent as Settings } from "vendor/menu_icon/settings.svg";
import { ReactComponent as RequestQuoteIcon } from "vendor/menu_icon/request_quote.svg";

import { env } from "env";
import { isAfterGivenDate } from "utils/helper";
import { concat } from "lodash";

const shouldDisplayOrderMenu = isAfterGivenDate(env);

const MENU: RouteType[] = [
  {
    id: "จัดการความต้องการซื้อ",
    path: paths.factoryOrderSchedulePath().toString(),
    icon: <FactoryOrderScheduleManage />,
  },
  {
    id: "จัดการชาวประมง",
    path: paths.fishermenPath().toString(),
    icon: <DirectionsBoatIcon />,
  },
  {
    id: "จัดการการจองคิว",
    path: paths.bookingsPath().toString(),
    icon: <BookingIcon />,
  },
  {
    id: "จัดการพนักงานส่ง",
    path: paths.shippersPath().toString(),
    icon: <LocalShippingIcon />,
  },
  {
    id: "จัดการปลา",
    path: paths.fishManagementPath().toString(),
    icon: <FishManage />,
  },
  {
    id: "จัดการโรงงาน",
    path: paths.factoriesPath().toString(),
    icon: <ApartmentIcon />,
  },
  {
    id: "การตั้งค่าระบบ",
    path: paths.settingsPath().toString(),
    icon: <Settings />,
  },
];

const ORDER_MENU: RouteType[] = [
  {
    id: "จัดการคำสั่งซื้อ",
    path: paths.ordersPath().toString(),
    icon: <RequestQuoteIcon />,
  },
];

const MERGE_MENU: RouteType[] = concat(ORDER_MENU, MENU);

export const MAIN_MENU: RouteType[] = shouldDisplayOrderMenu
  ? MERGE_MENU
  : MENU;

export const SHIPPER_MAIN_MENU: RouteType[] = [
  {
    id: "จัดการคำสั่งซื้อ",
    path: paths.shipperOrderPath().toString(),
    icon: <RequestQuoteIcon />,
  },
];

import { compose, withFormik, withHooks } from "enhancers";
import { PageContent } from "layouts";
import {
  Box,
  Grid,
  Typography,
  Form,
  Field,
  TextField,
  Divider,
  Button,
  Modal,
  Select,
} from "components";
import {
  gql,
  getErrorMessage,
  notifySuccess,
  paths,
  formatPhoneNumber,
  formatDate,
  toCurrency,
  Yup,
} from "utils/helper";
import { find } from "lodash";
import FishDemandModal from "./FishDemandModal";

export const BookingForm = (props) => (
  <Form>
    <Typography variant="h4" mb={6}>
      ข้อมูลชาวประมง
    </Typography>
    <Field
      component={Select}
      name="fisherman"
      type="text"
      label="รหัส/ชื่อ-นามสกุล"
      options={props.fishermanOptions}
      freeSolo
      fullWidth
      required
      disabled={props.isView}
      blurOnSelect={false}
      onChange={props.selectFactory}
    />

    <Box style={{ background: "#E7F4FD", borderRadius: 8 }} p={4} mt={6}>
      <Box display="flex" mb={4}>
        <Grid item xs={3}>
          <Typography variant="body1" color="Text/Dark Grey">
            ที่อยู่
          </Typography>
        </Grid>
        <Typography variant="body1" color="Text/Dark Grey">
          :
        </Typography>
        <Grid item xs={9} ml={8}>
          <Box display="flex" maxWidth="660px">
            <Typography variant="body1" color="Text/Black">
              {props.address}
            </Typography>
          </Box>
        </Grid>
      </Box>
      <Box display="flex" mb={4}>
        <Grid item xs={3}>
          <Typography variant="body1" color="Text/Dark Grey">
            โทรศัพท์
          </Typography>
        </Grid>
        <Typography variant="body1" color="Text/Dark Grey">
          :
        </Typography>

        <Grid item xs={9} ml={8}>
          <Typography variant="body1" color="Text/Black">
            {formatPhoneNumber(props.phoneNumber)}
          </Typography>
        </Grid>
      </Box>
      <Box display="flex">
        <Grid item xs={3}>
          <Typography variant="body1" color="Text/Dark Grey">
            หมายเหตุ
          </Typography>
        </Grid>
        <Typography variant="body1" color="Text/Dark Grey">
          :
        </Typography>

        <Grid item xs={9} ml={8}>
          <Typography variant="body1" color="Text/Black">
            {props.remark}
          </Typography>
        </Grid>
      </Box>
    </Box>

    <Box display="flex" alignItems="center" fullWidth mt={10}>
      <Box display="flex" flex={1} flexDirection="row" fullWidth>
        <Typography variant="h4">ข้อมูลการจองคิว</Typography>
      </Box>
      <Button
        color=""
        startIcon="add"
        onClick={props.openFishDemandModal}
        disabled={props.isView}
      >
        เลือกความต้องการซื้อ
      </Button>
    </Box>

    <Box style={{ background: "#E7F4FD", borderRadius: 8 }} p={4} mt={6}>
      <Box display="flex" mb={4}>
        <Grid item xs={3}>
          <Typography variant="body1" color="Text/Dark Grey">
            ชนิดปลา
          </Typography>
        </Grid>
        <Typography variant="body1" color="Text/Dark Grey">
          :
        </Typography>
        <Grid item xs={9} ml={8}>
          <Typography variant="body1" color="Text/Black">
            {props.fishDemandData?.fishName}
          </Typography>
        </Grid>
      </Box>
      <Box display="flex" mb={4}>
        <Grid item xs={3}>
          <Typography variant="body1" color="Text/Dark Grey">
            ปริมาณที่รับซื้อ (กก.)
          </Typography>
        </Grid>
        <Typography variant="body1" color="Text/Dark Grey">
          :
        </Typography>

        <Grid item xs={9} ml={8}>
          <Typography variant="body1" color="Text/Black">
            {props.fishDemandData &&
              toCurrency(props.fishDemandData?.purchaseVolume)}
          </Typography>
        </Grid>
      </Box>
      <Box display="flex" mb={4}>
        <Grid item xs={3}>
          <Typography variant="body1" color="Text/Dark Grey">
            ราคาที่รับซื้อ (บาท/กก.)
          </Typography>
        </Grid>
        <Typography variant="body1" color="Text/Dark Grey">
          :
        </Typography>

        <Grid item xs={9} ml={8}>
          <Typography variant="body1" color="Text/Black">
            {props.fishDemandData?.purchasePrice}
          </Typography>
        </Grid>
      </Box>
      <Box display="flex">
        <Grid item xs={3}>
          <Typography variant="body1" color="Text/Dark Grey">
            วันที่ต้องส่งปลา
          </Typography>
        </Grid>
        <Typography variant="body1" color="Text/Dark Grey">
          :
        </Typography>

        <Grid item xs={9} ml={8}>
          <Typography variant="body1" color="Text/Black">
            {props.fishDemandData?.receiveDate &&
              formatDate(props.fishDemandData?.receiveDate, "dd/MM/yyyy")}
          </Typography>
        </Grid>
      </Box>
    </Box>

    <Box display="flex" alignItems="center" mt={7}>
      <Grid item xs={6}>
        <Field
          component={TextField}
          name="reserveVolume"
          type="number"
          label="ปริมาณที่จอง"
          fullWidth
          unit="กิโลกรัม"
          disabled={props.isView}
        />
      </Grid>
      <Grid item xs={6} ml={6}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="body1" color="Text/Dark Grey">
            ราคารวม
          </Typography>
          <Box display="flex">
            <Typography variant="h4" color="Text/Black">
              {props.totalPrice}
            </Typography>
            <Typography
              variant="body1"
              color="Text/Dark Grey"
              alignSelf="end"
              ml={4}
              mb={0.25}
            >
              บาท
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Box>

    <Divider mt={10} />
    {props.isView ? (
      <Box display="flex" alignItems="center" fullWidth mb={6}>
        <Box display="flex" flex={1} flexDirection="row" fullWidth></Box>
        {props.isShowCancelButton && (
          <Button
            color="danger"
            mt={6}
            mr={4}
            width={160}
            onClick={props.onCancelBooking}
          >
            ยกเลิกการจอง
          </Button>
        )}

        <Button color="primary" width={160} mt={6} onClick={props.goToBooking}>
          ปิด
        </Button>
      </Box>
    ) : (
      <Box display="flex" alignItems="center" fullWidth mb={6}>
        <Box display="flex" flex={1} flexDirection="row" fullWidth></Box>
        <Button color="" mt={6} mr={4} width={160} onClick={props.onCancel}>
          {props.cancelButtonLabel}
        </Button>
        <Button onClick={props.submit} color="primary" width={160} mt={6}>
          {props.submitButtonLabel}
        </Button>
      </Box>
    )}
  </Form>
);

const BookingNewPage = (props) => (
  <PageContent title={props.title} breadcrumbs={props.breadcrumbs}>
    <BookingForm {...props} />
  </PageContent>
);

const API = {
  FETCH_FISHERMEN: gql`
    query FETCH_FISHERMEN {
      fishermen {
        id
        code
        name
        phoneNumber
        lineId
        remark
        googleMapLink
        availableStatus
        address {
          address
          district
          subDistrict
          province
          zipCode
        }
      }
    }
  `,
  CREATE_BOOKING: gql`
    mutation CREATE_BOOKING(
      $fishermanId: String
      $fishId: String
      $amount: Float
      $fishDemandVolume: Float
      $minPrice: Float
      $maxPrice: Float
      $maxPurchasePrice: Float
      $receiveDate: ISO8601DateTime
    ) {
      createBooking(
        input: {
          fishermanId: $fishermanId
          fishId: $fishId
          amount: $amount
          fishDemandVolume: $fishDemandVolume
          minPrice: $minPrice
          maxPrice: $maxPrice
          maxPurchasePrice: $maxPurchasePrice
          receiveDate: $receiveDate
        }
      ) {
        booking {
          code
          amount
          maxPurchasePrice
          receiveDate
          fisherman {
            id
          }
          fish {
            id
          }
        }
      }
    }
  `,
  FETCH_SETTING: gql`
    query FETCH_SETTING {
      setting {
        maxCapacityPerBooking
      }
    }
  `,
};

const enhancer = compose(
  withFormik({
    validateOnBlur: false,
    mapPropsToValues: () => ({
      fishDemand: [],
    }),
    validationSchema: Yup.object().shape({
      reserveVolume: Yup.lazy((value, props) => {
        if (
          props.parent.fishDemand &&
          props.parent.fishDemand.purchaseVolume &&
          value > props.parent.fishDemand.purchaseVolume
        ) {
          return Yup.number()
            .integer()
            .moreThan(-1, "ปริมาณที่จองไม่ถูกต้อง")
            .lessThan(
              props.parent.fishDemand.purchaseVolume + 1,
              "เกินปริมาณที่รับซื้อ"
            )
            .typeError("ต้องไม่เว้นว่างเอาไว้")
            .required("ต้องไม่เว้นว่างเอาไว้");
        }

        if (props.parent.maxCapacityPerBooking) {
          return Yup.number()
            .integer()
            .moreThan(-1, "ปริมาณที่จองไม่ถูกต้อง")
            .max(
              props.parent.maxCapacityPerBooking,
              `เกินปริมาณที่รับซื้อต่อรอบ (${props.parent.maxCapacityPerBooking} กก.)`
            )
            .positive("ปริมาณที่จองไม่ถูกต้อง")
            .typeError("ต้องไม่เว้นว่างเอาไว้")
            .required("ต้องไม่เว้นว่างเอาไว้");
        }

        return Yup.number()
          .integer()
          .moreThan(-1, "ปริมาณที่จองไม่ถูกต้อง")
          .typeError("ต้องไม่เว้นว่างเอาไว้")
          .required("ต้องไม่เว้นว่างเอาไว้");
      }),
    }),
  }),
  withHooks((props, hooks) => {
    const {
      useMemo,
      useMutation,
      useCallback,
      useEffect,
      useQuery,
      useState,
    } = hooks;
    const { values, isView, dirty, errors, setFieldValue } = props;
    const { loading, data, error } = useQuery(API.FETCH_FISHERMEN, {
      variables: {
        sorts: [{ field: "code", sort: "asc" }],
      },
    });
    const [createBooking] = useMutation(API.CREATE_BOOKING);
    const fetchSetting = useQuery(API.FETCH_SETTING);

    const { reserveVolume } = values;
    // const { setFieldError } = useFormikContext();

    const [address, setAddress] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [remark, setRemark] = useState("");
    const [selectedData, setSelectedData] = useState(null);
    const [fishDemandData, setFishDemandData] = useState(null);
    const [isSelectedFishDemand, setIsSelectedFishDemand] = useState(false);

    useEffect(() => {
      if (!fetchSetting.data || fetchSetting.loading || fetchSetting.error) {
        return;
      }

      setFieldValue(
        "maxCapacityPerBooking",
        fetchSetting.data?.setting?.maxCapacityPerBooking
      );
    }, [
      fetchSetting.loading,
      fetchSetting.data,
      fetchSetting.error,
      setFieldValue,
    ]);

    const title = useMemo(() => {
      return `สร้างการจองคิว`;
    }, []);

    const breadcrumbs = useMemo(() => {
      return [
        { path: paths.homePath(), label: "หน้าแรก" },
        { path: paths.bookingsPath(), label: "จัดการการจองคิว" },
        { path: null, label: title },
      ];
    }, [title]);

    const fishermanOptions = useMemo(() => {
      if (loading || error) {
        return [];
      }
      return data.fishermen
        .filter((fisherman) => fisherman.availableStatus === true)
        .map((fisherman) => {
          const { id, code, name, phoneNumber, remark, address } = fisherman;

          const addressInfo = address
            ? `${address?.address} ${address?.district} ${address?.subDistrict} ${address?.province} ${address?.zipCode}`
            : "-";
          const label = `[${code}] ${name}`;

          return {
            value: id,
            label,
            phoneNumber,
            remark: remark ? remark : "-",
            address: addressInfo,
          };
        });
    }, [loading, data, error]);

    const openFishDemandModal = useCallback(() => {
      Modal.open({
        className: "FishDemandModal",
        title: "เลือกความต้องการซื้อ",
        children: <FishDemandModal setSelectedData={setSelectedData} />,
        cancelButtonLabel: "ยกเลิก",
        okButtonLabel: "ยืนยัน",
        onClose: async ({ close }) => {
          close();
        },
        onCancel: async ({ close }) => {
          close();
        },
        onOk: async ({ close, values }) => {
          setIsSelectedFishDemand(true);
          close();
        },
      });
    }, [setSelectedData, setIsSelectedFishDemand]);

    useEffect(() => {
      if (isSelectedFishDemand && selectedData) {
        setFieldValue("fishDemand", selectedData);
        setFishDemandData(selectedData);
      } else {
        setFieldValue("fishDemand", null);
        setFishDemandData(null);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSelectedFishDemand, selectedData]);

    const onCancel = useCallback(async () => {
      if (dirty) {
        Modal.confirm({
          className: "ConfirmLeavePage",
          title: "ยกเลิกการแก้ไขข้อมูล",
          children: `ข้อมูลจะไม่ถูกบันทึกและไม่สามารถกู้คืนได้`,
          cancelButtonLabel: "ปิด",
          okButtonLabel: "ยกเลิกการแก้ไขข้อมูล",
          onOk: async ({ close }) => {
            try {
              close();
              paths.bookingsPath().push();
            } catch (e) {}
          },
        });
      } else {
        paths.bookingsPath().push();
      }
    }, [dirty]);

    const totalPrice = useMemo(() => {
      let result = "0";
      let minTotalPrice = 0;
      let maxTotalPrice = 0;

      if (fishDemandData && reserveVolume) {
        minTotalPrice = reserveVolume * fishDemandData.minPurchasePrice;
        maxTotalPrice = reserveVolume * fishDemandData.maxPurchasePrice;

        result =
          minTotalPrice === maxTotalPrice
            ? `${toCurrency(minTotalPrice)}`
            : `${toCurrency(minTotalPrice)} - ${toCurrency(maxTotalPrice)}`;
      }

      return result;
    }, [reserveVolume, fishDemandData]);

    const submit = useCallback(async () => {
      const { fisherman, reserveVolume } = values;
      try {
        if (
          errors.reserveVolume &&
          errors.reserveVolume !== "ต้องไม่เว้นว่างเอาไว้"
        ) {
          Modal.alert({
            title: "เกิดข้อผิดพลาด",
            children: (
              <Box>
                <Typography color="Text/Dark Grey" mb={2}>
                  ปริมาณที่จองไม่ถูกต้องโปรดตรวจสอบอีกครั้ง
                </Typography>
              </Box>
            ),
            okButtonLabel: "ปิด",
          });
        } else if (Object.keys(errors).length > 0) {
          Modal.alert({
            title: "เกิดข้อผิดพลาด",
            children: (
              <Box>
                <Typography color="Text/Dark Grey" mb={2}>
                  กรุณากรอกข้อมูลต่อไปนี้ให้ครบ
                </Typography>
                <Box mt={2} ml={2}>
                  <Typography color="Other/Danger">
                    {errors.fisherman ? " • รหัส/ชื่อ-นามสกุล" : ""}
                  </Typography>
                  <Typography color="Other/Danger">
                    {errors.reserveVolume ? " • ปริมาณที่จอง" : ""}
                  </Typography>
                </Box>
              </Box>
            ),
            okButtonLabel: "ปิด",
          });
        } else {
          await createBooking({
            variables: {
              fishermanId: fisherman,
              fishId: fishDemandData?.fishId,
              amount: reserveVolume,
              fishDemandVolume: fishDemandData?.purchaseVolume,
              minPrice: fishDemandData?.minPurchasePrice,
              maxPrice: fishDemandData?.maxPurchasePrice,
              maxPurchasePrice:
                reserveVolume * fishDemandData?.maxPurchasePrice,
              receiveDate: fishDemandData?.receiveDate,
            },
          });
          paths.bookingsPath().push();
          notifySuccess("เพิ่มข้อมูลสำเร็จ");
        }
      } catch (e) {
        const isSpecificError = [
          "ความต้องการซื้อมีการเปลี่ยนแปลง กรุณาแก้ไขข้อมูลอีกครั้ง",
        ].includes(getErrorMessage(e));

        const modalChildren = isSpecificError ? (
          <Typography color="Other/Danger">{getErrorMessage(e)}</Typography>
        ) : (
          `${getErrorMessage(e)}`
        );

        Modal.alert({
          title: "เกิดข้อผิดพลาด",
          children: modalChildren,
          okButtonLabel: "ปิด",
          okButtonVariant: "button",
          onOk: ({ close }) => {
            close();
            paths.bookingNewPath().push();
          },
        });
      }
    }, [values, errors, fishDemandData, createBooking]);

    const selectFactory = useCallback(
      (id) => {
        const foundedFisherman = find(fishermanOptions, {
          value: id,
        });

        setAddress(foundedFisherman.address);
        setPhoneNumber(foundedFisherman.phoneNumber);
        setRemark(foundedFisherman.remark);
      },
      [fishermanOptions, setAddress, setPhoneNumber, setRemark]
    );

    return {
      title,
      breadcrumbs,
      cancelButtonLabel: "ยกเลิก",
      submitButtonLabel: "สร้างการจองคิว",
      fishermanOptions,
      address,
      phoneNumber,
      remark,
      openFishDemandModal,
      fishDemandData,
      totalPrice,
      onCancel,
      isView,
      selectFactory,
      submit,
    };
  })
);

export default enhancer(BookingNewPage);

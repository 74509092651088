import { compose, withHooks } from "enhancers";
import { PageContent } from "layouts";
import { Box, Table, Typography } from "components";
import { gql, paths } from "utils/helper";
import { ReactComponent as EditIcon } from "assets/icon/edit.svg";
import { ReactComponent as AddIcon } from "assets/icon/add-icon.svg";

const FishermanIndexPage = (props) => (
  <PageContent
    title="จัดการชาวประมง"
    breadcrumbs={props.breadcrumbs}
    pageActions={props.pageActions}
  >
    <Box width="100%" mb={-6}>
      <Typography variant="h4" mb={4}>
        รายการชาวประมง
      </Typography>
      <Table
        columns={props.columns}
        rows={props.tableData}
        loading={props.loading}
        density="compact"
        autoHeight
        disableSelectionOnClick
        autoDetectInitialFilterOnUrlQueryParams
      />
    </Box>
  </PageContent>
);

export const API = {
  FETCH_FISHERMEN: gql`
    query FETCH_FISHERMEN {
      fishermen {
        id
        code
        name
        phoneNumber
        lineId
        remark
        googleMapLink
        availableStatus
        address {
          address
          district
          subDistrict
          province
          zipCode
        }
      }
    }
  `,
};

const enhancer = compose(
  withHooks((props, hooks) => {
    const { useMemo, useCallback, useQuery, useEffect } = hooks;
    const { loading, data, error, refetch } = useQuery(API.FETCH_FISHERMEN, {
      variables: {
        sorts: [{ field: "code", sort: "asc" }],
      },
    });

    useEffect(() => {
      refetch();
    }, [refetch]);

    const handleEditFisherman = useCallback(async (params) => {
      const { id } = params;
      paths.fishermanEditPath(id).push();
    }, []);

    const breadcrumbs = useMemo(() => {
      return [
        { path: paths.homePath(), label: "หน้าแรก" },
        { path: null, label: "จัดการชาวประมง" },
      ];
    }, []);

    const pageActions = useMemo(() => {
      return [
        {
          children: "เพิ่มชาวประมง",
          startIcon: <AddIcon />,
          onClick: () => paths.fishermanNewPath().push(),
          color: "primary",
        },
      ];
    }, []);

    const columns = useMemo(() => {
      return [
        { width: 170, field: "code", headerName: "รหัสชาวประมง" },
        { width: 180, field: "name", headerName: "ชื่อ-นามสกุล" },
        {
          width: 160,
          field: "phoneNumber",
          headerName: "เบอร์โทรศัพท์",
          type: "phoneNumber",
        },
        { width: 370, field: "address", headerName: "ที่อยู่" },
        {
          width: 115,
          field: "availableStatus",
          headerName: "สถานะ",
          type: "tag",
        },
        {
          width: 40,
          field: "actions",
          headerName: " ",
          filterable: false,
          sortable: false,
          type: "actions",
        },
      ];
    }, []);

    const tableData = useMemo(() => {
      if (loading || error) {
        return [];
      }
      return data.fishermen.map((fisherman) => {
        const {
          id,
          code,
          name,
          phoneNumber,
          lineId,
          remark,
          googleMapLink,
          availableStatus,
          address,
        } = fisherman;

        const addressInfo = `
          ${address?.address ?? ""} 
          ${address?.district ?? ""} 
          ${address?.subDistrict ?? ""} 
          ${address?.province ?? ""} 
          ${address?.zipCode ?? ""}`;

        return {
          id,
          code,
          name,
          phoneNumber,
          lineId,
          remark,
          googleMapLink,
          address: addressInfo,
          availableStatus: availableStatus ? "ใช้งาน" : "ไม่ใช้งาน",
          actions: [{ Icon: EditIcon, onClick: handleEditFisherman }],
        };
      });
    }, [loading, data, error, handleEditFisherman]);

    return {
      tableData,
      loading,
      rowCount: data?.fishermen?.rowCount ?? 0,
      breadcrumbs,
      pageActions,
      columns,
    };
  })
);

export default enhancer(FishermanIndexPage);

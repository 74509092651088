import React from "react";
import { compose, defaultProps, withHooks } from "enhancers";
import {
  Helmet,
  Box,
  Typography,
  Breadcrumbs,
  Link,
  Divider,
  Paper,
  Button,
  BrowseButton,
} from "components";

const PageContent = (props: any) => (
  <Box minWidth={786} maxWidth={1080} mx="auto">
    <Helmet title={props.title} />
    <Box display="flex" justifyContent="space-between">
      <Box display="flex">
        <Typography flex={1} variant="h2">
          {props.title}
        </Typography>
        {props.status && (
          <Box
            px={3}
            py={2}
            ml={4}
            height={32}
            alignSelf="center"
            alignItems="center"
            display="flex"
            style={{
              borderRadius: 4,
              backgroundColor: `${props.status?.color}`,
            }}
          >
            <Typography flex={1} variant="caption" color="Text/White">
              {props.status?.title}
            </Typography>
          </Box>
        )}
      </Box>
      <Box>
        {props.pageActions.map((actionProps: any, index: any) => (
          <>
            {actionProps.type !== "file" && (
              <Button key={index} ml={2} {...actionProps} />
            )}
            {actionProps.type === "file" && (
              <BrowseButton key={index} ml={2} {...actionProps} />
            )}
          </>
        ))}
      </Box>
    </Box>
    {props.breadcrumbs && (
      <>
        <Breadcrumbs aria-label="breadcrumb" mt={2}>
          {props.breadcrumbs.map(({ label, path }: any, index: any) => (
            <Link key={index} color={path ? undefined : "inherit"} to={path}>
              {label}
            </Link>
          ))}
        </Breadcrumbs>
        <Divider mt={6} />
      </>
    )}
    {props.paper ? (
      <Paper mt={6} px={4} py={6}>
        {props.children}
      </Paper>
    ) : (
      props.children
    )}
  </Box>
);

const enhancer = compose(
  defaultProps({
    paper: true,
  }),
  withHooks((props: any, hooks: any) => {
    const {
      title,
      breadcrumbs,
      pageActions = [],
      children,
      paper,
      status,
    } = props;
    return {
      title,
      breadcrumbs,
      pageActions,
      children,
      paper,
      status,
    };
  })
);

export default enhancer(PageContent);

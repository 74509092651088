import React from "react";
import { compose, withHooks, withStores } from "enhancers";
import { get } from "lodash";
import appStore from "stores/appStore";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { Box, Typography } from ".";

const BackdropComponent = (props) => (
  <Backdrop
    style={{ zIndex: 1201, color: "white" }}
    open={props.isShow}
    onClick={props.handleClose}
  >
    <Box display="flex" flexDirection="column" alignItems="center">
      <CircularProgress color="inherit" style={{ marginBottom: "12px" }} />
      <Typography>{props.message}</Typography>
    </Box>
  </Backdrop>
);

const enhancer = compose(
  withStores((stores, props) =>
    props.storeName ? get(stores, props.storeName) : {}
  ),
  withHooks((props, hooks) => {
    const { storeName, dispatch, ...rest } = props;
    const { useCallback } = hooks;
    const handleClose = useCallback(() => {}, []);
    return { ...rest, handleClose };
  })
);

const BackdropLoading = enhancer(BackdropComponent);

BackdropLoading.open = appStore.openLoadingBackdrop;
BackdropLoading.close = appStore.closeLoadingBackdrop;

export default BackdropLoading;

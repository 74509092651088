/* eslint-disable */
import React from "react"
import { compose, withFormik, withHooks, defaultProps } from "enhancers";
import {
  Box,
  Grid,
  Field,
  TextField,
  Select,
  Typography,
  Hidden,
} from "components";
import { bankNameOptions, getBankNameIcon, getBankNameLabel } from "./helper";

const BankAccount = (props) => (
  props.alternateLayout ?
    <Box {...props}>
      <Box flexDirection="row" mb={6}>
        <Typography variant="h4" display="inline">
          {props.title}
        </Typography>
        <Typography variant="h4" color="#F34336" display="inline">
          {props.requiredSymbol}
        </Typography>
      </Box>
      <Grid container spacing={6}>
        <Grid item xs={6}>
          <Field
            component={Select}
            name="bankName"
            type="text"
            label="ธนาคาร"
            fullWidth
            options={props.bankNameOptions}
            renderOption={props.renderBankNameOption}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            component={TextField}
            name="bankAccountName"
            type="text"
            label="ชื่อบัญชี"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            component={TextField}
            name="bankAccountNumber"
            type="text"
            label="เลขบัญชี"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={6}>
          <Hidden when={props.noBranch}>
            <Field
              component={TextField}
              name="branchName"
              type="text"
              label="สาขา"
              fullWidth
            />
          </Hidden>
        </Grid>
      </Grid>
    </Box>
    :
    (props.isFisherman ?
      <Box {...props}>
        <Typography variant="h4" mb={6}>
          {props.title}
        </Typography>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Field
              component={TextField}
              name="bankAccountName"
              type="text"
              label="ชื่อบัญชี"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component={Select}
              name="bankName"
              type="text"
              label="ธนาคาร"
              fullWidth
              options={props.bankNameOptions}
              renderOption={props.renderBankNameOption}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component={TextField}
              name="bankAccountNumber"
              type="text"
              label="หมายเลขบัญชี"
              fullWidth
            />
          </Grid>

        </Grid>
      </Box> :
      <Box {...props}>
        <Typography variant="h4" mb={6}>
          {props.title}
        </Typography>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <Field
              component={TextField}
              name="bankAccountName"
              type="text"
              label="ชื่อบัญชี"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component={TextField}
              name="bankAccountNumber"
              type="text"
              label="เลขบัญชี"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component={Select}
              name="bankName"
              type="text"
              label="ธนาคาร"
              fullWidth
              options={props.bankNameOptions}
              renderOption={props.renderBankNameOption}
            />
          </Grid>
          <Grid item xs={6}>
            <Hidden when={props.noBranch}>
              <Field
                component={TextField}
                name="branchName"
                type="text"
                label="สาขา"
                fullWidth
              />
            </Hidden>
          </Grid>
        </Grid>
      </Box>
    )
);

const enhancer = compose(
  defaultProps({
    bankNameOptions,
  }),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props) => ({
      // bankAccountName: null,
      // bankAccountNumber: null,
      // bankName: null,
      // branchName: null,
      ...props.field.value,
    }),
  }),
  withHooks((props, hooks) => {
    const { useCallback, useEffect, useMemo } = hooks;
    const {
      form,
      field,
      values,
      setFieldValue,
      setValues,
      type,
      isSeller,
      title,
      noBranch,
      isFisherman,
      ...rest
    } = props;

    const outerSetFieldValue = form.setFieldValue;
    const outerFieldName = field.name;
    const onChange = useCallback(
      (address) => {
        outerSetFieldValue(outerFieldName, address);
      },
      [outerSetFieldValue, outerFieldName]
    );

    useEffect(() => {
      onChange(values);
    }, [onChange, values]);

    const renderBankNameOption = useCallback((option) => {
      return (
        <>
          <Box mr={2} display="flex" alignItems="center">
            {getBankNameIcon(option)}
          </Box>
          {getBankNameLabel(option)}
        </>
      );
    }, []);

    const requiredSymbol = useMemo(() => {
      const symbol = title?.charAt(title.length - 1)
      return symbol === "*" ? symbol : null
    }, [title])

    const customTitle = useMemo(() => {
      return requiredSymbol ? title?.slice(0, -1) : title
    }, [requiredSymbol, title])

    const alternateLayout = isSeller

    return {
      ...rest,
      title: customTitle,
      noBranch,
      isFisherman,
      requiredSymbol,
      alternateLayout,
      renderBankNameOption,
    };
  })
);

export default enhancer(BankAccount);

import { compose, withHooks, withFormik } from "enhancers";
import { PageContent } from "layouts";
import { Modal, Typography, Box } from "components";
import {
  gql,
  notifyError,
  notifySuccess,
  paths,
  cleanTypename,
  getErrorMessage,
  Yup,
} from "utils/helper";
import { ShipperForm } from "./new";

const ShipperEditPage = (props) => (
  <PageContent title={props.title} breadcrumbs={props.breadcrumbs}>
    <ShipperForm {...props} />
  </PageContent>
);

const API = {
  FETCH_SHIPPER: gql`
    query FETCH_SHIPPER($id: ID!) {
      shipper(id: $id) {
        id
        code
        name
        phoneNumber
        licensePlateNumber
        remark
        availableStatus
        images
      }
    }
  `,
  UPDATE_SHIPPER: gql`
    mutation UPDATE_SHIPPER(
      $id: String!
      $name: String
      $phoneNumber: String
      $licensePlateNumber: String
      $remark: String
      $availableStatus: Boolean
      $images: [Upload!]
    ) {
      updateShipper(
        input: {
          id: $id
          name: $name
          phoneNumber: $phoneNumber
          licensePlateNumber: $licensePlateNumber
          remark: $remark
          availableStatus: $availableStatus
          images: $images
        }
      ) {
        shipper {
          id
          name
        }
      }
    }
  `,
};

const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({
      // fishermanStatus: "true",
      // googleMapLink: null,
    }),
    validationSchema: Yup.object().shape({
      name: Yup.string().required("ต้องไม่เว้นว่างไว้"),
      phoneNumber: Yup.string().required("ต้องไม่เว้นว่างไว้"),
    }),
  }),
  withHooks((props, hooks) => {
    const { setValues, values, errors } = props;
    const {
      useMemo,
      useQuery,
      useMutation,
      useEffect,
      useParams,
      useCallback,
    } = hooks;

    const { id } = useParams();

    const { loading, data, error, refetch } = useQuery(API.FETCH_SHIPPER, {
      variables: { id },
    });
    const [updateShipper] = useMutation(API.UPDATE_SHIPPER);

    useEffect(() => {
      refetch();
    }, [refetch]);

    const shipper = useMemo(() => {
      if (loading) {
        return null;
      }
      if (error) {
        const message = getErrorMessage(error);
        notifyError(message);
        paths.shippersPath().push();
        return null;
      }
      return data.shipper;
    }, [loading, data, error]);

    const title = shipper?.code;
    const breadcrumbs = useMemo(() => {
      return [
        { path: paths.homePath(), label: "หน้าแรก" },
        { path: paths.shippersPath(), label: "จัดการพนักงานขนส่ง" },
        { path: null, label: title },
      ];
    }, [title]);

    useEffect(() => {
      if (shipper) {
        const values = cleanTypename(shipper);
        values.shipperStatus = values.availableStatus.toString();
        setValues(values);
      }
    }, [setValues, shipper]);

    const isDirty = useCallback((values, shipper) => {
      if (values.images.length !== 0 && shipper.images.length !== 0) {
        if (
          values.availableStatus !== shipper.availableStatus ||
          values.name !== shipper.name ||
          values.phoneNumber !== shipper.phoneNumber ||
          values.licensePlateNumber !== shipper.licensePlateNumber ||
          values.remark !== shipper.remark ||
          values.images[0].name !== shipper.images[0].filename
        )
          return true;
      } else if (values.images.length !== 0 && shipper.images.length === 0) {
        return true;
      } else if (values.images.length === 0 && shipper.images.length === 0) {
        if (
          values.availableStatus !== shipper.availableStatus ||
          values.name !== shipper.name ||
          values.phoneNumber !== shipper.phoneNumber ||
          values.licensePlateNumber !== shipper.licensePlateNumber ||
          values.remark !== shipper.remark
        )
          return true;
      }
      return false;
    }, []);

    const useHandleCancel = useCallback(() => {
      values.availableStatus = values.shipperStatus === "true";
      if (isDirty(values, shipper)) {
        Modal.open({
          title: "ยกเลิกการแก้ไขข้อมูล",
          children: `ข้อมูลจะไม่ถูกบันทึกและไม่สามารถกู้คืนได้`,
          cancelButtonLabel: "ปิด",
          okButtonLabel: "ยกเลิกการแก้ไขข้อมูล",
          onOk: ({ close }) => {
            close();
            paths.shippersPath().push();
          },
        });
      } else {
        paths.shippersPath().push();
      }
    }, [values, shipper, isDirty]);

    const submit = useCallback(async () => {
      values.availableStatus = values.shipperStatus === "true";
      try {
        if (Object.keys(errors).length !== 0) {
          Modal.alert({
            title: "เกิดข้อผิดพลาด",
            children: (
              <Box>
                <Typography color="Text/Dark Grey" mb={2}>
                  กรุณากรอกข้อมูลต่อไปนี้ให้ครบ
                </Typography>
                <Typography color="Other/Danger">
                  {errors.name ? " • ชื่อ-นามสกุล" : ""}
                </Typography>
                <Typography color="Other/Danger">
                  {errors.phoneNumber ? " • เบอร์โทรศัพท์" : ""}
                </Typography>
              </Box>
            ),
            okButtonLabel: "ปิด",
          });
        } else {
          await updateShipper({ variables: values });
          paths.shippersPath().push();
          notifySuccess("แก้ไขข้อมูลสำเร็จ");
        }
      } catch (e) {
        if (
          getErrorMessage(e) ===
          "Validation failed: Phone number has already been taken"
        ) {
          Modal.alert({
            title: "เกิดข้อผิดพลาด",
            children: (
              <Box>
                <Typography color="Text/Dark Grey" mb={2}>
                  {"ข้อมูลต่อไปนี้ต้องไม่ซ้ำกับที่มีอยู่ในระบบ"}
                </Typography>
                <Typography color="Other/Danger">
                  {" • เบอร์โทรศัพท์"}
                </Typography>
              </Box>
            ),
            okButtonLabel: "ปิด",
            okButtonVariant: "button",
            onOk: ({ close }) => {
              close();
              paths.shipperEditPath(id).push();
            },
          });
        } else {
          Modal.alert({
            title: "เกิดข้อผิดพลาด",
            children: `${getErrorMessage(e)}`,
            okButtonLabel: "ปิด",
            okButtonVariant: "button",
            onOk: ({ close }) => {
              close();
              paths.shipperEditPath(id).push();
            },
          });
        }
      }
    }, [values, errors, updateShipper, id]);

    const shipperAvailableStatus = useMemo(() => {
      return [
        { label: "ใช้งาน", value: "true" },
        { label: "ไม่ใช้งาน", value: "false" },
      ];
    }, []);

    return {
      title,
      breadcrumbs,
      cancelButtonLabel: "ยกเลิก",
      submitButtonLabel: "บันทึก",
      useHandleCancel,
      submit,
      shipperAvailableStatus,
    };
  })
);

export default enhancer(ShipperEditPage);

import React from "react";
import { compose, withHooks } from "enhancers";
import {
  Box,
  Typography,
  Field,
  Button,
  BrowseButton,
  FileUpload,
  Hidden,
  IconButton,
} from "components";
import { get, range } from "lodash";
import { DeleteOutlineOutlined as DeleteIcon } from "@material-ui/icons";

const FileImageUpload = (props) => (
  <Box {...props}>
    {props.simple ? (
      <>
        <BrowseButton startIcon="upload" onBrowse={props.addDocument} mt={6}>
          <Typography color="Text/Black">{props.addButtonLabel}</Typography>
        </BrowseButton>
        {range(props.amount).map((index) => {
          return (
            <Box key={index} display="flex" alignItems="center" mt={6}>
              <Field
                component={FileUpload}
                name={`${props.name}[${index}]`}
                width={512}
                pl={4}
                simple
              />
              <Button
                startIcon="bigTrash"
                onClick={() => {
                  props.removeDocument(index);
                }}
                ml={2}
              />
            </Box>
          );
        })}
      </>
    ) : (
      <>
        {range(props.amount).map((index) => {
          return (
            <Box
              key={index}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mt={3}
              mb={3}
            >
              <Field
                component={FileUpload}
                simple
                name={`${props.name}[${index}]`}
                // width={512}
              />
              <IconButton onClick={() => props.removeDocument(index)}>
                <DeleteIcon style={{ color: "#000000", width: 20 }} />
              </IconButton>
            </Box>
          );
        })}
        <Hidden when={props.canAddMore}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <BrowseButton
              startIcon="add"
              onBrowse={props.addDocument}
              mt={4}
              mb={4}
              accept="image/*"
            >
              <Typography color="Text/Black">{props.addButtonLabel}</Typography>
            </BrowseButton>
          </Box>
        </Hidden>
      </>
    )}
  </Box>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const {
      form,
      name,
      insert,
      remove,
      title,
      addButtonLabel,
      mt,
      notDisplayHeader,
      simple,
    } = props;
    const { useCallback, useMemo } = hooks;
    const amount = get(form.values, name)?.length ?? 0;
    const canAddMore = amount === 1;
    const addDocument = useCallback(
      (file) => {
        insert(amount, file);
      },
      [insert, amount]
    );

    const removeDocument = useCallback(
      (index) => {
        remove(index);
      },
      [remove]
    );

    const requiredSymbol = useMemo(() => {
      const symbol = title?.charAt(title.length - 1);
      return symbol === "*" ? symbol : null;
    }, [title]);

    const customTitle = useMemo(() => {
      return requiredSymbol ? title?.slice(0, -1) : title;
    }, [requiredSymbol, title]);

    return {
      name,
      amount,
      addDocument,
      removeDocument,
      requiredSymbol,
      title: customTitle,
      addButtonLabel,
      mt,
      notDisplayHeader,
      simple,
      canAddMore,
    };
  })
);

export default enhancer(FileImageUpload);

import { compose, withFormik, withHooks } from "enhancers";
import { PageContent } from "layouts";
import {
  Box,
  Typography,
  Form,
  Divider,
  Button,
  Hidden,
  Modal,
  DatePicker,
  Table,
  Backdrop,
  Notification,
} from "components";
import { gql, getErrorMessage, paths, toCurrency } from "utils/helper";
import { ReactComponent as WarningAmberIcon } from "assets/icon/warning_amber.svg";
import { SummaryPriceComponent } from "components/advance/SummaryPrice";
import { Field } from "formik";
import { ReactComponent as Settings } from "assets/icon/settings.svg";
import { useRef } from "react";

const AutomaticOrderNewPage = (props) => (
  <PageContent title={props.title} breadcrumbs={props.breadcrumbs}>
    <Form>
      <Typography variant="h4" mb={2}>
        ข้อมูลความต้องการซื้อจากโรงงาน
      </Typography>
      <Typography variant="caption" mb={2} color="Text/Dark Grey">
        เลือกวันที่ต้องส่งปลาให้โรงงาน
        เพื่อให้ระบบจัดกลุ่มคำสั่งซื้อจากโรงงานเข้ากับรายการจองจากชาวประมงอัตโนมัติ
      </Typography>
      <Box
        style={{ background: "#F6F9FC", borderRadius: 8 }}
        display="flex"
        justifyContent="center"
        flexDirection="column"
        p={8}
        mt={4}
      >
        <Field
          name="date"
          component={DatePicker}
          label="วันส่งปลา"
          fullWidth
          placeholder=""
          markedDates={props.markedDates}
        />
        <Button
          mt={4}
          startIcon={<Settings />}
          children="สร้างคำสั่งซื้ออัตโนมัติ"
          style={{ alignSelf: "center" }}
          type="submit"
        />
      </Box>
      <Typography variant="h4" mt={10}>
        รายการคำสั่งซื้อ
      </Typography>
      <Box display="flex" alignItems="center" mt={1} fullWidth mb={2}>
        <Box display="flex" flex={1} flexDirection="row" fullWidth>
          <Typography variant="caption" color="Text/Dark Grey">
            รายการคำสั่งซื้อที่สร้างขึ้นโดยระบบ
          </Typography>
        </Box>
        <Typography variant="caption" color="Text/Dark Grey">
          {"ทั้งหมด "}
          {toCurrency(props.tableData?.length ?? 0, {
            minimumFractionDigits: 0,
          })}
          {" รายการ"}
        </Typography>
      </Box>
      <Hidden when={props.haveData}>
        <Box style={{ background: "#F6F9FC", borderRadius: 8 }} p={8} mt={4}>
          <Box display="flex" justifyContent="center">
            <WarningAmberIcon fontSize="large" />
            <Typography variant="body1" color="Text/Dark Grey" ml={1}>
              ไม่มีข้อมูลรายการคำสั่งซื้อ
              กรุณาเลือกวันส่งปลาและกดสร้างคำสั่งซื้ออัตโนมัติ
            </Typography>
          </Box>
        </Box>
      </Hidden>
      <Hidden when={!props.haveData}>
        <Table
          columns={props.columns}
          rows={props.tableData}
          loading={props.loading}
          density="compact"
          disableSelectionOnClick
          style={props.tableStyle}
          rowsPerPageOptions={props.rowsPerPageOptions}
        />
      </Hidden>
      <SummaryPriceComponent
        type="Admin"
        fuelPrice={props.values.fuelPrice}
        icePrice={props.values.icePrice}
        netProfit={props.values.netProfit}
        purchasePrice={props.values.purchasePrice}
        sellingPrice={props.values.sellingPrice}
        wage={props.values.wage}
      />
      <Divider mt={10} />
      <Box display="flex" justifyContent="flex-end" fullWidth mb={6}>
        <Button color="" mt={6} mr={4} width={150} onClick={props.onCancel}>
          ยกเลิก
        </Button>
        <Button
          onClick={props.createSuggestOrder}
          color="primary"
          width={150}
          mt={6}
        >
          สร้างคำสั่งซื้อ
        </Button>
      </Box>
    </Form>
  </PageContent>
);

const API = {
  AUTOMATIC_ORDERING_INFO: gql`
    query AUTOMATIC_ORDERING_INFO {
      automaticOrderingInfo {
        demandDates
      }
    }
  `,
  GENERATE_ORDER: gql`
    mutation GENERATE_ORDER($date: ISO8601DateTime!) {
      generateOrder(input: { date: $date }) {
        orders {
          id
          code
          shipperId
          purchasePrice
          fuelPrice
          icePrice
          wage
          sellingPrice
          netProfit
          orderDemands {
            id
            gradeAFishDeliveredWeight
            gradeBFishDeliveredWeight
            gradeCFishDeliveredWeight
            gradeDFishDeliveredWeight
            images
            factoryFishDemand {
              id
              factory {
                name
                phoneNumber
                remark
                address {
                  address
                  district
                  subDistrict
                  province
                  zipCode
                }
                fishDepreciation {
                  fishId
                  gradeADepreciationPct
                  gradeBDepreciationPct
                  gradeCDepreciationPct
                  gradeDDepreciationPct
                  purchasePrice
                }
              }
              fish {
                id
                name
                factoryPurchasePricePct
              }
              fishDeliverDate
              purchasePrice
              minPurchaseVolume
              maxPurchaseVolume
            }
            status
          }
          orderSupplies {
            id
            fishReceivedWeight
            fishReceivedGrade
            receivedSequence
            status
            images
            netProfit
            booking {
              id
              amount
              fisherman {
                id
                name
                phoneNumber
                remark
                address {
                  address
                  district
                  subDistrict
                  province
                  zipCode
                }
              }
            }
          }
          status
        }
        summary {
          purchasePrice
          fuelPrice
          icePrice
          wage
          sellingPrice
          netProfit
        }
      }
    }
  `,
  CREATE_ORDERS: gql`
    mutation CREATE_ORDERS($orders: [CreateOrderArguments!]) {
      createOrders(input: { orders: $orders }) {
        status
      }
    }
  `,
};

const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({
      purchasePrice: 0,
      fuelPrice: 0,
      icePrice: 0,
      wage: 0,
      sellingPrice: 0,
      netProfit: 0,
    }),
  }),
  withHooks((props, hooks) => {
    const {
      useMemo,
      useMutation,
      useQuery,
      useHandleSubmit,
      useCallback,
    } = hooks;
    const { setValues, values } = props;
    const [generateOrder, { data, loading, error }] = useMutation(
      API.GENERATE_ORDER
    );
    const [createOrders] = useMutation(API.CREATE_ORDERS);

    const {
      loading: automaticOrderingInfoLoading,
      data: automaticOrderingInfo,
      error: automaticOrderingInfoError,
    } = useQuery(API.AUTOMATIC_ORDERING_INFO, {
      fetchPolicy: "no-cache",
    });

    const cloneValues = useRef(values);

    const title = `สร้างคำสั่งซื้ออัตโนมัติ`;
    const breadcrumbs = useMemo(() => {
      return [
        { path: paths.homePath(), label: "หน้าแรก" },
        { path: paths.ordersPath(), label: "จัดการคำสั่งซื้อ" },
        { path: null, label: title },
      ];
    }, [title]);

    useHandleSubmit(async (values) => {
      try {
        Backdrop.open({ message: "กรุณารอสักครู่" });
        const res = await generateOrder({
          variables: {
            date: values.date,
          },
        });
        if (res.data.generateOrder.orders.length === 0) {
          Modal.alert({
            title: "เกิดข้อผิดพลาด",
            children: (
              <Typography color="Other/Danger">
                ขออภัยระบบไม่สามารถจัดรายการรับปลาที่เหมาะสมในวันนี้ได้
                อาจเกิดจากรายการจองปลาในวันนี้มีไม่เพียงพอหรือขาดทุน
                หากต้องการสร้างรายการรับปลาในวันนี้ขอแนะนำให้ทำรายการจัดด้วยตัวเอง
              </Typography>
            ),
            okButtonLabel: "ปิด",
            okButtonVariant: "contained",
            okButtonStyle: { minWidth: "160px" },
            onOk: ({ close }) => {
              close();
            },
          });
        }
        Backdrop.close();
      } catch (error) {
        Backdrop.close();
        Modal.alert({
          title: "ไม่สามารถสร้างคำสั่งซื้ออัตโนมัติได้",
          children: (
            <Typography color="Other/Danger">
              {getErrorMessage(error)}
            </Typography>
          ),
          okButtonLabel: "ปิด",
          okButtonVariant: "contained",
          okButtonStyle: { minWidth: "160px" },
          onOk: ({ close }) => {
            close();
          },
        });
      }
    }, []);

    const columns = useMemo(() => {
      return [
        { width: 400, field: "factoryName", headerName: "ชื่อโรงงาน" },
        {
          width: 150,
          field: "fishName",
          headerName: "ชื่อปลา",
        },
        {
          width: 185,
          field: "amount",
          headerName: "รับปลาแล้ว (กก.)",
          type: "amount",
        },
        {
          width: 130,
          field: "netProfit",
          headerName: "กำไรสุทธิ",
          type: "netProfit",
        },
      ];
    }, []);

    const tableData = useMemo(() => {
      if (!data || data.length === 0 || loading || error) {
        return [];
      }
      const resData = data.generateOrder;
      setValues({
        ...cloneValues.current,
        ...resData.summary,
      });
      return resData.orders.map((order, index) => {
        const { netProfit, orderDemands, orderSupplies } = order;

        var sumFishReceivedWeight = 0;
        var sumAmount = 0;
        orderSupplies.forEach((orderSupplie) => {
          sumAmount += orderSupplie.booking.amount;
          // sumFishReceivedWeight += orderSupplie.fishReceivedWeight;
        });

        return {
          id: index,
          factoryName: orderDemands[0].factoryFishDemand?.factory.name ?? "",
          fishName: "ปลา" + orderDemands[0].factoryFishDemand?.fish.name ?? "",
          amount: sumFishReceivedWeight + " / " + sumAmount,
          netProfit: netProfit ?? 0,
        };
      });
    }, [data, loading, error, setValues]);

    const tableStyle = useMemo(() => {
      return { minHeight: "unset" };
    }, []);

    const rowsPerPageOptions = useMemo(() => {
      return [];
    }, []);

    const onCancel = useCallback(() => {
      paths.ordersPath().push();
    }, []);

    const createSuggestOrder = useCallback(async () => {
      if (tableData.length === 0) {
        Modal.alert({
          title: "เกิดข้อผิดพลาด",
          children: (
            <Typography color="Other/Danger">
              ไม่มีข้อมูลรายการคำสั่งซื้อ
              กรุณาเลือกวันส่งปลาและกดสร้างคำสั่งซื้ออัตโนมัติ
            </Typography>
          ),
          okButtonLabel: "ปิด",
          okButtonVariant: "contained",
          okButtonStyle: { minWidth: "160px" },
          onOk: ({ close }) => {
            close();
          },
        });
        return;
      }
      const resData = data.generateOrder;
      const orders = [];
      for (const order of resData.orders) {
        orders.push({
          purchasePrice: order.purchasePrice,
          fuelPrice: order.fuelPrice,
          icePrice: order.icePrice,
          wage: order.wage,
          sellingPrice: order.sellingPrice,
          netProfit: order.netProfit,
          orderDemands: {
            factoryFishDemandId: order.orderDemands[0].factoryFishDemand.id,
          },
          orderSupplies: order.orderSupplies.map((orderSupply) => ({
            bookingId: orderSupply.booking.id,
            receivedSequence: orderSupply.receivedSequence,
          })),
          isSuggest: true,
        });
      }

      try {
        await createOrders({
          variables: {
            orders,
          },
        });
        Notification.success("แก้ไขข้อมูลสำเร็จ");
      } catch (error) {
        Modal.alert({
          title: "เกิดข้อผิดพลาด",
          children: (
            <Typography color="Other/Danger">
              {getErrorMessage(error)}
            </Typography>
          ),
          okButtonLabel: "ปิด",
          okButtonVariant: "button",
          onOk: ({ close }) => {
            close();
          },
        });
        return;
      }
      paths.ordersPath().push();
    }, [data, createOrders, tableData]);

    const markedDates = useMemo(() => {
      const demandDates =
        automaticOrderingInfo?.automaticOrderingInfo?.demandDates || [];
      return [...demandDates];
    }, [automaticOrderingInfo]);

    cloneValues.current = values;
    return {
      values,
      title,
      breadcrumbs,
      tableData,
      columns,
      tableStyle,
      rowsPerPageOptions,
      haveData: tableData && tableData.length > 0,
      onCancel,
      createSuggestOrder,
      markedDates,
    };
  })
);

export default enhancer(AutomaticOrderNewPage);

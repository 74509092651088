import { compose, withHooks, withStores } from "enhancers";
import { PageContent } from "layouts";
import { Box, Table, Typography, Tabs, Tab } from "components";
import { gql, paths } from "utils/helper";
import { ReactComponent as EditIcon } from "assets/icon/edit.svg";

const ShipperOrderIndexPage = (props) => (
  <PageContent title="จัดการคำสั่งซื้อ" breadcrumbs={props.breadcrumbs}>
    <Box width="100%" mb={-6}>
      <Typography variant="h4" mb={4}>
        รายการคำสั่งซื้อ
      </Typography>
      <Tabs
        value={props.tabState}
        onChange={props.changeTabState}
        variant="fullWidth"
      >
        <Tab className="FullWidthTab" label="ทั้งหมด" />
        <Tab className="FullWidthTab" label="กำลังดำเนินการ" />
        <Tab className="FullWidthTab" label="สำเร็จ" />
      </Tabs>
      <Table
        columns={[
          { width: 155, field: "code", headerName: "รหัสคำสั่งซื้อ" },
          { width: 205, field: "factoryName", headerName: "ชื่อโรงงาน" },
          {
            width: 150,
            field: "fishName",
            headerName: "ชื่อปลา",
          },
          {
            width: 185,
            field: "amount",
            headerName: "รับปลาแล้ว (กก.)",
            type: "amount",
          },
          {
            width: 145,
            field: "shippingAt",
            headerName: "วันที่ส่งปลา",
            type: "dateOnly",
          },
          {
            width: 115,
            field: "status",
            headerName: "สถานะ",
            type: "tagOrder",
          },
          {
            width: 40,
            field: "actions",
            headerName: " ",
            filterable: false,
            sortable: false,
            type: "actions",
          },
        ]}
        rows={props.tableData}
        loading={props.loading}
        density="compact"
        autoHeight
        disableSelectionOnClick
      />
    </Box>
  </PageContent>
);

export const API = {
  FETCH_ORDERS: gql`
    query FETCH_ORDERS($filters: JSON, $shipperId: ID) {
      orders(filters: $filters, shipperId: $shipperId) {
        id
        code
        netProfit
        orderDemands {
          factoryFishDemand {
            factory {
              name
            }
            fish {
              name
            }
            fishDeliverDate
          }
        }
        orderSupplies {
          fishReceivedWeight
          booking {
            amount
          }
        }
        status
      }
    }
  `,
};

const enhancer = compose(
  withStores((stores) => ({
    currentUser: stores.appStore.currentUser,
  })),
  withHooks((props, hooks) => {
    const {
      useMemo,
      useCallback,
      useQuery,
      useEffect,
      useState,
      useLocation,
    } = hooks;

    const { currentUser } = props;
    const { ownerData } = currentUser ?? {};
    const { shipper } = ownerData || {};

    const { loading, data, error, refetch } = useQuery(API.FETCH_ORDERS, {
      variables: {
        sorts: [{ field: "code", sort: "asc" }],
        shipperId: shipper?.id,
      },
    });
    const [tabState, setTabState] = useState(0);

    const location = useLocation();
    const hashTag = location.hash;

    const breadcrumbs = useMemo(() => {
      return [
        { path: paths.homePath(), label: "หน้าแรก" },
        { path: null, label: "จัดการคำสั่งซื้อ" },
      ];
    }, []);

    useEffect(() => {
      switch (hashTag) {
        case "#in_progress":
          refetch({
            filters: { status: "in_progress" },
          });
          return setTabState(1);
        case "#waiting_for_payment":
          refetch({
            filters: { status: "waiting_for_payment" },
          });
          return setTabState(2);
        default:
          refetch({ filters: {} });
          return setTabState(0);
      }
    }, [hashTag, refetch]);

    const changeTabState = useCallback((e, value) => {
      switch (value) {
        case 0:
          return paths.shipperOrderPath().push("");
        case 1:
          return paths.shipperOrderPath().push("#in_progress");
        case 2:
          return paths.shipperOrderPath().push("#waiting_for_payment");
        default:
          return paths.shipperOrderPath().push("");
      }
    }, []);

    const tableData = useMemo(() => {
      if (loading || error) {
        return [];
      }
      return data.orders
        .filter(
          (order) =>
            order.status === "in_progress" ||
            order.status === "waiting_for_payment"
        )
        .map((order) => {
          const {
            id,
            code,
            netProfit,
            orderDemands,
            orderSupplies,
            status,
          } = order;

          var sumFishReceivedWeight = 0;
          var sumAmount = 0;
          orderSupplies.forEach((orderSupply) => {
            sumAmount += orderSupply.booking.amount;
            sumFishReceivedWeight += orderSupply.fishReceivedWeight;
          });

          let customStatus = "";

          switch (status) {
            case "waiting_for_payment":
              customStatus = "รอโอนเงิน";
              break;
            case "in_progress":
              customStatus = "กำลังดำเนินการ";
              break;
            case "waiting_for_confirm":
              customStatus = "รอยืนยัน";
              break;
            case "success":
              customStatus = "สำเร็จ";
              break;
            case "canceled":
              customStatus = "ยกเลิก";
              break;
            default:
              customStatus = "รอโอนเงิน";
              break;
          }

          return {
            id,
            code,
            factoryName: orderDemands[0].factoryFishDemand.factory.name ?? "",
            fishName: "ปลา" + orderDemands[0].factoryFishDemand.fish.name ?? "",
            amount: sumFishReceivedWeight + " / " + sumAmount,
            netProfit,
            shippingAt: orderDemands[0].factoryFishDemand.fishDeliverDate,
            status: customStatus,
            actions: [
              {
                Icon: EditIcon,
                onClick: () => paths.shipperOrderEditPath(id).push(),
              },
            ],
          };
        });
    }, [loading, data, error]);

    return {
      tableData,
      tabState,
      changeTabState,
      loading,
      rowCount: 0,
      breadcrumbs,
    };
  })
);

export default enhancer(ShipperOrderIndexPage);

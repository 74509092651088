import React from "react";
import { compose, withHooks, defaultProps } from "enhancers";
import {
  Box,
  Typography,
  Field,
  Address,
  Button,
  RadioGroup,
} from "components";
import { get, range } from "lodash";

const AddressListComponent = (props) => (
  <Box {...props}>
    <Box display="flex" alignItems="center" mb={6}>
      <Box display="flex" flex={1} flexDirection="row" fullWidth>
        <Typography variant="h4" display="inline">
          {props.title}
        </Typography>
        <Typography variant="h4" color="#F34336" display="inline">
          {props.requiredSymbol}
        </Typography>
      </Box>
      <Typography variant="body1">ทั้งหมด {props.amount}</Typography>
    </Box>
    {range(props.amount).map((index) => {
      return (
        <Box key={index}>
          <Box display="flex" alignItems="center" mt={6}>
            <Typography variant="h6" bold flex={1}>
              ที่อยู่ {index + 1}
            </Typography>
            <Button
              startIcon="bigTrash"
              onClick={() => {
                props.remove(index);
              }}
            />
          </Box>
          <Field
            component={Address}
            name={`${props.name}[${index}]`}
            mt={6}
            type={props.type}
            hasGmap={props.hasGmap}
          />
          <Field
            component={RadioGroup}
            name={`${props.name}[${index}].isMain`}
            options={[
              {
                label: "ใช้เป็นที่อยู่หลัก",
                value: "true",
              },
            ]}
            onChange={props.selectMainAddress(index)}
            mt={6}
            mb={6}
          />
        </Box>
      );
    })}
    <Button startIcon="add" onClick={props.push}>
      {props.addButtonLabel}
    </Button>
  </Box>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const {
      form,
      name,
      push,
      remove,
      title,
      addButtonLabel,
      mt,
      type,
      hasGmap,
    } = props;
    const { useCallback, useMemo } = hooks;
    const amount = get(form.values, name)?.length ?? 0;

    const customPush = useCallback(() => {
      push({});
    }, [push]);

    const customRemove = useCallback(
      (index) => {
        remove(index);
      },
      [remove]
    );

    const setFieldValue = form.setFieldValue;
    const selectMainAddress = useCallback(
      (selectedIndex) => () => {
        range(amount).forEach((index) => {
          setFieldValue(
            `${name}[${index}].isMain`,
            index === selectedIndex ? "true" : null
          );
        });
      },
      [setFieldValue, name, amount]
    );

    const requiredSymbol = useMemo(() => {
      const symbol = title?.charAt(title.length - 1);
      return symbol === "*" ? symbol : null;
    }, [title]);

    const customTitle = useMemo(() => {
      return requiredSymbol ? title?.slice(0, -1) : title;
    }, [requiredSymbol, title]);

    return {
      name,
      amount,
      push: customPush,
      remove: customRemove,
      selectMainAddress,
      requiredSymbol,
      title: customTitle,
      addButtonLabel,
      mt,
      type,
      hasGmap,
    };
  })
);

const AddressList = enhancer(AddressListComponent);

AddressList.Billing = defaultProps({ type: "billing" })(AddressList);
AddressList.Delivery = defaultProps({ type: "delivery" })(AddressList);

export default AddressList;

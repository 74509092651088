import { compose, withFormik, withHooks, defaultProps } from "enhancers";
import { PageContent } from "layouts";
import {
  Box,
  Grid,
  Typography,
  Form,
  Field,
  Button,
  Modal,
  Select,
} from "components";
import {
  gql,
  getErrorMessage,
  notifySuccess,
  paths,
  formatPhoneNumber,
  Yup,
} from "utils/helper";
import { find, isEmpty, filter } from "lodash";
import FishDemand from "./FishDemand";
import DeliveryFish from "./DeliveryFish";

const FactoryOrderScheduleNewPage = (props) => (
  <PageContent title={props.title} breadcrumbs={props.breadcrumbs}>
    <Form>
      <Typography variant="h4" mb={6}>
        ข้อมูลโรงงาน
      </Typography>
      <Field
        component={Select}
        name="factory"
        type="text"
        label="รหัส/ชื่อโรงงาน"
        options={props.factoryOptions}
        freeSolo
        fullWidth
        required
        disabled={props.isView}
        blurOnSelect={false}
        onChange={props.selectFactory}
      />
      <Box style={{ background: "#E7F4FD", borderRadius: 8 }} p={4} mt={6}>
        <Box display="flex" mb={4}>
          <Grid item xs={3}>
            <Typography variant="body1" color="Text/Dark Grey">
              ที่อยู่
            </Typography>
          </Grid>
          <Typography variant="body1" color="Text/Dark Grey">
            :
          </Typography>
          <Grid item xs={9} ml={8}>
            <Typography variant="body1" color="Text/Black">
              {props?.address}
            </Typography>
          </Grid>
        </Box>
        <Box display="flex" mb={4}>
          <Grid item xs={3}>
            <Typography variant="body1" color="Text/Dark Grey">
              โทรศัพท์
            </Typography>
          </Grid>
          <Typography variant="body1" color="Text/Dark Grey">
            :
          </Typography>

          <Grid item xs={9} ml={8}>
            <Typography variant="body1" color="Text/Black">
              {formatPhoneNumber(props.phoneNumber)}
            </Typography>
          </Grid>
        </Box>
        <Box display="flex">
          <Grid item xs={3}>
            <Typography variant="body1" color="Text/Dark Grey">
              หมายเหตุ
            </Typography>
          </Grid>
          <Typography variant="body1" color="Text/Dark Grey">
            :
          </Typography>

          <Grid item xs={9} ml={8}>
            <Typography variant="body1" color="Text/Black">
              {props.remark}
            </Typography>
          </Grid>
        </Box>
      </Box>
      <Box mt={10}>
        <FishDemand {...props}></FishDemand>
      </Box>
      <Box mt={10}>
        <DeliveryFish {...props}></DeliveryFish>
      </Box>

      <Box display="flex" alignItems="center" fullWidth mb={6}>
        <Box display="flex" flex={1} flexDirection="row" fullWidth></Box>
        <Button color="" mt={6} mr={4} width={160} onClick={props.onCancel}>
          {props.cancelButtonLabel}
        </Button>
        <Button onClick={props.submit} color="primary" mt={6}>
          {props.submitButtonLabel}
        </Button>
      </Box>
    </Form>
  </PageContent>
);

const API = {
  FETCH_FISHERMEN: gql`
    query FETCH_FISHERMEN {
      fishermen {
        id
        code
        name
        phoneNumber
        lineId
        remark
        googleMapLink
        availableStatus
        address {
          address
          district
          subDistrict
          province
          zipCode
        }
      }
    }
  `,
  FETCH_FACTORIES: gql`
    query FETCH_FACTORIES {
      factories {
        id
        code
        googleMapLink
        name
        phoneNumber
        remark
        availableStatus
        address {
          address
          district
          subDistrict
          province
          zipCode
        }
      }
    }
  `,
  FETCH_FACTORY: gql`
    query FETCH_FACTORY($id: ID!) {
      factory(id: $id) {
        id
        code
        name
        phoneNumber
        remark
        googleMapLink
        availableStatus
        images
        address {
          address
          district
          subDistrict
          province
          zipCode
        }
        fishDepreciation {
          gradeADepreciationPct
          purchasePrice
          fish {
            id
            name
            availableStatus
          }
        }
      }
    }
  `,
  CREATE_FACTORY_ORDER_SCHEDULE: gql`
    mutation CREATE_FACTORY_ORDER_SCHEDULE(
      $factoryId: String!
      $fishes: [FishArguments!]!
      $scheduleType: String!
      $startDate: String
      $endDate: String
      $deliverDate: String
      $dailyRepeat: String
      $weeklyDeliveryDates: [String!]
      $monthlyDeliveryDates: [String!]
    ) {
      createFactoryOrderSchedule(
        input: {
          factoryId: $factoryId
          fishes: $fishes
          scheduleType: $scheduleType
          startDate: $startDate
          endDate: $endDate
          deliverDate: $deliverDate
          dailyRepeat: $dailyRepeat
          weeklyDeliveryDates: $weeklyDeliveryDates
          monthlyDeliveryDates: $monthlyDeliveryDates
        }
      ) {
        factorySchedules {
          id
          code
        }
      }
    }
  `,
};

const enhancer = compose(
  defaultProps({}),
  withFormik({
    mapPropsToValues: () => ({
      fishes: [],
      scheduleType: "one-demand",
    }),
    validationSchema: Yup.object().shape({
      factory: Yup.string().nullable().required("ต้องไม่เว้นว่างไว้"),
      fishes: Yup.array()
        .nullable(true)
        .min(1, "ข้อมูลความต้องการซื้อปลา")
        .of(
          Yup.object().shape({
            id: Yup.string().nullable().required("ต้องไม่เว้นว่างไว้"),
            purchasePrice: Yup.number()
              .nullable()
              .required("ต้องไม่เว้นว่างไว้"),
            minPurchaseVolume: Yup.number()
              .nullable()
              .required("ต้องไม่เว้นว่างไว้"),
            maxPurchaseVolume: Yup.number()
              .nullable()
              .required("ต้องไม่เว้นว่างไว้"),
          })
        ),
      deliverDate: Yup.string().when("scheduleType", {
        is: "one-demand",
        then: Yup.string().nullable().required("ต้องไม่เว้นว่างไว้"),
      }),
      startDate: Yup.string().when("scheduleType", {
        is: (scheduleType) =>
          scheduleType === "daily" ||
          scheduleType === "weekly" ||
          scheduleType === "monthly",
        then: Yup.string().nullable().required("ต้องไม่เว้นว่างไว้"),
      }),
      endDate: Yup.string().when("scheduleType", {
        is: (scheduleType) =>
          scheduleType === "daily" ||
          scheduleType === "weekly" ||
          scheduleType === "monthly",
        then: Yup.string().nullable().required("ต้องไม่เว้นว่างไว้"),
      }),
      dailyRepeat: Yup.string("ส่งซ้ำทุกๆ").when("scheduleType", {
        is: "daily",
        then: Yup.string().nullable().required("ต้องไม่เว้นว่างไว้"),
      }),
      weeklyDeliveryDates: Yup.lazy((value, props) => {
        if (props.parent.scheduleType === "weekly") {
          return Yup.array()
            .of(Yup.string())
            .min(1, "ต้องไม่เว้นว่างไว้")
            .nullable()
            .required("ต้องไม่เว้นว่างไว้");
        }
        return Yup.array().nullable(true);
      }),

      monthlyDeliveryDates: Yup.lazy((value, props) => {
        if (props.parent.scheduleType === "monthly") {
          return Yup.array()
            .of(Yup.string())
            .min(1, "ต้องไม่เว้นว่างไว้")
            .nullable()
            .required("ต้องไม่เว้นว่างไว้");
        }
        return Yup.array().nullable(true);
      }),
    }),
  }),
  withHooks((props, hooks) => {
    const {
      useMemo,
      useMutation,
      useCallback,
      useQuery,
      useState,
      useLazyQuery,
    } = hooks;
    const { values, isView, dirty, errors, setFieldValue } = props;
    const { loading, data, error } = useQuery(API.FETCH_FACTORIES, {
      variables: {
        sorts: [{ field: "code", sort: "asc" }],
      },
    });
    const [fetchFactory, { data: factoryData }] = useLazyQuery(
      API.FETCH_FACTORY
    );
    const [createFactoryOrderSchedule] = useMutation(
      API.CREATE_FACTORY_ORDER_SCHEDULE
    );

    const { fishes, scheduleType } = values;

    const [factoryOrderData, setFactoryOrderData] = useState({
      address: "",
      phoneNumber: "",
      remark: "",
    });

    const title = `สร้างความต้องการซื้อ`;

    const breadcrumbs = useMemo(() => {
      return [
        { path: paths.homePath(), label: "หน้าแรก" },
        {
          path: paths.factoryOrderSchedulePath(),
          label: "จัดการความต้องการซื้อ",
        },
        { path: null, label: title },
      ];
    }, [title]);

    const factoryOptions = useMemo(() => {
      if (loading || error) {
        return [];
      }
      return data.factories
        .filter((factory) => factory.availableStatus === true)
        .map((factory) => {
          const { id, code, name, phoneNumber, remark, address } = factory;

          const addressInfo = `${address?.address} ${address?.district} ${address?.subDistrict} ${address?.province} ${address?.zipCode}`;
          const label = `[${code}] ${name}`;

          return {
            value: id,
            label,
            phoneNumber,
            remark: remark ? remark : "-",
            address: addressInfo,
          };
        });
    }, [loading, data, error]);

    const onCancel = useCallback(async () => {
      if (dirty) {
        Modal.confirm({
          className: "ConfirmLeavePage",
          title: "ยกเลิกการแก้ไขข้อมูล",
          children: `ข้อมูลจะไม่ถูกบันทึกและไม่สามารถกู้คืนได้`,
          cancelButtonLabel: "ปิด",
          okButtonLabel: "ยกเลิกการแก้ไขข้อมูล",
          onOk: async ({ close }) => {
            try {
              close();
              paths.factoryOrderSchedulePath().push();
            } catch (e) {}
          },
        });
      } else {
        paths.factoryOrderSchedulePath().push();
      }
    }, [dirty]);

    const submit = useCallback(async () => {
      let params;

      switch (values.scheduleType) {
        case "one-demand":
          params = {
            factoryId: values.factory,
            fishes: values.fishes,
            scheduleType: values.scheduleType,
            deliverDate: values.deliverDate,
          };
          break;
        case "daily":
          params = {
            factoryId: values.factory,
            fishes: values.fishes,
            scheduleType: values.scheduleType,
            dailyRepeat: values.dailyRepeat,
            startDate: values.startDate,
            endDate: values.endDate,
          };
          break;
        case "weekly":
          params = {
            factoryId: values.factory,
            fishes: values.fishes,
            scheduleType: values.scheduleType,
            startDate: values.startDate,
            endDate: values.endDate,
            weeklyDeliveryDates: values.weeklyDeliveryDates,
          };
          break;
        case "monthly":
          params = {
            factoryId: values.factory,
            fishes: values.fishes,
            scheduleType: values.scheduleType,
            startDate: values.startDate,
            endDate: values.endDate,
            monthlyDeliveryDates: values.monthlyDeliveryDates,
          };
          break;

        default:
          break;
      }

      try {
        let fishDemandError = {};
        values.fishes.forEach((fish) => {
          if (fish.minPurchaseVolume && fish.maxPurchaseVolume) {
            fishDemandError =
              fish.minPurchaseVolume > fish.maxPurchaseVolume
                ? "ปริมาณปลาที่โรงงานต้องการต้องมากกว่าปริมาณปลาขั้นต่ำ"
                : {};
          }
        });

        if (Object.keys(errors).length !== 0 || !isEmpty(fishDemandError)) {
          let error = {};
          if (typeof errors.fishes === "object" && errors.fishes.length > 0) {
            const filterdErrors = filter(errors.fishes, (error) => error);
            error = filterdErrors[0] ?? {};
          }

          Modal.alert({
            title: "เกิดข้อผิดพลาด",
            children: (
              <Box>
                {!isEmpty(fishDemandError) ? (
                  <>
                    <Typography color="Text/Dark Grey" mb={2}>
                      ข้อมูลความต้องการซื้อปลา
                    </Typography>
                    <Typography color="Other/Danger">
                      {` • ${fishDemandError}`}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography color="Text/Dark Grey" mb={2}>
                      กรุณากรอกข้อมูลต่อไปนี้ให้ครบ
                    </Typography>
                    <Typography color="Other/Danger">
                      {errors.factory ? ` • รหัส/ชื่อโรงงาน` : ""}
                    </Typography>
                    {errors.fishes === "ข้อมูลความต้องการซื้อปลา" ? (
                      <Box>
                        <Typography color="Other/Danger">{` • ชื่อปลา`}</Typography>
                        <Typography color="Other/Danger">{` • ราคาขายให้โรงงาน`}</Typography>
                        <Typography color="Other/Danger">{` • ปริมาณขั้นต่ำ`}</Typography>
                        <Typography color="Other/Danger">{` • ปริมาณที่โรงงานต้องการ`}</Typography>
                      </Box>
                    ) : (
                      !isEmpty(error) && (
                        <Box>
                          <Typography Typography color="Other/Danger">
                            {error.id ? ` • ชื่อปลา` : ""}
                          </Typography>
                          <Typography Typography color="Other/Danger">
                            {error.purchasePrice ? ` • ราคาขายให้โรงงาน` : ""}
                          </Typography>
                          <Typography Typography color="Other/Danger">
                            {error.minPurchaseVolume ? ` • ปริมาณขั้นต่ำ` : ""}
                          </Typography>
                          <Typography Typography color="Other/Danger">
                            {error.maxPurchaseVolume
                              ? ` • ปริมาณที่โรงงานต้องการ`
                              : ""}
                          </Typography>
                        </Box>
                      )
                    )}

                    {values.scheduleType === "one-demand" && (
                      <Typography color="Other/Danger">
                        {errors.deliverDate ? ` • วันที่ส่งปลา` : ""}
                      </Typography>
                    )}

                    {values.scheduleType === "daily" && (
                      <Box>
                        <Typography color="Other/Danger">
                          {errors.startDate ? ` • วันที่ส่งปลา` : ""}
                        </Typography>
                        <Typography color="Other/Danger">
                          {errors.endDate ? ` • วันที่สิ้นสุดการส่งปลา` : ""}
                        </Typography>
                        <Typography color="Other/Danger">
                          {errors.dailyRepeat ? ` • ส่งซ้ำทุกๆ` : ""}
                        </Typography>
                      </Box>
                    )}

                    {values.scheduleType === "weekly" && (
                      <Box>
                        <Typography color="Other/Danger">
                          {errors.startDate ? ` • วันที่ส่งปลา` : ""}
                        </Typography>
                        <Typography color="Other/Danger">
                          {errors.endDate ? ` • วันที่สิ้นสุดการส่งปลา` : ""}
                        </Typography>
                        <Typography color="Other/Danger">
                          {errors.weeklyDeliveryDates
                            ? ` • วันที่ต้องส่งในแต่ละสัปดาห์ (อาทิตย์ - เสาร์)`
                            : ""}
                        </Typography>
                      </Box>
                    )}

                    {values.scheduleType === "monthly" && (
                      <Box>
                        <Typography color="Other/Danger">
                          {errors.startDate ? ` • วันที่ส่งปลา` : ""}
                        </Typography>
                        <Typography color="Other/Danger">
                          {errors.endDate ? ` • วันที่สิ้นสุดการส่งปลา` : ""}
                        </Typography>
                        <Typography color="Other/Danger">
                          {errors.monthlyDeliveryDates
                            ? ` • วันที่ต้องส่งในแต่ละเดือน (1-28)`
                            : ""}
                        </Typography>
                      </Box>
                    )}
                  </>
                )}
              </Box>
            ),
            okButtonLabel: "ปิด",
          });
        } else {
          await createFactoryOrderSchedule({
            variables: {
              ...params,
            },
          });
          paths.factoryOrderSchedulePath().push();
          notifySuccess("เพิ่มข้อมูลสำเร็จ");
        }
      } catch (e) {
        Modal.alert({
          title: "เกิดข้อผิดพลาด",
          children: `${getErrorMessage(e)}`,
          okButtonLabel: "ปิด",
          okButtonVariant: "button",
          onOk: ({ close }) => {
            close();
            paths.factoryOrderScheduleNewPath().push();
          },
        });
      }
    }, [values, errors, createFactoryOrderSchedule]);

    const fishDepreciation = useMemo(() => {
      return (
        factoryData?.factory?.fishDepreciation.filter(
          (fishDep) => fishDep.fish?.availableStatus === true
        ) || []
      );
    }, [factoryData]);

    const selectFactory = useCallback(
      (id) => {
        const foundedFactory = find(factoryOptions, {
          value: id,
        });

        if (!foundedFactory) {
          setFactoryOrderData({
            address: null,
            phoneNumber: null,
            remark: null,
          });
          return;
        }

        setFactoryOrderData({
          address: foundedFactory?.address,
          phoneNumber: foundedFactory?.phoneNumber,
          remark: foundedFactory?.remark,
        });

        fetchFactory({
          variables: {
            id: foundedFactory?.value,
          },
        });
      },
      [factoryOptions, setFactoryOrderData, fetchFactory]
    );

    const { address, phoneNumber, remark } = factoryOrderData;

    return {
      title,
      breadcrumbs,
      cancelButtonLabel: "ยกเลิก",
      submitButtonLabel: "สร้างความต้องการซื้อ",
      factoryOptions,
      address,
      phoneNumber,
      remark,
      onCancel,
      isView,
      fishes,
      fishDepreciation,
      scheduleType,
      selectFactory,
      submit,
      setFieldValue,
      startDate: values.startDate,
    };
  })
);

export default enhancer(FactoryOrderScheduleNewPage);

import { compose, withHooks, withFormik } from "enhancers";
import { PageContent } from "layouts";
import { Modal, Typography, Box } from "components";
import {
  gql,
  notifyError,
  notifySuccess,
  paths,
  cleanTypename,
  Yup,
  getErrorMessage,
} from "utils/helper";
import { FactoryForm } from "./new";
import { uniqBy } from "lodash";
import { useCallback, useState } from "react";

const FactoryEditPage = (props) => (
  <PageContent title={props.title} breadcrumbs={props.breadcrumbs}>
    <FactoryForm {...props} />
  </PageContent>
);

const API = {
  FETCH_FACTORY: gql`
    query FETCH_FACTORY($id: ID!) {
      factory(id: $id) {
        id
        code
        name
        phoneNumber
        remark
        googleMapLink
        availableStatus
        images
        address {
          address
          district
          subDistrict
          province
          zipCode
        }
        fishDepreciation {
          fishId
          gradeADepreciationPct
          gradeBDepreciationPct
          gradeCDepreciationPct
          gradeDDepreciationPct
          purchasePrice
        }
      }
    }
  `,
  UPDATE_FACTORY: gql`
    mutation UPDATE_FACTORY(
      $id: String!
      $name: String
      $phoneNumber: String
      $remark: String
      $googleMapLink: String
      $availableStatus: Boolean
      $address: AddressArguments
      $images: [Upload!]
      $fishDepreciation: [FishDepreciationArguments!]
    ) {
      updateFactory(
        input: {
          id: $id
          name: $name
          phoneNumber: $phoneNumber
          remark: $remark
          googleMapLink: $googleMapLink
          availableStatus: $availableStatus
          address: $address
          images: $images
          fishDepreciation: $fishDepreciation
        }
      ) {
        factory {
          id
          name
        }
      }
    }
  `,
};

const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({}),
    validationSchema: Yup.object().shape({
      name: Yup.string().required("ต้องไม่เว้นว่างไว้"),
      phoneNumber: Yup.string().required("ต้องไม่เว้นว่างไว้"),
      googleMapLink: Yup.string().required("ต้องไม่เว้นว่างไว้"),
      address: Yup.object().shape({
        address: Yup.string().required("ต้องไม่เว้นว่างไว้"),
        district: Yup.string().required("ต้องไม่เว้นว่างไว้"),
        subDistrict: Yup.string().required("ต้องไม่เว้นว่างไว้"),
        province: Yup.string().required("ต้องไม่เว้นว่างไว้"),
        zipCode: Yup.string().required("ต้องไม่เว้นว่างไว้"),
      }),
    }),
  }),

  withHooks((props, hooks) => {
    const { setValues, values, errors } = props;
    const { googleMapLink } = values || {};
    const { useMemo, useQuery, useMutation, useEffect, useParams } = hooks;

    const { id } = useParams();

    const { loading, data, error, refetch } = useQuery(API.FETCH_FACTORY, {
      variables: { id },
    });
    const [updateFactory] = useMutation(API.UPDATE_FACTORY);
    const [oldValue, setOldValue] = useState();

    useEffect(() => {
      refetch();
    }, [refetch]);

    const factory = useMemo(() => {
      if (loading) {
        return null;
      }
      if (error) {
        const message = getErrorMessage(error);
        notifyError(message);
        paths.factoriesPath().push();
        return null;
      }
      if (data.factory !== undefined) {
        setOldValue(data.factory);
      }
      return data.factory;
    }, [loading, data, error]);

    const title = factory?.code;
    const breadcrumbs = useMemo(() => {
      return [
        { path: paths.homePath(), label: "หน้าแรก" },
        { path: paths.factoriesPath(), label: "จัดการโรงงาน" },
        { path: null, label: title },
      ];
    }, [title]);

    useEffect(() => {
      if (factory) {
        const values = cleanTypename(factory);
        values.factoryStatus = values.availableStatus.toString();

        setValues(values);
      }
    }, [setValues, factory]);

    const validFishDepreciation = useMemo(() => {
      let res = false;
      if (values.fishDepreciation && values.fishDepreciation.length > 0) {
        values.fishDepreciation.forEach((dep) => {
          if (
            dep.fishId === undefined ||
            dep.purchasePrice === undefined ||
            dep.gradeADepreciationPct === undefined ||
            dep.gradeBDepreciationPct === undefined ||
            dep.gradeCDepreciationPct === undefined ||
            dep.gradeDDepreciationPct === undefined
          ) {
            res = true;
          }
        });
      }
      return res;
    }, [values.fishDepreciation]);

    const submit = useCallback(async () => {
      values.availableStatus = values.factoryStatus === "true";
      try {
        if (Object.keys(errors).length > 0 || validFishDepreciation === true) {
          Modal.alert({
            title: "เกิดข้อผิดพลาด",
            children: (
              <Box>
                <Typography color="Text/Dark Grey" mb={2}>
                  กรุณากรอกข้อมูลต่อไปนี้ให้ครบ
                </Typography>
                <Typography color="Other/Danger">
                  {errors.name ? " • ชื่อ" : ""}
                </Typography>
                <Typography color="Other/Danger">
                  {errors.phoneNumber ? " • เบอร์โทรศัพท์" : ""}
                </Typography>
                <Typography color="Other/Danger">
                  {errors.address && errors.address.address ? " • ที่อยู่" : ""}
                </Typography>
                <Typography color="Other/Danger">
                  {errors.address && errors.address.subDistrict
                    ? " • แขวง/ตำบล"
                    : ""}
                </Typography>
                <Typography color="Other/Danger">
                  {errors.address && errors.address.district
                    ? " • เขต/อำเภอ"
                    : ""}
                </Typography>
                <Typography color="Other/Danger">
                  {errors.address && errors.address.province
                    ? " • จังหวัด"
                    : ""}
                </Typography>
                <Typography color="Other/Danger">
                  {errors.address && errors.address.zipCode
                    ? " • รหัสไปรษณีย์"
                    : ""}
                </Typography>
                <Typography color="Other/Danger">
                  {errors.googleMapLink ? " • Google map link" : ""}
                </Typography>
                <Typography color="Other/Danger">
                  {validFishDepreciation === true
                    ? " • ข้อมูลราคารับซื้อและค่าเสื่อมปลา"
                    : ""}
                </Typography>
              </Box>
            ),
            okButtonLabel: "ปิด",
          });
        } else if (
          values.fishDepreciation.length >
          uniqBy(values.fishDepreciation, "fishId").length
        ) {
          Modal.alert({
            title: "เกิดข้อผิดพลาด",
            children: `ข้อมูลปลา ในข้อมูลราคารับซื้อและค่าเสื่อมปลาห้ามซ้ำกัน`,
            okButtonLabel: "ปิด",
            okButtonVariant: "button",
            onOk: async ({ close }) => {
              await close();
              await paths.factoryEditPath(id).push();
            },
          });
        } else {
          await updateFactory({ variables: values });
          paths.factoriesPath().push();
          notifySuccess("แก้ไขข้อมูลสำเร็จ");
        }
      } catch (e) {
        Modal.alert({
          title: "เกิดข้อผิดพลาด",
          children: `${getErrorMessage(e)}`,
          okButtonLabel: "ปิด",
          okButtonVariant: "button",
          onOk: async ({ close }) => {
            await close();
            await paths.factoryEditPath(id).push();
          },
        });
      }
    }, [values, errors, updateFactory, id, validFishDepreciation]);

    const isDirty = useCallback(
      (values) => {
        if (
          values.factoryStatus !== oldValue.availableStatus.toString() ||
          values.name !== oldValue.name ||
          values.phoneNumber !== oldValue.phoneNumber ||
          values.googleMapLink !== oldValue.googleMapLink ||
          values.remark !== oldValue.remark ||
          values.address.address !== oldValue.address.address ||
          values.address.district !== oldValue.address.district ||
          values.address.subDistrict !== oldValue.address.subDistrict ||
          values.address.province !== oldValue.address.province ||
          values.address.zipCode !== oldValue.address.zipCode ||
          values.images !== oldValue.images ||
          values.fishDepreciation !== oldValue.fishDepreciation
        )
          return true;
        return false;
      },
      [oldValue]
    );

    const cancelFactory = useCallback(() => {
      if (isDirty(values)) {
        Modal.open({
          className: "ConfirmLeavePage",
          title: "ยกเลิกการแก้ไขข้อมูล",
          children: `ข้อมูลจะไม่ถูกบันทึกและไม่สามารถกู้คืนได้`,
          cancelButtonLabel: "ปิด",
          okButtonLabel: "ยกเลิกการแก้ไขข้อมูล",
          onOk: async ({ close }) => {
            try {
              close();
              await paths.factoriesPath().push();
            } catch (e) {}
          },
        });
      } else {
        paths.factoriesPath().push();
      }
    }, [values, isDirty]);

    const fishDepreciation = values.fishDepreciation;
    const factoryStatus = values.factoryStatus;
    const factoryAvailableStatus = useMemo(() => {
      return [
        { label: "ใช้งาน", value: "true" },
        { label: "ไม่ใช้งาน", value: "false" },
      ];
    }, []);

    return {
      values,
      title,
      breadcrumbs,
      factoryStatus,
      googleMapLink,
      cancelButtonLabel: "ยกเลิก",
      submitButtonLabel: "บันทึก",
      fishDepreciation,
      submit,
      cancelFactory,
      factoryAvailableStatus,
    };
  })
);

export default enhancer(FactoryEditPage);

import { flatten, uniq, uniqBy } from "lodash";
import addressData from "./address_data.json";

export const subDistrictOptions = uniqBy(
  flatten(
    addressData.map(([province, districtData]) =>
      flatten(
        districtData.map(([district, subDistrictData]) =>
          flatten(
            subDistrictData.map(([subDistrict, postcodeData]) =>
              flatten(
                postcodeData.map((postcode) => ({
                  label: `${subDistrict} » ${district} » ${province} » ${postcode}`,
                  value: `${subDistrict} » ${district} » ${province} » ${postcode}`,
                }))
              )
            )
          )
        )
      )
    )
  ),
  "label"
);

export const districtOptions = uniqBy(
  uniq(
    flatten(
      addressData.map(([_, districtData]) =>
        districtData.map(([district]) => district)
      )
    )
  ).map((district) => ({
    label: district,
    value: district,
  })),
  "label"
);

export const provinceOptions = uniqBy(
  addressData.map(([province]) => ({
    label: province,
    value: province,
  })),
  "label"
);

export const zipCodeOptions = uniqBy(
  flatten(
    addressData.map(([province, districtData]) =>
      flatten(
        districtData.map(([district, subDistrictData]) =>
          flatten(
            subDistrictData.map(([subDistrict, postcodeData]) =>
              flatten(
                postcodeData.map((postcode) => ({
                  label: `${postcode}`,
                  value: `${postcode}`,
                }))
              )
            )
          )
        )
      )
    )
  ),
  "label"
);

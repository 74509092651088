import React from "react";
import { compose, withHooks, withFormik } from "enhancers";
import {
  Form,
  Field,
  TextField,
  Button,
  Alert,
  Avatar,
  Paper,
  Typography,
  Helmet,
  Modal,
} from "components";
import { gql, Yup, paths, notifyError } from "utils/helper";

type ResetPasswordPageProps = {
  errorMessage?: string;
};

const ResetPasswordPage = (props: ResetPasswordPageProps) => (
  <Paper width="100%" p={10}>
    <Helmet title="Setup Password" />
    <Avatar
      alt="Moreloop"
      src="/static/img/logo.jpg"
      variant="square"
      mx="auto"
    />
    <Typography component="h1" variant="h4" align="center" mt={10}>
      ลืมรหัสผ่าน
    </Typography>
    <Typography component="h2" variant="caption" align="center" mt={2}>
      กรอกอีเมลเพื่อสร้างรหัสผ่านในการเข้าสู่ระบบใหม่
    </Typography>
    <Form>
      {props.errorMessage && (
        <Alert severity="error" mt={6}>
          {props.errorMessage}
        </Alert>
      )}
      <Field
        component={TextField.Email}
        name="email"
        label="อีเมล"
        fullWidth
        mt={6}
      />

      <Button type="submit" color="primary" fullWidth mt={10}>
        ส่ง
      </Button>
    </Form>
  </Paper>
);

const API = {
  RESET_PASSWORD: gql`
    mutation RESET_PASSWORD($email: String!) {
      resetPassword(input: { email: $email }) {
        currentUser {
          email
        }
      }
    }
  `,
};

const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({
      email: "",
    }),
    validationSchema: Yup.object().shape({
      email: Yup.string().email("รูปแบบอีเมลไม่ถูกต้อง"),
    }),
  }),
  withHooks((props: any, hooks: any) => {
    const { useHandleSubmit, useMutation, useMemo } = hooks;

    const [resetPassword, { loading, error }] = useMutation(API.RESET_PASSWORD);

    useHandleSubmit(
      async (values: any) => {
        try {
          const { email } = values;
          await resetPassword({ variables: { email } });

          const closeModalAndGotoSignin = ({ close }: any) => {
            close();
            paths.signInPath().push();
          };

          Modal.alert({
            title: "ส่งเมลสำเร็จ",
            children: `ลิงก์สำหรับสร้างรหัสผ่านใหม่ได้ถูกส่งไปที่อีเมล ${email} เรียบร้อยแล้ว กรุณาคลิกลิงก์จากอีเมลเพื่อสร้างรหัสผ่านในการเข้าสู่ระบบ`,
            okButtonLabel: "ปิด",
            okButtonVariant: "text",
            onOk: closeModalAndGotoSignin,
            onClose: closeModalAndGotoSignin,
          });
        } catch (e: any) {
          notifyError(e);
        }
      },
      [resetPassword]
    );

    const errorMessage = useMemo(() => {
      if (!loading && error) {
        return "ไม่พบอีเมลนี้ กรุณาติดต่อผู้ดูแลระบบ";
      }
    }, [loading, error]);

    return {
      errorMessage,
    };
  })
);

export default enhancer(ResetPasswordPage);

import React, { useEffect } from "react";
import { compose, withHooks, withFormik } from "enhancers";
import {
  Form,
  Field,
  TextField,
  Button,
  Avatar,
  Paper,
  Typography,
  Helmet,
  Box,
} from "components";
import { gql, Yup, paths, notifySuccess } from "utils/helper";
import { setToken } from "api";
import { isEmpty } from "lodash";
import styled from "styled-components";

import { ReactComponent as Logo } from "assets/image/fishyu_logo.svg";

const CustomBox = styled(Box)`
  div {
    margin-top: 0px;
  }
`;

type SignInPageProps = {
  seconds?: number;
  resendOTP?: any;
  refNo?: string;
  disabled?: boolean;
  disableOtpButton?: boolean;
  disableOtpField?: boolean;
};

const SignInPage = (props: SignInPageProps) => (
  <Paper width="100%" p={10}>
    <Helmet title="Sign in" />
    <Box display="flex" justifyContent="center">
      <Logo />
    </Box>
    <Typography component="h1" variant="h4" align="center" mt={10}>
      เข้าสู่ระบบ
    </Typography>
    <Typography component="h2" variant="caption" align="center" mt={2}>
      กรอกเบอร์โทรศัพท์ของคุณและ OTP เพื่อเข้าสู่ระบบ
    </Typography>
    <Form>
      <Box display="flex">
        <Field
          component={TextField.PhoneNumber}
          name="phoneNumber"
          label="เบอร์โทรศัพท์"
          fullWidth
          mt={6}
        />
        <Box>
          <Button
            color="primary"
            onClick={props.resendOTP}
            disabled={props.seconds || props.disableOtpButton}
            mt={6}
            ml={4}
            height={56}
            width={120}
          >
            {props.seconds ? `ส่ง OTP (${props.seconds})` : "ส่ง OTP"}
          </Button>
        </Box>
      </Box>

      <Box mt={13} mb={2} textAlign="end">
        {props.refNo && (
          <Typography variant="body1" color="Text/Dark Grey" mt={-7}>
            โค้ดยืนยัน: {props.refNo}
          </Typography>
        )}
      </Box>
      <CustomBox>
        <Field
          component={TextField}
          name="otp"
          label="รหัส OTP"
          fullWidth
          mt={6}
          disabled={props.disableOtpField}
        />
      </CustomBox>

      <Button
        type="submit"
        color="primary"
        fullWidth
        mt={10}
        disabled={props.disabled}
      >
        เข้าสู่ระบบ
      </Button>
    </Form>
  </Paper>
);

const API = {
  REQUEST_OTP: gql`
    mutation RequestOtp($phoneNumber: String!) {
      requestOtp(input: { phoneNumber: $phoneNumber, isMock: true }) {
        otpData {
          status
          token
          refno
        }
      }
    }
  `,
  VERIFY_OTP: gql`
    mutation VerifyOtp(
      $otp: String!
      $token: String!
      $phoneNumber: String!
      $userType: String!
    ) {
      verifyOtp(
        input: {
          otp: $otp
          token: $token
          phoneNumber: $phoneNumber
          userType: $userType
          isMock: true
        }
      ) {
        backofficeUser {
          authenticationToken
        }
      }
    }
  `,
};

const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({
      phoneNumber: "",
      otp: "",
    }),
    validationSchema: Yup.object().shape({
      phoneNumber: Yup.string().required("ต้องไม่เว้นว่างไว้"),
      otp: Yup.string().required("ต้องไม่เว้นว่างไว้"),
    }),
  }),

  withHooks((props: any, hooks: any) => {
    const {
      useHandleSubmit,
      useMutation,
      useCallback,
      useState,
      useFormikContext,
    } = hooks;
    const form = useFormikContext();
    const { values, setErrors } = props;
    const [requestOtp] = useMutation(API.REQUEST_OTP);
    const [verifyOtp] = useMutation(API.VERIFY_OTP);

    const [seconds, setSeconds] = useState(0);
    const [refNo, setRefNo] = useState("");
    const [otpToken, setOtpToken] = useState(null);

    useHandleSubmit(
      async (values: any) => {
        const { phoneNumber, otp } = values;
        try {
          const { data } = await verifyOtp({
            variables: { otp, phoneNumber, token: otpToken, userType: "admin" },
          });
          const { backofficeUser } = data?.verifyOtp;

          const authenticationToken = backofficeUser.authenticationToken;
          setToken(authenticationToken);

          paths.homePath().reload();
        } catch (e) {
          setErrors({
            __error__: "รหัส OTP ไม่ถูกต้อง",
            otp: "รหัส OTP ไม่ถูกต้อง",
          });
        }
      },
      [verifyOtp, otpToken]
    );

    const resendOTP = useCallback(async () => {
      const { phoneNumber } = values;
      try {
        const { data } = await requestOtp({
          variables: { phoneNumber },
        });
        const { refno, token } = data?.requestOtp.otpData;
        setRefNo(refno);
        setOtpToken(token);
        notifySuccess("กรุณาตรวจสอบหมายเลข OTP ในกล่องข้อความ");
        setSeconds(60);
      } catch (e) {
        setErrors({
          __error__:
            "ไม่พบเบอร์โทรนี้ในระบบ กรุณาติดต่อ 08X-XXX-XXXX เพื่อลงทะเบียน",
          phoneNumber:
            "ไม่พบเบอร์โทรนี้ในระบบ กรุณาติดต่อ 08X-XXX-XXXX เพื่อลงทะเบียน",
        });
      }
    }, [setSeconds, requestOtp, values, setRefNo, setOtpToken, setErrors]);

    useEffect(() => {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }, [seconds]);

    const disabled = !isEmpty(form?.errors);
    const disableOtpButton = form?.errors.phoneNumber;
    const disableOtpField = isEmpty(otpToken);

    return {
      seconds,
      resendOTP,
      refNo,
      disabled,
      disableOtpButton,
      disableOtpField,
    };
  })
);

export default enhancer(SignInPage);

import { compose, withHooks } from "enhancers";
import { Box, BrowseButton, Field, ImageLightbox } from "components";
import Image from "./Image";
import { get, range, map } from "lodash";

const ImageUpload = (props) => (
  <Box {...props}>
    <Box display="flex" alignItems="center" justifyContent="center">
      {range(props.amount).map((index) => {
        return (
          <Box key={index} mt={6}>
            <Box onClick={() => props.openImageLightbox(index)}>
              <Field component={Image} name={`${props.name}[${index}]`} />
            </Box>
          </Box>
        );
      })}
    </Box>
    <Box display="flex" alignItems="center" justifyContent="center">
      <BrowseButton
        startIcon={props.canAddMore ? "add" : "edit"}
        onBrowse={props.addImage}
        mt={6}
        width={171}
        accept="image/*"
      >
        {props.canAddMore ? "เพิ่มรูป" : "แก้ไขรูป"}
      </BrowseButton>
    </Box>
  </Box>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const {
      form,
      name,
      insert,
      remove,
      title,
      mt,
      label,
      onRemoveImage,
    } = props;
    const { useCallback } = hooks;
    const values = get(form.values, name);
    const amount = values?.length ?? 0;
    const canAddMore = amount < 1;

    const removeImage = useCallback(
      (index) => {
        remove(index);
        if (onRemoveImage) {
          onRemoveImage(index, props);
        }
      },
      [remove, onRemoveImage, props]
    );

    const addImage = useCallback(
      (file) => {
        if (canAddMore) {
          insert(amount, file);
        } else {
          removeImage();
          insert(amount, file);
        }
      },
      [insert, amount, canAddMore, removeImage]
    );

    const openImageLightbox = useCallback(
      (index) => {
        const images = map(values, (file, index) => {
          return {
            url: file instanceof File ? URL.createObjectURL(file) : null,
            title: `รูปที่ ${index + 1}`,
          };
        });
        ImageLightbox.open({
          images,
          startIndex: index,
        });
      },
      [values]
    );

    return {
      name,
      amount,
      addImage,
      removeImage,
      title,
      mt,
      label,
      openImageLightbox,
      canAddMore,
    };
  })
);

export default enhancer(ImageUpload);

import {
  Box,
  Button,
  DatePicker,
  Field,
  Form,
  Grid,
  Select,
  Table,
  TextField,
} from "components";
import { compose, defaultProps, withHooks } from "enhancers";
import { withFormik } from "formik";
import { find } from "lodash";
import { toCurrency, gql } from "utils/helper";

const FishDemandModal = (props) => (
  <Form>
    <Box style={{ background: "#F6F9FC", borderRadius: 8 }} p={4} mb={4}>
      <Grid container spacing={6} mb={3}>
        <Grid item xs={12}>
          <Field
            component={Select}
            name="fish"
            type="text"
            label="ชื่อปลา"
            options={props.fishOptions}
            freeSolo
            fullWidth
            // fuse
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            component={TextField}
            name="minPurchasePrice"
            type="number"
            label="ราคารับซื้อต่ำสุด"
            fullWidth
            unit="บาท"
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            component={Select}
            name="orderBy"
            type="text"
            label="เรียงตาม"
            options={props.orderByOptions}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            component={DatePicker}
            name="startDate"
            // style={{ width: "264px" }}
            label="ช่วงวันส่งปลาตั้งแต่วันที่"
            placeholder=""
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            component={DatePicker}
            name="endDate"
            // style={{ width: "264px" }}
            label="ถึงวันที่"
            placeholder=""
            fullWidth
          />
        </Grid>
      </Grid>

      <Box display="flex" alignItems="center" fullWidth>
        <Box display="flex" flex={1} flexDirection="row" fullWidth></Box>
        <Button
          color=""
          // mt={6}
          onClick={props.clearFilter}
        >
          รีเซ็ตตัวกรอง
        </Button>
        <Button
          className="FishDemandModalFilterButton"
          color="primary"
          onClick={props.onFilter}
          // mt={6}
          ml={4}
          width={118}
        >
          กรอง
        </Button>
      </Box>
    </Box>

    <Table
      className="FishDemandTable"
      columns={[
        { width: 180, field: "fishName", headerName: "ชนิดปลา" },
        {
          width: 150,
          type: "currency",
          field: "purchaseVolume",
          headerName: "รับซื้อ (กก.)",
        },
        {
          width: 150,
          field: "purchasePrice",
          headerName: "กก. ละ",
        },
        {
          width: 150,
          field: "receiveDate",
          headerName: "วันที่รับปลา",
        },
      ]}
      rows={props.tableData}
      loading={props.loading}
      density="compact"
      autoHeight
      disableSelectionOnClick
      //   includeToolbar
      onSelectionModelChange={props.onSelectionChange}
      isRowSelectable={(params) =>
        !props.selectedId || params.row.id === props.selectedId
      }
      displaySelectAll={false}
      // paginationMode="server"
      // filterMode="server"
      // sortingMode="server"
      // refetch={props.refetch}
      checkboxSelection={true}
      rowCount={props.rowCount}
      initialFilter={{
        items: [],
      }}
    />
  </Form>
);

export const API = {
  FISH_DEMANDS: gql`
    query FISH_DEMANDS($filters: JSON) {
      fishDemands(filters: $filters) {
        fishDemands {
          id
          fishName
          fishId
          purchaseVolume
          minPurchasePrice
          maxPurchasePrice
          receiveDate
          availableStatus
        }
      }
    }
  `,
  FETCH_FISHES: gql`
    query FETCH_FISHES {
      fishes {
        id
        code
        name
        factoryPurchasePricePct
        remark
        availableStatus
      }
    }
  `,
};

const enhancer = compose(
  defaultProps({}),
  withFormik({
    mapPropsToValues: () => ({
      orderBy: "priceDesc",
    }),
  }),
  withHooks((props, hooks) => {
    const { useQuery, useMemo, useEffect, useCallback, useState } = hooks;
    const { setSelectedData, values, setValues } = props;
    const { loading, data, error, refetch } = useQuery(API.FISH_DEMANDS, {
      variables: {
        filters: { orderBy: "priceDesc" },
      },
    });
    const fetchFishes = useQuery(API.FETCH_FISHES);

    const [selectedId, setSelectedId] = useState(null);

    useEffect(() => {
      refetch();
    }, [refetch]);

    const tableData = useMemo(() => {
      if (loading || error) {
        return [];
      }

      return data.fishDemands.fishDemands
        .filter((fishDem) => fishDem.availableStatus === true)
        .map((demand) => {
          const {
            id,
            fishName,
            fishId,
            purchaseVolume,
            minPurchasePrice,
            maxPurchasePrice,
            receiveDate,
          } = demand;

          const purchasePrice =
            minPurchasePrice === maxPurchasePrice
              ? `${toCurrency(minPurchasePrice)}`
              : `${toCurrency(minPurchasePrice)} - ${toCurrency(
                  maxPurchasePrice
                )}`;
          return {
            id,
            fishName,
            fishId,
            purchaseVolume,
            purchasePrice,
            minPurchasePrice,
            maxPurchasePrice,
            receiveDate: receiveDate,
          };
        });
    }, [loading, data, error]);

    const onSelectionChange = useCallback(
      (id) => {
        const fishDemand = find(tableData, { id: id[0] });

        if (!id.length) {
          setSelectedId(null);
        }

        if (fishDemand) {
          setSelectedData(fishDemand);
          setSelectedId(fishDemand?.id);
        }
      },
      [tableData, setSelectedData, setSelectedId]
    );

    const fishOptions = useMemo(() => {
      if (fetchFishes) {
        if (fetchFishes.loading || fetchFishes.error) {
          return [];
        }

        return fetchFishes.data.fishes
          .filter((fish) => fish.availableStatus === true)
          .map((fish) => {
            const { id, name } = fish;

            return {
              value: id,
              label: name,
            };
          });
      }
    }, [fetchFishes]);

    const orderByOptions = useMemo(() => {
      return [
        { label: "ราคาเรียงจากมากไปน้อย", value: "priceDesc" },
        { label: "จำนวนที่ต้องการจากมากไปน้อย", value: "amountDesc" },
        { label: "จำนวนที่ต้องการจากน้อยไปมาก", value: "amountAsc" },
        { label: "วันที่ต้องการจากล่าสุด", value: "newestDate" },
        { label: "วันที่ต้องการจากเก่าสุด", value: "oldestDate" },
      ];
    }, []);

    const onFilter = useCallback(async () => {
      await refetch({
        filters: { ...values, fishId: values.fish },
      });
    }, [values, refetch]);

    const clearFilter = useCallback(async () => {
      await refetch({
        filters: { orderBy: "priceDesc" },
      });
      setValues({
        fish: null,
        minPurchasePrice: null,
        orderBy: "priceDesc",
        startDate: null,
        endDate: null,
      });
    }, [setValues, refetch]);

    return {
      tableData,
      loading,
      onSelectionChange,
      orderByOptions,
      selectedId,
      fishOptions,
      onFilter,
      clearFilter,
    };
  })
);

export default enhancer(FishDemandModal);

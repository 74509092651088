import { compose, withFormik, withHooks, defaultProps } from "enhancers";
import { PageContent } from "layouts";
import {
  Box,
  Grid,
  Typography,
  Form,
  Field,
  TextField,
  RadioGroup,
  Divider,
  Button,
  Modal,
} from "components";
import { gql, getErrorMessage, notifySuccess, paths, Yup } from "utils/helper";

export const FishesForm = (props) => (
  <Form>
    <Box display="flex" alignItems="center" fullWidth mb={1}>
      <Box display="flex" flex={1} flexDirection="row" fullWidth>
        <Typography variant="h4" mb={6}>
          ข้อมูลปลา
        </Typography>
      </Box>
      <Typography variant="body1" display="inline" mb={6}>
        สถานะ
      </Typography>
      <Field
        mb={6}
        component={RadioGroup}
        name="fishStatus"
        options={props.fishAvailableStatus}
        row
        className="tax_payer_type_radio_group"
        disabled={props.disabled}
      />
    </Box>
    <Grid container spacing={6} mb={4}>
      <Grid item xs={6}>
        <Field
          component={TextField}
          name="name"
          type="text"
          label="ชื่อ"
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextField}
          name="factoryPurchasePricePct"
          type="number"
          label="ราคารับซื้อจากชาวประมง (คิดเป็น % จากราคาโรงงาน)"
          fullWidth
          required
          unit="%"
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          component={TextField}
          name="remark"
          type="text"
          label="หมายเหตุ"
          fullWidth
        />
      </Grid>
    </Grid>
    <Divider mt={5} />
    <Box display="flex" alignItems="center" fullWidth>
      <Box display="flex" flex={1} flexDirection="row" fullWidth></Box>
      <Button
        color=""
        mt={7}
        mr={7}
        width="16%"
        onClick={() => {
          props.cancelFish();
        }}
      >
        {props.cancelButtonLabel}
      </Button>

      <Button onClick={props.submit} color="primary" mt={7} mr={6} width="16%">
        {props.submitButtonLabel}
      </Button>
    </Box>
  </Form>
);

const FishNewPage = (props) => (
  <PageContent title={props.title} breadcrumbs={props.breadcrumbs}>
    <FishesForm {...props} />
  </PageContent>
);

const API = {
  CREATE_FISH: gql`
    mutation CREATE_FISH(
      $name: String
      $factoryPurchasePricePct: Float
      $remark: String
      $availableStatus: Boolean
    ) {
      createFish(
        input: {
          name: $name
          factoryPurchasePricePct: $factoryPurchasePricePct
          remark: $remark
          availableStatus: $availableStatus
        }
      ) {
        fish {
          code
          name
        }
      }
    }
  `,
};

const enhancer = compose(
  defaultProps({
    fishAvailableStatus: [
      { label: "ใช้งาน", value: "true" },
      { label: "ไม่ใช้งาน", value: "false" },
    ],
  }),
  withFormik({
    mapPropsToValues: () => ({
      fishStatus: "true",
    }),
    validationSchema: Yup.object().shape({
      name: Yup.string().nullable().required("ต้องไม่เว้นว่างเอาไว้"),
      factoryPurchasePricePct: Yup.string()
        .nullable()
        .required("ต้องไม่เว้นว่างเอาไว้"),
    }),
  }),
  withHooks((props, hooks) => {
    const { useMemo, useMutation, useCallback } = hooks;
    const { values, errors } = props;

    const [createFishes] = useMutation(API.CREATE_FISH);
    const title = `เพิ่มปลา`;
    const breadcrumbs = useMemo(() => {
      return [
        { path: paths.homePath(), label: "หน้าแรก" },
        { path: paths.fishManagementPath(), label: "จัดการปลา" },
        { path: null, label: title },
      ];
    }, [title]);

    const isDirty = useCallback((values) => {
      if (values.fishermanStatus === "false") {
        return true;
      }
      if (
        values.name ||
        values.factoryPurchasePricePct ||
        values.availableStatus ||
        values.remark !== undefined
      ) {
        return true;
      } else {
        return false;
      }
    }, []);

    const cancelFish = useCallback(() => {
      if (isDirty(values)) {
        Modal.open({
          title: "ยกเลิกการแก้ไขข้อมูล",
          children: `ข้อมูลจะไม่ถูกบันทึกและไม่สามารถกู้คืนได้`,
          cancelButtonLabel: "ปิด",
          okButtonLabel: "ยกเลิกการแก้ไขข้อมูล",
          onOk: ({ close }) => {
            close();
            paths.fishManagementPath().push();
          },
        });
      } else {
        paths.fishManagementPath().push();
      }
    }, [isDirty, values]);

    const submit = useCallback(async () => {
      values.availableStatus = values.fishStatus === "true";
      try {
        if (Object.keys(errors).length !== 0) {
          Modal.alert({
            title: "เกิดข้อผิดพลาด",
            children: (
              <Box>
                <Typography color="Text/Dark Grey" mb={2}>
                  กรุณากรอกข้อมูลต่อไปนี้ให้ครบ
                </Typography>
                <Typography color="Other/Danger" mt={1}>
                  {errors.name ? " • ชื่อ" : ""}
                </Typography>
                <Typography color="Other/Danger" mt={1}>
                  {errors.factoryPurchasePricePct
                    ? " • ราคารับซื้อจากชาวประมง (คิดเป็น % จากราคาโรงงาน)"
                    : ""}
                </Typography>
              </Box>
            ),
            okButtonLabel: "ปิด",
          });
        } else {
          await createFishes({ variables: values });
          paths.fishManagementPath().push();
          notifySuccess("เพิ่มข้อมูลสำเร็จ");
        }
      } catch (e) {
        if (
          getErrorMessage(e) ===
          "Validation failed: Name has already been taken"
        ) {
          Modal.alert({
            title: "เกิดข้อผิดพลาด",
            children: (
              <Box>
                <Typography color="Text/Dark Grey" mb={2}>
                  {"ข้อมูลต่อไปนี้ต้องไม่ซ้ำกับที่มีอยู่ในระบบ"}
                </Typography>
                <Typography color="Other/Danger">{" • ชื่อ"}</Typography>
              </Box>
            ),
            okButtonLabel: "ปิด",
            okButtonVariant: "button",
            onOk: ({ close }) => {
              close();
            },
          });
        } else {
          Modal.alert({
            title: "เกิดข้อผิดพลาด",
            children: `${getErrorMessage(e)}`,
            okButtonLabel: "ปิด",
            okButtonVariant: "button",
            onOk: ({ close }) => {
              close();
            },
          });
        }
      }
    }, [createFishes, errors, values]);

    const fishStatus = values.fishStatus;
    const fishAvailableStatus = useMemo(() => {
      return [
        { label: "ใช้งาน", value: "true" },
        { label: "ไม่ใช้งาน", value: "false" },
      ];
    }, []);

    return {
      title,
      breadcrumbs,
      cancelButtonLabel: "ยกเลิก",
      submitButtonLabel: "เพิ่มปลา",
      fishStatus,
      fishAvailableStatus,
      cancelFish,
      submit,
    };
  })
);

export default enhancer(FishNewPage);

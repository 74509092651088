import { compose, withHooks } from "enhancers";
import { Box, Link } from "components";
import { forceDownload, notifyError } from "utils/helper";
import { ReactComponent as DownloadIcon } from "assets/icon/download-blue-icon.svg";

const FileUpload = (props) => (
  <Box
    display="flex"
    onClick={props.download}
    mr={2}
    style={{ cursor: "pointer" }}
  >
    <Link
      variant="caption"
      color="Other/Info"
      mr={2}
      style={{
        textDecoration: "underline",
        color: "#376FD0",
      }}
    >
      {props.filename}
    </Link>
    <DownloadIcon />
  </Box>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const { useCallback } = hooks;
    const { field, form, label, filename, url, ...rest } = props;

    const download = useCallback(() => {
      if (filename && url) {
        const loadFile = async () => {
          try {
            const blob = await fetch(url).then((r) => r.blob());
            const file = new File([blob], filename, {
              type: blob.type,
            });

            if (file) {
              const url = URL.createObjectURL(file);
              forceDownload(url, filename);
            }
          } catch (e) {
            notifyError(e);
          }
        };

        loadFile();
      } else {
        alert("Coming Soon!");
      }
    }, [filename, url]);

    return {
      ...rest,
      filename,
      download: rest.download ?? download,
      label,
    };
  })
);

export default enhancer(FileUpload);

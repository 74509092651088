import { compose, withHooks } from "enhancers";
import { Box, Grid, Typography, Select } from "components";
import { gql, getErrorMessage, notifyError } from "utils/helper";
import { find } from "lodash";

const boxStyles = {
  backgroundColor: "#E7F4FD",
  borderRadius: "10px",
  padding: "16px",
};

const AssignShipper = (props) => (
  <div>
    <Box
      display="flex"
      flex={1}
      flexDirection="row"
      fullWidth
      alignItems="baseline"
    >
      <Typography variant="h4" mb={2} mr={2}>
        ข้อมูลพนักงานขนส่งที่รับผิดชอบ
      </Typography>
      <Typography variant="caption" mb={2}>
        แนะนำให้ใช้พนักงาน {props.shipperPerWeight} คน
      </Typography>
    </Box>
    <Grid item xs={12} pr="6px !important" mt={3} mb={4}>
      <Select
        type="text"
        label="รหัส/ชื่อพนักงาน"
        fullWidth
        required
        options={props.shipperOptions}
        disabled={props.shouldDropdownDisabled}
        field={props.field}
        form={props.form}
        onSelect={props.handleSelect}
        filterSelectedOptions
      />
    </Grid>

    <Box fullWidth mb={2} style={boxStyles}>
      <Grid container spacing={12}>
        <Box
          display="flex"
          flex={1}
          flexDirection="row"
          fullWidth
          alignItems="center"
          mb={2}
        >
          <Grid container spacing={12}>
            <Grid item xs={2}>
              <Typography variant="body1">ทะเบียนรถ</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body1">:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">
                {props.licensePlateNumber}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid container spacing={12}>
        <Box
          display="flex"
          flex={1}
          flexDirection="row"
          fullWidth
          alignItems="center"
          mb={2}
        >
          <Grid item xs={2}>
            <Typography variant="body1">โทรศัพท์</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body1">:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1">{props.phoneNumber}</Typography>
          </Grid>
        </Box>
      </Grid>
      <Grid container spacing={12}>
        <Box
          display="flex"
          flex={1}
          flexDirection="row"
          fullWidth
          alignItems="center"
          mb={2}
        >
          <Grid item xs={2}>
            <Typography variant="body1">หมายเหตุ</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body1">:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1"> {props.remark}</Typography>
          </Grid>
        </Box>
      </Grid>
    </Box>
  </div>
);

const API = {
  FETCH_SHIPPERS: gql`
    query FETCH_SHIPPERS {
      shippers {
        id
        code
        name
        phoneNumber
        licensePlateNumber
        remark
        availableStatus
      }
    }
  `,
};

const enhancer = compose(
  withHooks((props, hooks) => {
    const { useMemo, useQuery, useState, useCallback, useEffect } = hooks;
    const { shipperPerWeight, orderStatus, field, form } = props || {};
    const { value: fieldValue } = field || {};

    const [selectedShipperId, setSelectedShipperId] = useState(fieldValue);

    const { loading, data, error } = useQuery(API.FETCH_SHIPPERS, {
      variables: {
        sorts: [{ field: "code", sort: "asc" }],
      },
    });

    const handleSelect = useCallback(
      (value) => {
        setSelectedShipperId(value);
      },
      [setSelectedShipperId]
    );

    useEffect(() => {
      if (fieldValue) {
        setSelectedShipperId(fieldValue);
      }
    }, [fieldValue]);

    const shipperOptions = useMemo(() => {
      if (loading) {
        return [];
      }
      if (error) {
        const message = getErrorMessage(error);
        notifyError(message);
        return [];
      }

      const shipperList = data?.shippers.map((sp, index) => {
        return {
          label: `[${sp.code}] ` + sp.name,
          value: sp.id,
        };
      });

      return shipperList;
    }, [loading, data, error]);

    const shipperDetail = useMemo(() => {
      if (data?.shippers) {
        return find(data?.shippers, { id: selectedShipperId });
      } else return "";
    }, [data, selectedShipperId]);

    const shouldDropdownDisabled = orderStatus?.title !== "รอยืนยัน";

    const { licensePlateNumber, phoneNumber, remark } = shipperDetail || {};

    const recommendShipperAmount = Math.ceil(shipperPerWeight);

    return {
      cancelButtonLabel: "ยกเลิก",
      submitButtonLabel: "บันทึก",
      shipperOptions,
      licensePlateNumber,
      phoneNumber,
      remark,
      shipperPerWeight: recommendShipperAmount,
      shouldDropdownDisabled,
      handleSelect,
      field,
      form,
    };
  })
);

export default enhancer(AssignShipper);

import { find, has } from "lodash";
// import { jsPDF } from "jspdf";
// import { font as SarabunRegular } from "pages/main/products/PDFContent/assets/Sarabun-Regular-normal";
// import { font as SarabunSemiBold } from "pages/main/products/PDFContent/assets/Sarabun-SemiBold-normal";

export const isServer = typeof window === "undefined";
export const isClient = !isServer;

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function call(func) {
  func();
}

export const safe = (block, safeValue) => {
  try {
    return block();
  } catch (e) {
    return safeValue;
  }
};

export const safeAsync = async (block, safeValue) => {
  try {
    return await block();
  } catch (e) {
    return safeValue;
  }
};

export const AUTHENTICATION_TOKEN = "AUTHENTICATION_TOKEN";

export const getToken = () => {
  return localStorage.getItem(AUTHENTICATION_TOKEN);
};

export const setToken = (token) => {
  localStorage.setItem(AUTHENTICATION_TOKEN, token);
};

export const clearToken = () => {
  localStorage.removeItem(AUTHENTICATION_TOKEN);
};

export const showCompanyMenu = (isShow) => {
  if (isClient) {
    document.body.classList.remove("aside-secondary-disabled");
    document.body.classList.add("aside-secondary-enabled");

    if (!isShow) {
      document.body.classList.add("aside-minimize");
    } else {
      document.body.classList.remove("aside-minimize");
    }
  }
};

export const cleanFilter = (obj, name, options) => {
  if (has(obj, name) && !find(options, { value: obj[name] })) {
    obj[name] = undefined;
  }
};

// export const renderPDF = async (html) => {
//   const doc = new jsPDF("p", "pt", "a4");
//   // const doc = new jsPDF({
//   //   floatPrecision: 2,
//   //   unit: "pt",
//   //   lineWidth: 1,
//   // });

//   doc.addFileToVFS("Sarabun-Regular-normal.ttf", SarabunRegular);
//   doc.addFont("Sarabun-Regular-normal.ttf", "Sarabun-Regular", "normal");
//   doc.setFont("Sarabun-Regular");

//   doc.addFileToVFS("Sarabun-SemiBold-normal.ttf", SarabunSemiBold);
//   doc.addFont("Sarabun-SemiBold-normal.ttf", "Sarabun-SemiBold", "normal");
//   doc.setFont("Sarabun-SemiBold");

//   await doc.html(html, {
//     callback: function (doc) {
//       doc.save("product_qrcode.pdf");
//     },
//     margin: [10, 10],
//     html2canvas: {
//       scale: 0.6,
//     },
//   });
// };

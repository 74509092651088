import { Prompt } from "react-router-dom";
import { compose, withFormik, withHooks } from "enhancers";
import { PageContent } from "layouts";
import {
  cleanTypename,
  gql,
  notifySuccess,
  paths,
  Yup,
  getErrorMessage,
  notifyError,
} from "utils/helper";
import { ReactComponent as PlaceIcon } from "assets/icon/place.svg";
import {
  Box,
  Grid,
  Typography,
  Form,
  Field,
  TextField,
  Button,
  Divider,
  Modal,
} from "components";
import { useRef } from "react";
import { isEqual } from "lodash";
import Select from "components/common/Select";

const SettingsFrom = (props) => (
  <Form>
    <Box width="100%" mb={-6}>
      <Typography variant="h4" mb={12}>
        ตัวแปรในการคำนวณรายละเอียดคำสั่งซื้อ
      </Typography>

      <Grid container spacing={6} mb={4}>
        <Grid item xs={6}>
          <Box>
            <Typography variant="body2">
              โรงงานของ FISHYU
              <Typography variant="underline" color="red">
                *
              </Typography>
            </Typography>
            <Typography variant="caption" color="Text/Dark Grey">
              โรงงานของ FISHYU คือโรงงานที่ใช้ในการส่งปลาดอง
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box width={250}>
            <Field
              component={Select}
              options={props.factoryOptions}
              name="factoryId"
              blurOnSelect={false}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={6} mb={4}>
        <Grid item xs={6}>
          <Box>
            <Typography variant="body2">
              ปริมาณปลาสูงสุดต่อ 1 การจองคิว
              <Typography variant="underline" color="red">
                *
              </Typography>
            </Typography>
            <Typography variant="caption" color="Text/Dark Grey">
              ปริมาณที่เหมาะสมเพื่อให้เหลือที่ว่างในรถขนส่งน้อยที่สุด
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Field
            component={TextField}
            name="maxCapacityPerBooking"
            type="number"
            width={250}
            height={56}
            unit={`กก.${"/"}การจอง`}
            allowNegative={false}
          />
        </Grid>
      </Grid>

      <Grid container spacing={6} mb={4}>
        <Grid item xs={6}>
          <Box>
            <Typography variant="body2">
              ปริมาณขั้นต่ำที่ชาวประมงสามารถจองได้
              <Typography variant="underline" color="red">
                *
              </Typography>
            </Typography>
            <Typography variant="caption" color="Text/Dark Grey">
              ใช้ในการกำหนดปริมาณขั้นต่ำที่รับซื้อ
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Field
            component={TextField}
            name="minimumReservedRequired"
            type="number"
            width={250}
            height={56}
            unit={`กก.${"/"}การจอง`}
            allowNegative={false}
          />
        </Grid>
      </Grid>

      <Grid container spacing={6} mb={4}>
        <Grid item xs={6}>
          <Box>
            <Typography variant="body2">
              อัตราสิ้นเปลืองน้ำมันต่อ 1 ลิตร
              <Typography variant="underline" color="red">
                *
              </Typography>
            </Typography>
            <Typography variant="caption" color="Text/Dark Grey">
              ระยะทางที่เดินทางได้เฉลี่ยเมื่อใช้น้ำมัน 1 ลิตร
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Field
            component={TextField}
            name="fuelConsumptionPerLitre"
            type="number"
            width={250}
            height={56}
            unit={`กม.${"/"}ลิตร`}
            allowNegative={false}
          />
        </Grid>
      </Grid>

      <Grid container spacing={6} mb={4}>
        <Grid item xs={6}>
          <Box>
            <Typography variant="body2">
              ราคาน้ำมันต่อ 1 ลิตร
              <Typography variant="underline" color="red">
                *
              </Typography>
            </Typography>
            <Typography variant="caption" color="Text/Dark Grey">
              ใช้ในการคำนวณค่าน้ำมันรวม
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Field
            component={TextField}
            name="fuelPricePerLitre"
            type="number"
            width={250}
            height={56}
            unit={`บาท${"/"}ลิตร`}
            allowNegative={false}
          />
        </Grid>
      </Grid>

      <Grid container spacing={6} mb={4}>
        <Grid item xs={6}>
          <Box>
            <Typography variant="body2">
              อัตราการใช้น้ำแข็งเมื่อเทียบกับน้ำหนักปลา
              <Typography variant="underline" color="red">
                *
              </Typography>
            </Typography>
            <Typography variant="caption" color="Text/Dark Grey">
              เช่น อัตรา 20% หมายถึง ใช้น้ำแข็งในการดอง 20 กก. ต่อปลา 100 กก.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Field
            component={TextField}
            name="iceUsagePct"
            type="number"
            width={250}
            height={56}
            unit={`%`}
            allowNegative={false}
          />
        </Grid>
      </Grid>

      <Grid container spacing={6} mb={4}>
        <Grid item xs={6}>
          <Box>
            <Typography variant="body2">
              ราคาน้ำแข็งต่อ 1 กิโลกรัม
              <Typography variant="underline" color="red">
                *
              </Typography>
            </Typography>
            <Typography variant="caption" color="Text/Dark Grey">
              ใช้ในการคำนวณค่าน้ำแข็งรวม
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Field
            component={TextField}
            name="icePricePerKilo"
            type="number"
            width={250}
            height={56}
            unit={`บาท${"/"}กก.`}
            allowNegative={false}
          />
        </Grid>
      </Grid>

      <Grid container spacing={6} mb={4}>
        <Grid item xs={6}>
          <Box>
            <Typography variant="body2">
              น้ำหนักสินค้าที่พนักงานขนส่ง 1 คนสามารถรับผิดชอบได้
              <Typography variant="underline" color="red">
                *
              </Typography>
            </Typography>
            <Typography variant="caption" color="Text/Dark Grey">
              ใช้ในการแนะนำจำนวนพนักงานขนส่งที่เหมาะสม เช่น ทุกๆ 500
              กิโลกรัมต้องเพิ่มพนักงานขนส่ง 1 คน
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Field
            component={TextField}
            name="carryOnWeightPerEmployee"
            type="number"
            width={250}
            height={56}
            unit={`กก.${"/"}คน`}
            allowNegative={false}
          />
        </Grid>
      </Grid>

      <Grid container spacing={6} mb={8}>
        <Grid item xs={6}>
          <Box>
            <Typography variant="body2">
              ค่าแรงพนักงานขนส่งต่อคนต่อ 1 คำสั่งซื้อ
              <Typography variant="underline" color="red">
                *
              </Typography>
            </Typography>
            <Typography variant="caption" color="Text/Dark Grey">
              ใช้ในการคำนวณค่าแรงรวม
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Field
            component={TextField}
            name="shipperWagePerEmployeePerOrder"
            type="number"
            width={250}
            height={56}
            unit={`บาท${"/"}คน/คำสั่งซื้อ`}
            allowNegative={false}
          />
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Box fullWidth>
            <Typography variant="h4" mb={6}>
              ตำแหน่งที่ตั้งของ FISHYU Office (ใช้ในการคำนวณระยะทาง)
            </Typography>
          </Box>
          <Grid container spacing={6}>
            <Grid item xs={11}>
              <Field
                component={TextField}
                name="fishyuOfficeGoogleMapUrl"
                type="text"
                label="Google map link"
                fullWidth
              />
            </Grid>

            <Grid item xs={1}>
              <Button
                color="primary"
                variant="outlined"
                size="large"
                width={56}
                height={56}
                onClick={props.goToGoogleMap}
                disabled={!props.fishyuOfficeGoogleMapUrl}
              >
                <PlaceIcon />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>

    <Divider mt={10} />
    <Box display="flex" alignItems="center" fullWidth mb={1}>
      <Box display="flex" flex={1} flexDirection="row" fullWidth></Box>
      <Box width={24}></Box>
      <Button onClick={props.submit} color="primary" mt={6} width={160}>
        บันทึก
      </Button>
    </Box>
    <Prompt when={props.isDirty} message={props.promptMessage} />
  </Form>
);

const SettingsIndexPage = (props) => (
  <PageContent title={props.title} breadcrumbs={props.breadcrumbs}>
    <SettingsFrom {...props} />
  </PageContent>
);

export const API = {
  FETCH_SETTING: gql`
    query FETCH_SETTING {
      setting {
        id
        fuelConsumptionPerLitre
        fuelPricePerLitre
        iceUsagePct
        icePricePerKilo
        carryOnWeightPerEmployee
        shipperWagePerEmployeePerOrder
        fishyuOfficeGoogleMapUrl
        maxCapacityPerBooking
        minimumReservedRequired
        factoryId
      }
    }
  `,
  UPDATE_SETTING: gql`
    mutation UPDATE_SETTING(
      $id: ID
      $fuelConsumptionPerLitre: Float
      $fuelPricePerLitre: Float
      $iceUsagePct: Float
      $icePricePerKilo: Float
      $carryOnWeightPerEmployee: Float
      $shipperWagePerEmployeePerOrder: Float
      $fishyuOfficeGoogleMapUrl: String
      $maxCapacityPerBooking: Float
      $minimumReservedRequired: Float
      $factoryId: String
    ) {
      updateSetting(
        input: {
          id: $id
          fuelConsumptionPerLitre: $fuelConsumptionPerLitre
          fuelPricePerLitre: $fuelPricePerLitre
          iceUsagePct: $iceUsagePct
          icePricePerKilo: $icePricePerKilo
          carryOnWeightPerEmployee: $carryOnWeightPerEmployee
          shipperWagePerEmployeePerOrder: $shipperWagePerEmployeePerOrder
          fishyuOfficeGoogleMapUrl: $fishyuOfficeGoogleMapUrl
          maxCapacityPerBooking: $maxCapacityPerBooking
          minimumReservedRequired: $minimumReservedRequired
          factoryId: $factoryId
        }
      ) {
        setting {
          id
          fuelConsumptionPerLitre
          fuelPricePerLitre
          iceUsagePct
          icePricePerKilo
          carryOnWeightPerEmployee
          shipperWagePerEmployeePerOrder
          fishyuOfficeGoogleMapUrl
          maxCapacityPerBooking
          minimumReservedRequired
          factoryId
        }
      }
    }
  `,
  FETCH_FACTORIES: gql`
    query FETCH_FACTORIES {
      factories {
        id
        code
        name
      }
    }
  `,
};

const enhancer = compose(
  withFormik({
    validationSchema: Yup.object().shape({
      factoryId: Yup.string().nullable().required("ต้องไม่เว้นว่างเอาไว้"),
      fuelConsumptionPerLitre: Yup.string()
        .nullable()
        .required("ต้องไม่เว้นว่างเอาไว้"),
      fuelPricePerLitre: Yup.string()
        .nullable()
        .required("ต้องไม่เว้นว่างเอาไว้"),
      iceUsagePct: Yup.string().nullable().required("ต้องไม่เว้นว่างเอาไว้"),
      icePricePerKilo: Yup.string()
        .nullable()
        .required("ต้องไม่เว้นว่างเอาไว้"),
      carryOnWeightPerEmployee: Yup.string()
        .nullable()
        .required("ต้องไม่เว้นว่างเอาไว้"),
      shipperWagePerEmployeePerOrder: Yup.string()
        .nullable()
        .required("ต้องไม่เว้นว่างเอาไว้"),
      fishyuOfficeGoogleMapUrl: Yup.string()
        .nullable()
        .required("ต้องไม่เว้นว่างเอาไว้"),
      maxCapacityPerBooking: Yup.string()
        .nullable()
        .required("ต้องไม่เว้นว่างเอาไว้"),
      minimumReservedRequired: Yup.string()
        .nullable()
        .required("ต้องไม่เว้นว่างเอาไว้"),
    }),
  }),
  withHooks((props, hooks) => {
    const { useQuery, useMemo, useMutation, useEffect, useCallback } = hooks;
    const { setValues, values, errors } = props;
    const { fishyuOfficeGoogleMapUrl } = values || {};
    const { loading, data, error, refetch } = useQuery(API.FETCH_SETTING);
    const {
      loading: loadingFectchFactories,
      data: dataFectchFactories,
      error: errorFectchFactories,
      refetch: refetchFectchFactories,
    } = useQuery(API.FETCH_FACTORIES);
    const [updateSetting] = useMutation(API.UPDATE_SETTING);

    useEffect(() => {
      refetch();
      refetchFectchFactories();
    }, [refetch, refetchFectchFactories]);

    const factoryOptions = useMemo(() => {
      if (loadingFectchFactories || errorFectchFactories) {
        return [];
      }

      return dataFectchFactories.factories.map((factory) => ({
        label: factory.name,
        value: factory.id,
      }));
    }, [loadingFectchFactories, errorFectchFactories, dataFectchFactories]);

    let setting = useMemo(() => {
      if (loading || error) {
        return null;
      }
      if (error) {
        const message = getErrorMessage(error);
        notifyError(message);
        paths.bookingsNewPath().push();
        return null;
      }
      return cleanTypename(data.setting ?? {});
    }, [loading, data, error]);

    const title = "ตั้งค่าระบบ";
    const breadcrumbs = useMemo(() => {
      return [
        { path: paths.homePath(), label: "หน้าแรก" },
        { path: null, label: "การตั้งค่าระบบ" },
      ];
    }, []);

    useEffect(() => {
      if (setting) {
        setValues(setting);
      }
    }, [setValues, setting]);
    const lock = useRef(true);
    const isDirty = useMemo(() => !isEqual(values, setting) && lock.current, [
      values,
      setting,
    ]);

    const submit = useCallback(async () => {
      try {
        if (Object.keys(errors).length !== 0) {
          Modal.alert({
            title: "เกิดข้อผิดพลาด",
            children: (
              <Box>
                <Typography color="Text/Dark Grey" mb={2}>
                  กรุณากรอกข้อมูลต่อไปนี้ให้ครบ
                </Typography>
                <Typography color="Other/Danger">
                  {errors.factoryId ? " • โรงงานของ FISHYU" : ""}
                </Typography>
                <Typography color="Other/Danger">
                  {errors.maxCapacityPerBooking
                    ? " • ปริมาณปลาสูงสุดต่อ 1 การจองคิว"
                    : ""}
                </Typography>
                <Typography color="Other/Danger">
                  {errors.minimumReservedRequired
                    ? " • ปริมาณขั้นต่ำที่ชาวประมงสามารถจองได้"
                    : ""}
                </Typography>
                <Typography color="Other/Danger" mt={1}>
                  {errors.fuelConsumptionPerLitre
                    ? " • อัตราสิ้นเปลืองน้ำมันต่อ 1 ลิตร"
                    : ""}
                </Typography>
                <Typography color="Other/Danger">
                  {errors.fuelPricePerLitre ? " • ราคาน้ำมันต่อ 1 ลิตร" : ""}
                </Typography>
                <Typography color="Other/Danger">
                  {errors.iceUsagePct
                    ? " • อัตราการใช้น้ำแข็งเมื่อเทียบกับน้ำหนักปลา"
                    : ""}
                </Typography>
                <Typography color="Other/Danger">
                  {errors.icePricePerKilo ? " • ราคาน้ำแข็งต่อ 1 กิโลกรัม" : ""}
                </Typography>
                <Typography color="Other/Danger">
                  {errors.carryOnWeightPerEmployee
                    ? " • น้ำหนักสินค้าที่พนักงานขนส่ง 1 คนสามารถรับผิดชอบได้"
                    : ""}
                </Typography>
                <Typography color="Other/Danger">
                  {errors.shipperWagePerEmployeePerOrder
                    ? " • ค่าแรงพนักงานขนส่งต่อคนต่อ 1 คำสั่งซื้อ"
                    : ""}
                </Typography>
                <Typography color="Other/Danger">
                  {errors.fishyuOfficeGoogleMapUrl
                    ? " • ตำแหน่งที่ตั้งของ FISHYU Office (ใช้ในการคำนวณระยะทาง)"
                    : ""}
                </Typography>
              </Box>
            ),
            okButtonLabel: "ปิด",
          });
        } else {
          await updateSetting({ variables: values });
          notifySuccess("แก้ไขข้อมูลสำเร็จ");
        }
      } catch (e) {
        Modal.alert({
          title: "เกิดข้อผิดพลาด",
          children: `${getErrorMessage(e)}`,
          okButtonLabel: "ปิด",
          okButtonVariant: "button",
          onOk: ({ close }) => {
            close();
          },
        });
      }
    }, [values, errors, updateSetting]);

    const promptMessage = useCallback(
      (location, action) => {
        if (lock.current) {
          Modal.confirm({
            className: "ConfirmLeavePage",
            title: "ยกเลิกการแก้ไขข้อมูล",
            children: (
              <Typography variant="body1" color="Other/Danger">
                ข้อมูลจะไม่ถูกบันทึกและไม่สามารถกู้คืนได้
              </Typography>
            ),
            okButtonLabel: "ยกเลิกการแก้ไขข้อมูล",
            cancelButtonLabel: "ปิด",
            onOk: async ({ close }) => {
              await close();
              lock.current = false;
              props.history.push(location.pathname);
            },
          });
        }

        return !lock.current;
      },
      [props.history]
    );

    const goToGoogleMap = useCallback(
      () => window.open(props.values.fishyuOfficeGoogleMapUrl),
      [props.values.fishyuOfficeGoogleMapUrl]
    );

    return {
      title,
      breadcrumbs,
      fishyuOfficeGoogleMapUrl,
      submit,
      isDirty,
      promptMessage,
      goToGoogleMap,
      factoryOptions,
    };
  })
);

export default enhancer(SettingsIndexPage);

import { compose, withFormik, withHooks, defaultProps } from "enhancers";
import { PageContent } from "layouts";
import {
  Box,
  Grid,
  Typography,
  Form,
  Field,
  TextField,
  RadioGroup,
  Divider,
  Button,
  Modal,
  ImageUpload,
  FieldArray,
} from "components";
import { gql, getErrorMessage, notifySuccess, paths, Yup } from "utils/helper";

export const ShipperForm = (props) => (
  <Form>
    <Box display="flex" alignItems="center" fullWidth mb={1}>
      <Box display="flex" flex={1} flexDirection="row" fullWidth>
        <Typography variant="h4" mb={6}>
          ข้อมูลพนักงานขนส่ง
        </Typography>
      </Box>
      <Typography variant="body1" display="inline" mb={6}>
        สถานะ
      </Typography>
      <Field
        component={RadioGroup}
        name="shipperStatus"
        options={props.shipperAvailableStatus}
        row
        className="tax_payer_type_radio_group"
        mb={6}
        disabled={props.disabled}
      />
    </Box>
    <Grid container spacing={6} mb={2}>
      <Grid item xs={12}>
        <Field
          component={TextField}
          name="name"
          type="text"
          label="ชื่อ-นามสกุล"
          required
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextField.PhoneNumber}
          name="phoneNumber"
          label="เบอร์โทรศัพท์"
          required
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextField}
          name="licensePlateNumber"
          type="text"
          label="ทะเบียนรถ"
          fullWidth
        />
      </Grid>
    </Grid>

    <Grid container spacing={6} mt={1}>
      <Grid item xs={12}>
        <Field
          component={TextField}
          name="remark"
          type="text"
          label="หมายเหตุ"
          fullWidth
          placeholder="เช่น ไม่รับงานวันเสาร์อาทิตย์"
        />
      </Grid>
    </Grid>

    <Box display="flex" alignItems="center" fullWidth mb={1}>
      <Box display="flex" flex={1} flexDirection="row" fullWidth>
        <Typography variant="h4" mb={3} mt={10}>
          เอกสารใบอนุญาติขับรถ (ขนส่ง)
        </Typography>
      </Box>
    </Box>
    <FieldArray
      component={ImageUpload}
      name="images"
      addButtonLabel="เพิ่มรูป"
      max={1}
    />

    <Divider mt={10} />
    <Box display="flex" alignItems="center" fullWidth mb={6}>
      <Box display="flex" flex={1} flexDirection="row" fullWidth></Box>
      <Button color="" mt={6} onClick={props.useHandleCancel} width={160}>
        {props.cancelButtonLabel}
      </Button>
      <Box width={24}></Box>
      <Button onClick={props.submit} color="primary" mt={6} width={160}>
        {props.submitButtonLabel}
      </Button>
    </Box>
  </Form>
);

const ShipperNewPage = (props) => (
  <PageContent title={props.title} breadcrumbs={props.breadcrumbs}>
    <ShipperForm {...props} />
  </PageContent>
);

const API = {
  CREATE_SHIPPER: gql`
    mutation CREATE_SHIPPER(
      $name: String
      $phoneNumber: String
      $licensePlateNumber: String
      $remark: String
      $availableStatus: Boolean
      $images: [Upload!]
    ) {
      createShipper(
        input: {
          name: $name
          phoneNumber: $phoneNumber
          licensePlateNumber: $licensePlateNumber
          remark: $remark
          availableStatus: $availableStatus
          images: $images
        }
      ) {
        shipper {
          id
          name
        }
      }
    }
  `,
};

const enhancer = compose(
  defaultProps({
    shipperAvailableStatus: [
      { label: "ใช้งาน", value: "true" },
      { label: "ไม่ใช้งาน", value: "false" },
    ],
  }),
  withFormik({
    mapPropsToValues: () => ({
      shipperStatus: "true",
    }),
    validationSchema: Yup.object().shape({
      name: Yup.string().required("ต้องไม่เว้นว่างไว้"),
      phoneNumber: Yup.string().required("ต้องไม่เว้นว่างไว้"),
    }),
  }),
  withHooks((props, hooks) => {
    const { useMemo, useMutation, useCallback } = hooks;
    const { values, errors } = props;
    const [createShipper] = useMutation(API.CREATE_SHIPPER);

    const title = `เพิ่มพนักงานขนส่ง`;
    const breadcrumbs = useMemo(() => {
      return [
        { path: paths.homePath(), label: "หน้าแรก" },
        { path: paths.shippersPath(), label: "จัดการพนักงานขนส่ง" },
        { path: null, label: title },
      ];
    }, [title]);

    const isDirty = useCallback((values) => {
      if (values.shipperStatus === "false") return true;
      if (
        values.name ||
        values.phoneNumber ||
        values.licensePlateNumber ||
        values.remark ||
        values.images !== undefined
      )
        return true;
      return false;
    }, []);

    const useHandleCancel = useCallback(() => {
      if (isDirty(values)) {
        Modal.open({
          title: "ยกเลิกการเพิ่มข้อมูล",
          children: `ข้อมูลจะไม่ถูกบันทึกและไม่สามารถกู้คืนได้`,
          cancelButtonLabel: "ปิด",
          okButtonLabel: "ยกเลิกการเพิ่มข้อมูล",
          onOk: ({ close }) => {
            close();
            paths.shippersPath().push();
          },
        });
      } else {
        paths.shippersPath().push();
      }
    }, [values, isDirty]);

    const submit = useCallback(async () => {
      values.availableStatus = values.shipperStatus === "true";
      try {
        if (Object.keys(errors).length !== 0) {
          Modal.alert({
            title: "เกิดข้อผิดพลาด",
            children: (
              <Box>
                <Typography color="Text/Dark Grey" mb={2}>
                  กรุณากรอกข้อมูลต่อไปนี้ให้ครบ
                </Typography>
                <Typography color="Other/Danger">
                  {errors.name ? ` • ชื่อ-นามสกุล` : ""}
                </Typography>
                <Typography color="Other/Danger">
                  {errors.phoneNumber ? ` • เบอร์โทรศัพท์` : ""}
                </Typography>
              </Box>
            ),
            okButtonLabel: "ปิด",
          });
        } else {
          await createShipper({ variables: values });
          paths.shippersPath().push();
          notifySuccess("เพิ่มข้อมูลสำเร็จ");
        }
      } catch (e) {
        if (
          getErrorMessage(e) ===
          "Validation failed: Phone number has already been taken"
        ) {
          Modal.alert({
            title: "เกิดข้อผิดพลาด",
            children: (
              <Box>
                <Typography color="Text/Dark Grey" mb={2}>
                  {"ข้อมูลต่อไปนี้ต้องไม่ซ้ำกับที่มีอยู่ในระบบ"}
                </Typography>
                <Typography color="Other/Danger">
                  {" • เบอร์โทรศัพท์"}
                </Typography>
              </Box>
            ),
            okButtonLabel: "ปิด",
            okButtonVariant: "button",
            onOk: ({ close }) => {
              close();
              paths.shipperNewPath().push();
            },
          });
        } else {
          Modal.alert({
            title: "เกิดข้อผิดพลาด",
            children: `${getErrorMessage(e)}`,
            okButtonLabel: "ปิด",
            okButtonVariant: "button",
            onOk: ({ close }) => {
              close();
              paths.shipperNewPath().push();
            },
          });
        }
      }
    }, [values, errors, createShipper]);

    const shipperStatus = values.shipperStatus;

    return {
      title,
      breadcrumbs,
      cancelButtonLabel: "ยกเลิก",
      submitButtonLabel: "เพิ่มพนักงานขนส่ง",
      shipperStatus,
      submit,
      useHandleCancel,
      shipperAvailableStatus: [
        { label: "ใช้งาน", value: "true" },
        { label: "ไม่ใช้งาน", value: "false" },
      ],
    };
  })
);

export default enhancer(ShipperNewPage);

import React from "react";
import { compose, withHooks } from "enhancers";
import {
  Box,
  Typography,
  Field,
  Button,
  BrowseButton,
  FileUpload,
} from "components";
import { get, range } from "lodash";

const FileUploadList = (props) => (
  <Box {...props}>
    {props.simple ? (
      <>
        <BrowseButton startIcon="upload" onBrowse={props.addDocument} mt={6}>
          <Typography color="Text/Black">{props.addButtonLabel}</Typography>
        </BrowseButton>
        {range(props.amount).map((index) => {
          return (
            <Box key={index} display="flex" alignItems="center" mt={6}>
              <Field
                component={FileUpload}
                name={`${props.name}[${index}]`}
                width={512}
                pl={4}
                simple
              />
              <Button
                startIcon="bigTrash"
                onClick={() => {
                  props.removeDocument(index);
                }}
                ml={2}
              />
            </Box>
          );
        })}
      </>
    ) : (
      <>
        <Box display="flex" alignItems="center">
          <Box display="flex" flex={1} flexDirection="row" fullWidth>
            <Typography variant="h4" display="inline">
              {props.title}
            </Typography>
            <Typography variant="h4" color="#F34336" display="inline">
              {props.requiredSymbol}
            </Typography>
          </Box>
          <Typography variant="body1">ทั้งหมด {props.amount}</Typography>
        </Box>
        {range(props.amount).map((index) => {
          return (
            <Box key={index} display="flex" alignItems="center" mt={6}>
              <Field
                component={FileUpload}
                name={`${props.name}[${index}]`}
                width={512}
                pl={4}
              />
              <Button
                startIcon="bigTrash"
                onClick={() => {
                  props.removeDocument(index);
                }}
                ml={2}
              />
            </Box>
          );
        })}
        <BrowseButton startIcon="upload" onBrowse={props.addDocument} mt={6}>
          <Typography color="Text/Black">{props.addButtonLabel}</Typography>
        </BrowseButton>
      </>
    )}
  </Box>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const {
      form,
      name,
      insert,
      remove,
      title,
      addButtonLabel,
      mt,
      notDisplayHeader,
      simple,
    } = props;
    const { useCallback, useMemo } = hooks;
    const amount = get(form.values, name)?.length ?? 0;

    const addDocument = useCallback(
      (file) => {
        insert(amount, file);
      },
      [insert, amount]
    );

    const removeDocument = useCallback(
      (index) => {
        remove(index);
      },
      [remove]
    );

    const requiredSymbol = useMemo(() => {
      const symbol = title?.charAt(title.length - 1);
      return symbol === "*" ? symbol : null;
    }, [title]);

    const customTitle = useMemo(() => {
      return requiredSymbol ? title?.slice(0, -1) : title;
    }, [requiredSymbol, title]);

    return {
      name,
      amount,
      addDocument,
      removeDocument,
      requiredSymbol,
      title: customTitle,
      addButtonLabel,
      mt,
      notDisplayHeader,
      simple,
    };
  })
);

export default enhancer(FileUploadList);

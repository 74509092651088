import { useMemo } from "react";
import { Switch } from "react-router-dom";
import { compose, withHooks, withStores } from "enhancers";
import { safe } from "common/helper";

const enhancer = compose(
  withStores((stores) => ({
    currentRole: stores.appStore.currentRole,
  })),
  withHooks((props) => {
    const children = useMemo(() => {
      if (props.children) {
        return props.children.filter((child, index) => {
          const permittedRoles = safe(() => child.props.permittedRoles, null);

          if (permittedRoles) {
            const authorized = permittedRoles.includes(props.currentRole);
            return authorized;
          }

          return true;
        });
      }
    }, [props.children, props.currentRole]);

    return {
      ...props,
      children,
    };
  })
);

export default enhancer(Switch);

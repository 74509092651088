import { compose, defaultProps, withFormik, withHooks } from "enhancers";
import { PageContent } from "layouts";
import {
  Box,
  Grid,
  Typography,
  Form,
  Field,
  TextField,
  RadioGroup,
  Divider,
  Button,
  Address,
  Modal,
  FishDepreciation,
  FieldArray,
  ImageUpload,
} from "components";

import { getErrorMessage, gql, notifySuccess, paths, Yup } from "utils/helper";
import { ReactComponent as PlaceIcon } from "assets/icon/place.svg";
import { uniqBy } from "lodash";

export const FactoryForm = (props) => (
  <Form>
    <Box display="flex" alignItems="center" fullWidth mb={6}>
      <Box display="flex" flex={1} flexDirection="row" fullWidth>
        <Typography variant="h4" mb={6}>
          ข้อมูลโรงงาน
        </Typography>
      </Box>
      <Field
        component={RadioGroup}
        name="factoryStatus"
        options={props.factoryAvailableStatus}
        row
        className="tax_payer_type_radio_group"
      />
    </Box>
    <Grid container spacing={6}>
      <Grid item xs={6}>
        <Field
          component={TextField}
          name="name"
          type="text"
          label="ชื่อ"
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextField.PhoneNumber}
          name="phoneNumber"
          label="เบอร์โทรศัพท์"
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={12}>
        <Field component={Address} name="address" title="ข้อมูลชาวประมง" />
      </Grid>
    </Grid>
    <Grid container spacing={6} mb={6}>
      <Grid item xs={12}>
        <Field
          component={TextField}
          name="remark"
          type="text"
          label="หมายเหตุ"
          fullWidth
          placeholder="เช่น รับซื้อปลา 2,000 กิโลกรัมขึ้นไปเท่านั้น"
        />
      </Grid>
      <Grid item xs={11}>
        <Field
          component={TextField}
          name="googleMapLink"
          type="text"
          label="Google map link"
          fullWidth
          required
        />
      </Grid>

      <Grid item xs={1}>
        <Button
          color="primary"
          variant="outlined"
          size="large"
          width={56}
          height={56}
          onClick={() => window.open(props.googleMapLink)}
          disabled={!props.googleMapLink}
        >
          <PlaceIcon />
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" flex={1} flexDirection="row" fullWidth>
          <Typography variant="h4" mb={6}>
            เกณฑ์การรับซื้อของโรงงาน
          </Typography>
        </Box>
        <Grid container spacing={6} mb={6}>
          <Grid
            item
            xs={12}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <FieldArray
              component={ImageUpload}
              name="images"
              addButtonLabel="เพิ่มรูป"
              max={1}
            />
          </Grid>
        </Grid>
        <FishDepreciation fishDepreciation={props.fishDepreciation} />
      </Grid>
    </Grid>

    <Divider mt={10} />
    <Box display="flex" alignItems="center" fullWidth mb={6}>
      <Box display="flex" flex={1} flexDirection="row" fullWidth></Box>
      <Button
        color=""
        mt={6}
        mr={4}
        width={150}
        onClick={() => props.cancelFactory()}
      >
        {props.cancelButtonLabel}
      </Button>
      <Button onClick={props.submit} color="primary" width={150} mt={6}>
        {props.submitButtonLabel}
      </Button>
    </Box>
  </Form>
);

const FactoryNewPage = (props) => (
  <PageContent title={props.title} breadcrumbs={props.breadcrumbs}>
    <FactoryForm {...props} />
  </PageContent>
);

const API = {
  CREATE_FACTORY: gql`
    mutation CREATE_FACTORY(
      $availableStatus: Boolean
      $googleMapLink: String
      $name: String
      $phoneNumber: String
      $remark: String
      $address: AddressArguments
      $images: [Upload!]
      $fishDepreciation: [FishDepreciationArguments!]
    ) {
      createFactory(
        input: {
          availableStatus: $availableStatus
          googleMapLink: $googleMapLink
          name: $name
          phoneNumber: $phoneNumber
          remark: $remark
          address: $address
          images: $images
          fishDepreciation: $fishDepreciation
        }
      ) {
        factory {
          id
          name
        }
      }
    }
  `,
};

const enhancer = compose(
  defaultProps({
    factoryAvailableStatus: [
      { label: "ใช้งาน", value: "true" },
      { label: "ไม่ใช้งาน", value: "false" },
    ],
  }),
  withFormik({
    mapPropsToValues: () => ({
      googleMapLink: null,
      factoryStatus: "true",
      fishDepreciation: [],
    }),
    validationSchema: Yup.object().shape({
      name: Yup.string().nullable().required("ต้องไม่เว้นว่างไว้"),
      phoneNumber: Yup.string().nullable().required("ต้องไม่เว้นว่างไว้"),
      googleMapLink: Yup.string().nullable().required("ต้องไม่เว้นว่างไว้"),
      address: Yup.object().shape({
        address: Yup.string().nullable().required("ต้องไม่เว้นว่างไว้"),
        district: Yup.string().nullable().required("ต้องไม่เว้นว่างไว้"),
        subDistrict: Yup.string().nullable().required("ต้องไม่เว้นว่างไว้"),
        province: Yup.string().nullable().required("ต้องไม่เว้นว่างไว้"),
        zipCode: Yup.string().nullable().required("ต้องไม่เว้นว่างไว้"),
      }),
    }),
  }),
  withHooks((props, hooks) => {
    const { useMemo, useMutation, useCallback } = hooks;
    const { values, errors } = props;
    const { googleMapLink, fishDepreciation } = values || {};
    const [createFactory] = useMutation(API.CREATE_FACTORY);

    const title = `เพิ่มโรงงาน`;
    const breadcrumbs = useMemo(() => {
      return [
        { path: paths.homePath(), label: "หน้าแรก" },
        { path: paths.factoriesPath(), label: "จัดการโรงงาน" },
        { path: null, label: title },
      ];
    }, [title]);

    const validFishDepreciation = useMemo(() => {
      let res = false;
      if (values.fishDepreciation && values.fishDepreciation.length > 0) {
        values.fishDepreciation.forEach((dep) => {
          if (
            dep.fishId === undefined ||
            dep.purchasePrice === undefined ||
            dep.gradeADepreciationPct === undefined ||
            dep.gradeBDepreciationPct === undefined ||
            dep.gradeCDepreciationPct === undefined ||
            dep.gradeDDepreciationPct === undefined
          ) {
            res = true;
          }
        });
      }
      return res;
    }, [values.fishDepreciation]);

    const submit = useCallback(async () => {
      values.availableStatus = values.factoryStatus === "true";
      try {
        if (Object.keys(errors).length > 0 || validFishDepreciation === true) {
          Modal.alert({
            title: "เกิดข้อผิดพลาด",
            children: (
              <Box>
                <Typography color="Text/Dark Grey" mb={2}>
                  กรุณากรอกข้อมูลต่อไปนี้ให้ครบ
                </Typography>
                <Typography color="Other/Danger" mt={1}>
                  {errors.name ? " • ชื่อ" : ""}
                </Typography>
                <Typography color="Other/Danger">
                  {errors.phoneNumber ? "• เบอร์โทรศัพท์" : ""}
                </Typography>
                <Typography color="Other/Danger">
                  {errors.address && errors.address.address ? " • ที่อยู่" : ""}
                </Typography>
                <Typography color="Other/Danger">
                  {errors.address && errors.address.subDistrict
                    ? " • แขวง/ตำบล"
                    : ""}
                </Typography>
                <Typography color="Other/Danger">
                  {errors.address && errors.address.district
                    ? " • เขต/อำเภอ"
                    : ""}
                </Typography>
                <Typography color="Other/Danger">
                  {errors.address && errors.address.province
                    ? " • จังหวัด"
                    : ""}
                </Typography>
                <Typography color="Other/Danger">
                  {errors.address && errors.address.zipCode
                    ? " • รหัสไปรษณีย์"
                    : ""}
                </Typography>
                <Typography color="Other/Danger">
                  {errors.googleMapLink ? " • Google map link" : ""}
                </Typography>
                <Typography color="Other/Danger">
                  {validFishDepreciation === true
                    ? " • ข้อมูลราคารับซื้อและค่าเสื่อมปลา"
                    : ""}
                </Typography>
              </Box>
            ),
            okButtonLabel: "ปิด",
          });
        } else if (
          values.fishDepreciation.length >
          uniqBy(values.fishDepreciation, "fishId").length
        ) {
          Modal.alert({
            title: "เกิดข้อผิดพลาด",
            children: `ข้อมูลปลา ในข้อมูลราคารับซื้อและค่าเสื่อมปลาห้ามซ้ำกัน`,
            okButtonLabel: "ปิด",
            okButtonVariant: "button",
            onOk: ({ close }) => {
              close();
              paths.factoryNewPath().push();
            },
          });
        } else {
          await createFactory({ variables: values });
          paths.factoriesPath().push();
          notifySuccess("เพิ่มข้อมูลสำเร็จ");
        }
      } catch (e) {
        Modal.alert({
          title: "เกิดข้อผิดพลาด",
          children: `${getErrorMessage(e)}`,
          okButtonLabel: "ปิด",
          okButtonVariant: "button",
          onOk: ({ close }) => {
            close();
            paths.factoryNewPath().push();
          },
        });
      }
    }, [values, errors, createFactory, validFishDepreciation]);

    const isDirty = useCallback((values) => {
      if (values.factoryStatus === "false") {
        return true;
      } else if (
        values.name ||
        values.phoneNumber ||
        values.remark ||
        values.googleMapLink ||
        values.address.address ||
        values.address.district ||
        values.address.subDistrict ||
        values.address.province ||
        values.address.zipCode ||
        values.images !== undefined
      ) {
        return true;
      } else if (values.fishDepreciation.length > 0) {
        return true;
      }
      return false;
    }, []);

    const cancelFactory = useCallback(() => {
      if (isDirty(values)) {
        Modal.open({
          className: "ConfirmLeavePage",
          title: "ยกเลิกการแก้ไขข้อมูล",
          children: `ข้อมูลจะไม่ถูกบันทึกและไม่สามารถกู้คืนได้`,
          cancelButtonLabel: "ปิด",
          okButtonLabel: "ยกเลิกการแก้ไขข้อมูล",
          onOk: async ({ close }) => {
            try {
              close();
              await paths.factoriesPath().push();
            } catch (e) {}
          },
        });
      } else {
        paths.factoriesPath().push();
      }
    }, [values, isDirty]);

    const factoryStatus = values.factoryStatus;
    const factoryAvailableStatus = useMemo(() => {
      return [
        { label: "ใช้งาน", value: "true" },
        { label: "ไม่ใช้งาน", value: "false" },
      ];
    }, []);

    return {
      title,
      breadcrumbs,
      googleMapLink,
      factoryStatus,
      cancelButtonLabel: "ยกเลิก",
      submitButtonLabel: "เพิ่มโรงงาน",
      factoryAvailableStatus,
      cancelFactory,
      fishDepreciation,
      submit,
    };
  })
);

export default enhancer(FactoryNewPage);

import { compose, withHooks } from "enhancers";
import { PageContent } from "layouts";
import { Box, Table, Typography } from "components";
import { gql, paths } from "utils/helper";
import { ReactComponent as EditIcon } from "assets/icon/edit.svg";
import { ReactComponent as AddIcon } from "assets/icon/add-icon.svg";

const ShipperIndexPage = (props) => (
  <PageContent
    title="จัดการพนักงานส่ง"
    breadcrumbs={props.breadcrumbs}
    pageActions={props.pageActions}
  >
    <Box width="100%" mb={-6}>
      <Typography variant="h4" mb={4}>
        รายการพนักงานขนส่ง
      </Typography>
      <Table
        columns={[
          { width: 170, field: "code", headerName: "รหัสพนักงาน" },
          { width: 330, field: "name", headerName: "ชื่อ-นามสกุล" },
          {
            width: 160,
            field: "phoneNumber",
            headerName: "เบอร์โทรศัพท์",
            type: "phoneNumber",
          },
          { width: 220, field: "licensePlateNumber", headerName: "ทะเบียนรถ" },
          {
            width: 115,
            field: "availableStatus",
            headerName: "สถานะ",
            type: "tag",
          },
          {
            width: 40,
            field: "actions",
            headerName: " ",
            filterable: false,
            sortable: false,
            type: "actions",
          },
        ]}
        rows={props.tableData}
        loading={props.loading}
        density="compact"
        autoHeight
        disableSelectionOnClick
        autoDetectInitialFilterOnUrlQueryParams
      />
    </Box>
  </PageContent>
);

export const API = {
  FETCH_SHIPPERS: gql`
    query FETCH_SHIPPERS {
      shippers {
        id
        code
        name
        phoneNumber
        licensePlateNumber
        remark
        availableStatus
      }
    }
  `,
};

const enhancer = compose(
  withHooks((props, hooks) => {
    const { useMemo, useCallback, useQuery, useEffect } = hooks;
    const { loading, data, error, refetch } = useQuery(API.FETCH_SHIPPERS, {
      variables: {
        sorts: [{ field: "code", sort: "asc" }],
      },
    });

    const breadcrumbs = useMemo(() => {
      return [
        { path: paths.homePath(), label: "หน้าแรก" },
        { path: null, label: "จัดการพนักงานส่ง" },
      ];
    }, []);

    const pageActions = useMemo(() => {
      return [
        {
          children: "เพิ่มพนักงานขนส่ง",
          startIcon: <AddIcon />,
          onClick: () => paths.shipperNewPath().push(),
          color: "primary",
        },
      ];
    }, []);
    useEffect(() => {
      refetch();
    }, [refetch]);

    const handleEditShipper = useCallback(async (params) => {
      const { id } = params;
      paths.shipperEditPath(id).push();
    }, []);

    const tableData = useMemo(() => {
      if (loading || error) {
        return [];
      }
      return data.shippers.map((shipper) => {
        const {
          id,
          code,
          name,
          phoneNumber,
          licensePlateNumber,
          remark,
          availableStatus,
        } = shipper;

        return {
          id,
          code,
          name,
          phoneNumber,
          licensePlateNumber,
          remark,
          availableStatus: availableStatus ? "ใช้งาน" : "ไม่ใช้งาน",
          actions: [{ Icon: EditIcon, onClick: handleEditShipper }],
        };
      });
    }, [loading, data, error, handleEditShipper]);

    return {
      tableData,
      loading,
      rowCount: data?.shippers?.rowCount ?? 0,
      refetch,
      pageActions,
      breadcrumbs,
    };
  })
);

export default enhancer(ShipperIndexPage);

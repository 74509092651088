import { compose, withHooks } from "enhancers";
import {
  Box,
  Grid,
  Typography,
  Form,
  Field,
  TextField,
  Select,
  Divider,
  RadioGroup,
  DatePicker,
} from "components";

const DeliveryFish = (props) => (
  <Form {...props}>
    <Box display="flex" flex={1} flexDirection="row" fullWidth>
      <Grid item xs={12}>
        <Typography variant="h4" mb={6}>
          ข้อมูลวันส่งปลา
        </Typography>

        <Grid container spacing={6} mb={6}>
          <Grid item flex={1}>
            <Typography variant="body1">ประเภทความต้องการซื้อ</Typography>
          </Grid>
          <Grid item flex={1}>
            <Field
              component={RadioGroup}
              name="scheduleType"
              options={props.scheduleTypeOptions.oneDemand}
              width={160}
              ml={4}
              mr={0}
            />
          </Grid>
          <Grid item flex={1}>
            <Field
              component={RadioGroup}
              name="scheduleType"
              options={props.scheduleTypeOptions.daily}
              width={160}
              ml={4}
              mr={0}
            />
          </Grid>
          <Grid item flex={1}>
            <Field
              component={RadioGroup}
              name="scheduleType"
              options={props.scheduleTypeOptions.weekly}
              width={160}
              ml={4}
              mr={0}
            />
          </Grid>
          <Grid item flex={1}>
            <Field
              component={RadioGroup}
              name="scheduleType"
              options={props.scheduleTypeOptions.monthly}
              width={160}
              ml={4}
              mr={0}
            />
          </Grid>
        </Grid>
        <Divider mb={6} />

        {props.scheduleType === "one-demand" && (
          <Grid container spacing={6} mb={6}>
            <Grid item xs={6}>
              <Field
                component={DatePicker}
                name="deliverDate"
                label="วันที่ส่งปลา"
                placeholder=""
                fullWidth
                required
              />
            </Grid>
          </Grid>
        )}

        {props.scheduleType === "daily" && (
          <Grid container spacing={6} mb={6}>
            <Grid item xs={6}>
              <Field
                component={DatePicker}
                name="startDate"
                label="วันที่ส่งปลา"
                placeholder=""
                fullWidth
                required
                minDate={props.minDateOfStartDate}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={DatePicker}
                name="endDate"
                label="วันที่สิ้นสุดการส่งปลา"
                placeholder=""
                fullWidth
                required
                minDate={props.minDateOfEndDate}
                fast={false}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={TextField}
                fullWidth
                name="dailyRepeat"
                label="ส่งซ้ำทุกๆ"
                unit="วัน"
                required
              />
            </Grid>
          </Grid>
        )}
        {props.scheduleType === "weekly" && (
          <Grid container spacing={6} mb={6}>
            <Grid item xs={6}>
              <Field
                component={DatePicker}
                name="startDate"
                label="วันที่ส่งปลา"
                placeholder=""
                fullWidth
                required
                minDate={props.minDateOfStartDate}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={DatePicker}
                name="endDate"
                label="วันที่สิ้นสุดการส่งปลา"
                placeholder=""
                fullWidth
                required
                minDate={props.minDateOfEndDate}
                fast={false}
              />
            </Grid>

            <Grid item xs={6}>
              <Field
                component={Select}
                fullWidth
                name="weeklyDeliveryDates"
                label="วันที่ต้องส่งในแต่ละสัปดาห์ (อาทิตย์ - เสาร์)"
                options={props.weeklyOptions}
                required
                multiple
                blurOnSelect={false}
              />
            </Grid>
          </Grid>
        )}
        {props.scheduleType === "monthly" && (
          <Grid container spacing={6} mb={6}>
            <Grid item xs={6}>
              <Field
                component={DatePicker}
                name="startDate"
                label="วันที่ส่งปลา"
                placeholder=""
                fullWidth
                required
                minDate={props.minDateOfStartDate}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={DatePicker}
                name="endDate"
                label="วันที่สิ้นสุดการส่งปลา"
                placeholder=""
                fullWidth
                required
                minDate={props.minDateOfEndDate}
                fast={false}
              />
            </Grid>

            <Grid item xs={6}>
              <Field
                component={Select}
                fullWidth
                name="monthlyDeliveryDates"
                label="วันที่ต้องส่งในแต่ละเดือน (1-28)"
                options={props.dailyOptions}
                required
                multiple
                blurOnSelect={false}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  </Form>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const { scheduleType, startDate } = props;
    const { useMemo } = hooks;

    const dailyOptions = useMemo(() => {
      let result = Array.from({ length: 28 }, (_, i) => i + 1).map((i) => ({
        label: i.toString(),
        value: i.toString(),
      }));

      return result;
    }, []);

    const weeklyOptions = useMemo(
      () => [
        { label: "วันอาทิตย์", value: "Sunday" },
        { label: "วันจันทร์", value: "Monday" },
        { label: "วันอังคาร", value: "Tuesday" },
        { label: "วันพุธ", value: "Wednesday" },
        { label: "วันพฤหัสบดี", value: "Thursday" },
        { label: "วันศุกร์", value: "Friday" },
        { label: "วันเสาร์", value: "Saturday" },
      ],
      []
    );

    const scheduleTypeOptions = useMemo(() => {
      const oneDemand = [
        {
          label: "รายครั้ง",
          subLabel: "ครั้งเดียวไม่ทำซ้ำ",
          value: "one-demand",
        },
      ];

      const daily = [
        {
          label: "รายวัน",
          subLabel: "ส่งซ้ำทุก x วัน",
          value: "daily",
        },
      ];

      const weekly = [
        {
          label: "รายสัปดาห์",
          subLabel: "ส่งซ้ำทุกสัปดาห์",
          value: "weekly",
        },
      ];

      const monthly = [
        {
          label: "รายเดือน",
          subLabel: "ส่งซ้ำทุกวันที่ x ของเดือน",
          value: "monthly",
        },
      ];

      return { oneDemand, daily, weekly, monthly };
    }, []);

    const minDateOfStartDate = useMemo(() => new Date(), []);
    const minDateOfEndDate = useMemo(() => startDate || new Date(), [
      startDate,
    ]);
    return {
      scheduleType,
      dailyOptions,
      weeklyOptions,
      scheduleTypeOptions,
      minDateOfStartDate,
      minDateOfEndDate,
    };
  })
);

export default enhancer(DeliveryFish);

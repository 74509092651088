import {
  Box,
  Grid,
  Typography,
  Hidden,
  Divider,
  Chip,
  IconButton,
  Modal,
  Button,
  Link,
} from "components";
import { compose, withHooks } from "enhancers";
import { ReactComponent as ArrowUpDisabledIcon } from "assets/icon/arrow_up_disabled.svg";
import { ReactComponent as ArrowDownDisabledIcon } from "assets/icon/arrow_down_disabled.svg";
import { ReactComponent as WarningAmberIcon } from "assets/icon/warning_amber.svg";
import DeliverFishByFishermanModal from "./DeliverFishByFishermanModal";
import {
  PlaceOutlined as PlaceIcon,
  LocalPhoneOutlined as TelephoneIcon,
  DeleteOutlineOutlined as DeleteIcon,
  EditOutlined as EditIcon,
} from "@material-ui/icons";
import { ReactComponent as ArrowUpIcon } from "assets/icon/arrow_up.svg";
import { ReactComponent as ArrowDownIcon } from "assets/icon/arrow_down.svg";
import { notifySuccess, toCurrency, formatPhoneNumber } from "utils/helper";
import AddFishSuppliesModal from "./AddFishSuppliesModal";
import { every, isNil } from "lodash";

const placeIconStyle = {
  color: "#A1A1A1",
  width: 16,
  height: 16,
};

const styleOverflow = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const linkIconStyle = {
  textDecoration: "underline",
  color: "#376FD0",
  ...styleOverflow,
};

const chipStyle = {
  fontSize: 12,
  lineHeight: 14,
  color: "white",
};

const boxStyle = { background: "#F6F9FC", borderRadius: 8 };

const FishSupplies = (props) => (
  <div>
    <Box display="flex" alignItems="center" fullWidth mt={10}>
      <Box display="flex" flex={1} flexDirection="row" fullWidth>
        <Typography variant="h4">
          ลำดับการรับปลาจากชาวประมง (
          {props.fishSuppliesData ? props.fishSuppliesData.length : 0})
        </Typography>
      </Box>

      <Typography
        variant="body1"
        color={
          props.isFinishReceivedFishSupply ? "Other/Success" : "Other/Info"
        }
      >
        {props.orderStatus?.title === "กำลังดำเนินการ"
          ? `รับปลาแล้ว `
          : `จับคู่แล้ว `}
        {toCurrency(
          props.orderStatus?.title === "กำลังดำเนินการ"
            ? props.receiveFishSuppliesWeight ?? 0
            : props.sumReceivedSequence ?? 0,
          {
            minimumFractionDigits: 0,
          }
        )}
        {"/" +
          toCurrency(
            props.orderStatus?.title === "กำลังดำเนินการ"
              ? props.totalFishDemandAmount ?? 0
              : props.factoryDemandAmount ?? 0,
            {
              minimumFractionDigits: 0,
            }
          ) +
          " "}
        กก.
      </Typography>
    </Box>
    {props.fishSuppliesData?.map((object, index) => {
      const {
        status: supplyStatus,
        id,
        bookingId,
        fishReceivedWeight,
        fishReceivedGrade,
        netProfit = 0,
        fisherman,
        amount,
      } = object || {};
      const { name: fishermanName, address, phoneNumber, googleMapLink } =
        fisherman || {};
      const fishermanAddress = `${address?.address ?? ""} ${
        address?.district ?? ""
      } ${address?.subDistrict ?? ""} ${address?.province ?? ""} ${
        address?.zipCode ?? ""
      }`;

      let status = "";

      switch (supplyStatus) {
        case "in_progress":
          status = "กำลังดำเนินการ";
          break;
        case "waiting_for_payment":
          status = "รอโอนเงิน";
          break;
        case "success":
          status = "สำเร็จ";
          break;
        default:
          status = "รอโอนเงิน";
          break;
      }

      const shouldShowReceivedFishAmount =
        supplyStatus === "waiting_for_payment" || supplyStatus === "success";
      const isFirstIndex = index === 0;
      const isLastIndex = index === props.fishSuppliesData.length - 1;
      const isOrderCanceled = props?.orderStatus?.title === "ยกเลิก";
      const isOrderInProgress = props?.orderStatus?.title === "กำลังดำเนินการ";
      const isWaitingForPayment = props?.orderStatus?.title === "รอโอนเงิน";
      const isSuccess = props?.orderStatus?.title === "สำเร็จ";
      const isFishReceived = false;

      return (
        <Box mb={2}>
          <Box display="flex">
            <Grid item xs={8}>
              <Box p={4} mt={2}>
                <Box display="flex" mb={3}>
                  <Typography variant="h6" color="Text/Black">
                    {fishermanName}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="row" mb={1}>
                  <Box display="flex">
                    <Typography variant="body1" color="Text/Dark Grey" mr={2}>
                      <PlaceIcon style={placeIconStyle} />
                    </Typography>
                  </Box>
                  <Link
                    component="button"
                    variant="body1"
                    color="Other/Info"
                    mr={2}
                    style={linkIconStyle}
                    onClick={() => window.open(googleMapLink)}
                  >
                    {fishermanAddress}
                  </Link>
                </Box>
                <Box display="flex" mb={2}>
                  <Typography variant="body1" color="Text/Dark Grey" mr={2}>
                    <TelephoneIcon style={placeIconStyle} />
                  </Typography>
                  <Typography variant="body1" color="Text/Black">
                    {formatPhoneNumber(phoneNumber)}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={1}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Hidden when={props.hideWhenUnConfirmOrder}>
                <Box display="flex">
                  <Chip
                    size="small"
                    label={status}
                    style={{
                      ...chipStyle,
                      backgroundColor: `${props.handleColorStatus(status)}`,
                    }}
                  />
                </Box>
              </Hidden>
            </Grid>

            <Grid item xs={3}>
              <Box mb={6}></Box>

              <Box
                p={4}
                display="flex"
                alignItems="center"
                justifyContent="end"
              >
                <Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Hidden
                      when={
                        shouldShowReceivedFishAmount
                        // fishReceivedWeight !== null &&
                        // fishReceivedWeight !== undefined
                      }
                    >
                      <Typography variant="h1" color="Other/Info">
                        {toCurrency(amount, {
                          minimumFractionDigits: 0,
                        })}
                      </Typography>
                    </Hidden>

                    <Hidden
                      when={
                        !shouldShowReceivedFishAmount
                        // fishReceivedWeight === null ||
                        // fishReceivedWeight === undefined
                      }
                    >
                      <Typography variant="h1" color="Other/Success">
                        {toCurrency(fishReceivedWeight, {
                          minimumFractionDigits: 0,
                        })}
                      </Typography>
                    </Hidden>
                    <Typography
                      variant="caption"
                      color="Text/Dark Grey"
                      ml={1}
                      mt={3}
                    >
                      {"กก."}
                    </Typography>
                  </Box>
                  <Hidden
                    when={
                      !shouldShowReceivedFishAmount
                      // status === "กำลังดำเนินการ" ||
                      // status === "รอยืนยัน" ||
                      // status === "ยกเลิก" ||
                      // props.orderStatus?.title === "รอยืนยัน"
                    }
                  >
                    <Typography variant="caption" color="Text/Black">
                      {props.type === "Shipper"
                        ? "เกรด " + fishReceivedGrade
                        : "เกรด " +
                          fishReceivedGrade +
                          " • " +
                          toCurrency(netProfit, {
                            minimumFractionDigits: 0,
                          }) +
                          " บาท"}
                    </Typography>
                  </Hidden>
                </Box>
                <Hidden
                // when={
                //   props.orderStatus?.title !== "รอยืนยัน" &&
                //   props.orderStatus?.title !== "ยกเลิก"
                // }
                >
                  <Hidden
                    when={
                      isLastIndex ||
                      isOrderCanceled ||
                      isOrderInProgress ||
                      isWaitingForPayment ||
                      isSuccess
                      // index === props.fishSuppliesData?.length - 1 ||
                      // props.orderStatus?.title === "ยกเลิก"
                    }
                  >
                    <IconButton
                      key={index}
                      onClick={() => props.swapDownFishSupply(index)}
                      mr={2}
                    >
                      <ArrowDownIcon />
                    </IconButton>
                  </Hidden>
                  <Hidden
                    when={
                      (!isLastIndex && !isOrderCanceled) ||
                      isOrderInProgress ||
                      isWaitingForPayment ||
                      isSuccess
                      // index !== props.fishSuppliesData?.length - 1 &&
                      // props.orderStatus?.title !== "ยกเลิก"
                    }
                  >
                    <IconButton key={index} disabled mr={2}>
                      <ArrowDownDisabledIcon />
                    </IconButton>
                  </Hidden>
                  <Hidden
                    when={
                      isFirstIndex ||
                      isOrderCanceled ||
                      isOrderInProgress ||
                      isWaitingForPayment ||
                      isSuccess
                      // index === 0 ||
                      // props.orderStatus?.title === "ยกเลิก"
                    }
                  >
                    <IconButton
                      key={index}
                      onClick={() => props.swapUpFishSupply(index)}
                      mr={2}
                    >
                      <ArrowUpIcon />
                    </IconButton>
                  </Hidden>
                  <Hidden
                    when={
                      (!isFirstIndex && !isOrderCanceled) ||
                      isOrderInProgress ||
                      isWaitingForPayment ||
                      isSuccess
                      // index !== 0 &&
                      // props.orderStatus?.title !== "ยกเลิก"
                    }
                  >
                    <IconButton key={index} disabled mr={2}>
                      <ArrowUpDisabledIcon />
                    </IconButton>
                  </Hidden>

                  <Hidden
                    when={
                      isOrderInProgress ||
                      isFishReceived ||
                      isWaitingForPayment ||
                      isSuccess
                      // index === 0 || props.orderStatus?.title === "ยกเลิก"
                    }
                  >
                    <IconButton
                      disabled={props.orderStatus?.title === "ยกเลิก"}
                      key=""
                      onClick={() =>
                        props.deleteFishSupply(
                          index,
                          props.isCreate ? id : bookingId
                        )
                      }
                    >
                      <DeleteIcon
                        style={{
                          color:
                            props.orderStatus?.title === "ยกเลิก"
                              ? "#A1A1A1"
                              : "#000000",
                          width: 20,
                        }}
                      />
                    </IconButton>
                  </Hidden>
                </Hidden>

                <Hidden
                  when={
                    !isOrderInProgress &&
                    !isFishReceived &&
                    !isWaitingForPayment &&
                    !isSuccess
                    // props.orderStatus?.title === "รอยืนยัน" ||
                    // props.orderStatus?.title === "ยกเลิก"
                  }
                >
                  <IconButton
                    key=""
                    onClick={() => props.openDeliverFishByFishermanModal(index)}
                  >
                    <EditIcon style={{ color: "#000000", width: 20 }} />
                  </IconButton>
                </Hidden>
              </Box>
            </Grid>
          </Box>
          <Divider mt={0} mb={1} />
        </Box>
      );
    })}
    <Hidden when={props.fishSuppliesData.length > 0}>
      <Box style={boxStyle} p={8} mt={4}>
        <Box display="flex" justifyContent="center">
          <WarningAmberIcon fontSize="large" />
          <Typography variant="body1" color="Text/Dark Grey" ml={1}>
            ไม่มีข้อมูลรายการจองคิวจากชาวประมง กรุณาแก้ไขลำดับการรับปลา
          </Typography>
        </Box>
      </Box>
    </Hidden>
    <Hidden when={props.type === "Shipper"}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        fullWidth
        mt={6}
        mb={6}
      >
        <Button
          color=""
          startIcon="add"
          disabled={props.orderStatus?.title === "ยกเลิก"}
          onClick={props.openFishSuppliesModal}
        >
          เพิ่มการรับปลาจากชาวประมง
        </Button>
      </Box>
    </Hidden>
  </div>
);

export const API = {};

const enhancer = compose(
  withHooks((props, hooks) => {
    const { useCallback, useMemo } = hooks;
    const {
      type,
      orderStatus,
      form,
      remove,
      swap,
      push,
      factoryFishDemandData: factoryFishDemand,
      totalFishDemandAmount,
      onSelect,
      onFishReceived,
      isCreate,
    } = props;

    const { values } = form || {};
    const { orderSupplies = [] } = values || {};

    const fishSuppliesData = useMemo(() => {
      return orderSupplies;
    }, [orderSupplies]);

    const factoryFishDemandData = useMemo(() => {
      return factoryFishDemand;
    }, [factoryFishDemand]);

    const hideWhenUnConfirmOrder = useMemo(() => {
      let shouldHide = true;

      const statusShouldShowTag =
        orderStatus?.title === "กำลังดำเนินการ" ||
        orderStatus?.title === "รอโอนเงิน" ||
        orderStatus?.title === "สำเร็จ";

      if (statusShouldShowTag) {
        shouldHide = false;
      }

      return shouldHide;
    }, [orderStatus]);

    const handleColorStatus = useCallback((value) => {
      switch (value) {
        case "กำลังดำเนินการ":
          return "#376FD0";
        case "รอโอนเงิน":
          return "#FF9800";
        case "สำเร็จ":
          return "#4CAF4F";
        case "รอยืนยัน":
          return "#767676";
        default:
          return "#376FD0";
      }
    }, []);

    const deleteFishSupply = useCallback(
      (index, id) => {
        remove(index);
        const filteredOrder = orderSupplies.filter((orderSupply) =>
          isCreate ? orderSupply.id !== id : orderSupply.bookingId !== id
        );
        onSelect(filteredOrder);
        notifySuccess("แก้ไขข้อมูลสำเร็จ");
      },
      [remove, orderSupplies, onSelect, isCreate]
    );

    const swapDownFishSupply = useCallback(
      (index) => {
        swap(index + 1, index);
        notifySuccess("แก้ไขข้อมูลสำเร็จ");
      },
      [swap]
    );

    const swapUpFishSupply = useCallback(
      (index) => {
        swap(index - 1, index);
        notifySuccess("แก้ไขข้อมูลสำเร็จ");
      },
      [swap]
    );

    const openFishSuppliesModal = useCallback(() => {
      let selectedBookingList = [];

      function onSelectFishSupply(data) {
        if (data) {
          selectedBookingList = data;
        }
      }
      Modal.open({
        className: "FishSuppliesModal",
        title: "เพิ่มการรับปลาจากชาวประมง",
        children: (
          <AddFishSuppliesModal
            selectedSupplyData={fishSuppliesData}
            factoryFishDemandData={factoryFishDemandData}
            orderStatus={orderStatus}
            onSelectFishSupply={onSelectFishSupply}
            isCreate={isCreate}
          />
        ),
        cancelButtonLabel: "ยกเลิก",
        okButtonLabel: "ยืนยัน",
        onClose: async ({ close }) => {
          close();
        },
        onCancel: async ({ close }) => {
          close();
        },
        onOk: async ({ close }) => {
          selectedBookingList.forEach((selectedBooking) => {
            push(selectedBooking);
          });

          close();
          const mergedSupplyList = [...orderSupplies, ...selectedBookingList];
          onSelect(mergedSupplyList);

          notifySuccess("แก้ไขข้อมูลสำเร็จ");
        },
      });
    }, [
      factoryFishDemandData,
      orderStatus,
      push,
      fishSuppliesData,
      orderSupplies,
      onSelect,
      isCreate,
    ]);

    const openDeliverFishByFishermanModal = useCallback(
      (index) => {
        const supplyData = fishSuppliesData[index];
        let netProfit = 0;

        function onCalculateNetProfit(data) {
          netProfit = data;
        }

        Modal.open({
          className: "DeliverFishByFishermanModal",
          title: "แก้ไขการรับปลาจากชาวประมง",
          children: (
            <DeliverFishByFishermanModal
              type={type}
              fishSupply={supplyData}
              onCalculateNetProfit={onCalculateNetProfit}
              factoryFishDemandData={factoryFishDemandData}
            />
          ),
          cancelButtonLabel: "ยกเลิก",
          okButtonLabel: "ยืนยัน",
          onCancel: async ({ close }) => {
            close();
          },
          onOk: async ({ close, ...rest }) => {
            const { values } = rest || {};
            const {
              fishReceivedGrade,
              fishReceivedWeight,
              supplyPaymentSlipImage,
            } = values || {};
            if (
              isNil(fishReceivedWeight) ||
              isNil(fishReceivedGrade) ||
              (type === "Admin" && supplyPaymentSlipImage.length === 0)
            ) {
              Modal.alert({
                title: "เกิดข้อผิดพลาด",
                children: (
                  <Box>
                    <Typography color="Text/Dark Grey" mb={2}>
                      กรุณากรอกข้อมูลต่อไปนี้ให้ครบ
                    </Typography>
                    <Typography color="Other/Danger">
                      {fishReceivedWeight === undefined ||
                      fishReceivedWeight === null
                        ? " • น้ำหนักปลาที่ได้รับ"
                        : ""}
                    </Typography>
                    <Typography color="Other/Danger">
                      {fishReceivedGrade === undefined ||
                      fishReceivedGrade === null
                        ? " • เกรดของปลาที่ได้รับ"
                        : ""}
                    </Typography>
                    <Typography color="Other/Danger">
                      {type === "Admin" && supplyPaymentSlipImage.length === 0
                        ? " • หลักฐานการโอนเงิน"
                        : ""}
                    </Typography>
                  </Box>
                ),
                okButtonLabel: "ปิด",
              });
              return;
            }

            const customSupplyData = {
              ...supplyData,
              status:
                supplyPaymentSlipImage && supplyPaymentSlipImage.length !== 0
                  ? "success"
                  : "waiting_for_payment",
              netProfit,
              fishReceivedWeight,
              fishReceivedGrade,
              images: supplyPaymentSlipImage,
            };

            onFishReceived(customSupplyData);
            close();
          },
        });
      },
      [fishSuppliesData, type, factoryFishDemandData, onFishReceived]
    );

    const sumReceivedSequence = useMemo(() => {
      const sum = orderSupplies.reduce(
        (total, currentItem) => (total = total + currentItem?.amount),
        0
      );
      return sum;
    }, [orderSupplies]);

    const receiveFishSuppliesWeight = useMemo(() => {
      const sum = fishSuppliesData.reduce((total, fishSupply) => {
        if (
          fishSupply.status === "waiting_for_payment" ||
          fishSupply.status === "success"
        ) {
          return (total += fishSupply.fishReceivedWeight);
        }

        return total;
      }, 0);

      return sum;
    }, [fishSuppliesData]);

    const isFinishReceivedFishSupply = useMemo(() => {
      if (!fishSuppliesData || fishSuppliesData.length === 0) {
        return false;
      }

      const result = every(fishSuppliesData, [
        "status",
        "waiting_for_payment" || "success",
      ]);

      return result;
    }, [fishSuppliesData]);

    const factoryDemandAmount = factoryFishDemandData?.amount ?? 0;

    return {
      fishSuppliesData,
      handleColorStatus,
      deleteFishSupply,
      swapDownFishSupply,
      swapUpFishSupply,
      openDeliverFishByFishermanModal,
      type,
      orderStatus,
      hideWhenUnConfirmOrder,
      openFishSuppliesModal,
      totalFishDemandAmount,
      sumReceivedSequence,
      receiveFishSuppliesWeight,
      factoryDemandAmount,
      isFinishReceivedFishSupply,
      isCreate,
    };
  })
);

export default enhancer(FishSupplies);

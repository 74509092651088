import { compose, withHooks } from "enhancers";
import { PageContent } from "layouts";
import { Box, Table, Typography, ExcelGenerator, Tabs, Tab } from "components";
import { formatDate, gql, paths } from "utils/helper";
import { ReactComponent as EditIcon } from "assets/icon/edit.svg";
import { ReactComponent as Admin } from "assets/icon/menu_icon/admin.svg";
import { ReactComponent as ExportIcon } from "assets/icon/export_icon.svg";
import { ReactComponent as Settings } from "assets/icon/settings.svg";

const OrderIndexPage = (props) => (
  <PageContent
    title="จัดการคำสั่งซื้อ"
    breadcrumbs={props.breadcrumbs}
    pageActions={props.pageActions}
  >
    <Box width="100%" mb={-6}>
      <Typography variant="h4" mb={4}>
        รายการคำสั่งซื้อ
      </Typography>
      <Tabs value={props.tabState} onChange={props.changeTabState}>
        <Tab className="FullWidthTab" label="ทั้งหมด" />
        <Tab className="FullWidthTab" label="รอยืนยัน" />
        <Tab className="FullWidthTab" label="กำลังดำเนินการ" />
        <Tab className="FullWidthTab" label="รอโอนเงิน" />
        <Tab className="FullWidthTab" label="สำเร็จ" />
        <Tab className="FullWidthTab" label="ยกเลิก" />
      </Tabs>
      <Table
        columns={props.columns}
        rows={props.tableData}
        loading={props.loading}
        density="compact"
        autoHeight
        disableSelectionOnClick
      />
    </Box>
  </PageContent>
);

export const API = {
  FETCH_ORDERS: gql`
    query FETCH_ORDERS($filters: JSON) {
      orders(filters: $filters) {
        id
        code
        netProfit
        isSuggest
        orderDemands {
          factoryFishDemand {
            factory {
              name
            }
            fish {
              name
            }
            fishDeliverDate
          }
        }
        orderSupplies {
          fishReceivedWeight
          booking {
            amount
          }
        }
        status
      }
    }
  `,
  FETCH_ORDER_DEMANDS: gql`
    query FETCH_ORDER_DEMANDS {
      orderDemands {
        id
        order {
          code
          netProfit
        }
        factoryFishDemand {
          factory {
            name
          }
          fish {
            name
          }
          fishDeliverDate
        }
        status
      }
    }
  `,
};

const enhancer = compose(
  withHooks((props, hooks) => {
    const {
      useMemo,
      useCallback,
      useQuery,
      useEffect,
      useState,
      useLocation,
    } = hooks;
    const { loading, data, error, refetch } = useQuery(API.FETCH_ORDERS, {
      variables: {
        sorts: [{ field: "code", sort: "asc" }],
      },
    });
    const [tabState, setTabState] = useState(0);

    const location = useLocation();
    const { hash: hashTag } = location || {};

    const columns = useMemo(() => {
      return [
        {
          width: 40,
          field: "isSuggest",
          headerName: " ",
          filterable: false,
          sortable: false,
          type: "isSuggest",
        },
        { width: 155, field: "code", headerName: "รหัสคำสั่งซื้อ" },
        { width: 205, field: "factoryName", headerName: "ชื่อโรงงาน" },
        {
          width: 150,
          field: "fishName",
          headerName: "ชื่อปลา",
        },
        {
          width: 185,
          field: "amount",
          headerName: "รับปลาแล้ว (กก.)",
          type: "amount",
        },
        {
          width: 130,
          field: "netProfit",
          headerName: "กำไรสุทธิ",
          type: "currency",
        },
        {
          width: 145,
          field: "shippingAt",
          headerName: "วันที่ส่งปลา",
          type: "dateOnly",
        },
        {
          width: 115,
          field: "status",
          headerName: "สถานะ",
          type: "tagOrder",
        },
        {
          width: 40,
          field: "actions",
          headerName: " ",
          filterable: false,
          sortable: false,
          type: "actions",
        },
      ];
    }, []);

    const breadcrumbs = useMemo(() => {
      return [
        { path: paths.homePath(), label: "หน้าแรก" },
        { path: null, label: "จัดการคำสั่งซื้อ" },
      ];
    }, []);

    useEffect(() => {
      switch (hashTag) {
        case "#รอยืนยัน":
          refetch({
            filters: { status: "waiting_for_confirm" },
          });
          return setTabState(1);
        case "#กำลังดำเนินการ":
          refetch({
            filters: { status: "in_progress" },
          });
          return setTabState(2);
        case "#รอโอนเงิน":
          refetch({
            filters: { status: "waiting_for_payment" },
          });
          return setTabState(3);
        case "#สำเร็จ":
          refetch({
            filters: { status: "success" },
          });
          return setTabState(4);
        case "#ยกเลิก":
          refetch({
            filters: { status: "canceled" },
          });
          return setTabState(5);
        default:
          refetch({ filters: {} });
          return setTabState(0);
      }
    }, [hashTag, refetch, props]);

    const changeTabState = useCallback((e, value) => {
      switch (value) {
        case 0:
          return paths.ordersPath().push("");
        case 1:
          return paths.ordersPath().push("#รอยืนยัน");
        case 2:
          return paths.ordersPath().push("#กำลังดำเนินการ");
        case 3:
          return paths.ordersPath().push("#รอโอนเงิน");
        case 4:
          return paths.ordersPath().push("#สำเร็จ");
        case 5:
          return paths.ordersPath().push("#ยกเลิก");
        default:
          return paths.ordersPath().push("");
      }
    }, []);

    const gotoEditPath = useCallback(
      (id) => paths.orderEditPath(id).push(),
      []
    );

    const initialTableData = useMemo(() => {
      return [];
    }, []);

    const tableData = useMemo(() => {
      if (loading || error) {
        return initialTableData;
      }
      return data.orders.map((order) => {
        const {
          id,
          code,
          netProfit,
          orderDemands,
          orderSupplies,
          isSuggest,
        } = order;

        var sumFishReceivedWeight = 0;
        var sumAmount = 0;
        orderSupplies.forEach((orderSupplie) => {
          sumAmount += orderSupplie.booking.amount;
          sumFishReceivedWeight += orderSupplie.fishReceivedWeight;
        });

        let customStatus = "";

        switch (order.status) {
          case "waiting_for_payment":
            customStatus = "รอโอนเงิน";
            break;
          case "in_progress":
            customStatus = "กำลังดำเนินการ";
            break;
          case "waiting_for_confirm":
            customStatus = "รอยืนยัน";
            break;
          case "success":
            customStatus = "สำเร็จ";
            break;
          case "canceled":
            customStatus = "ยกเลิก";
            break;
          default:
            customStatus = "รอโอนเงิน";
            break;
        }

        return {
          id,
          isSuggest,
          code,
          factoryName: orderDemands[0]?.factoryFishDemand?.factory.name ?? "",
          fishName: "ปลา" + orderDemands[0]?.factoryFishDemand?.fish.name ?? "",
          amount: sumFishReceivedWeight + " / " + sumAmount,
          netProfit,
          shippingAt: orderDemands[0]?.factoryFishDemand?.fishDeliverDate,
          status: customStatus,
          actions: [{ Icon: EditIcon, onClick: () => gotoEditPath(id) }],
        };
      });
    }, [loading, data, error, gotoEditPath, initialTableData]);

    const downloadExcel = useCallback(() => {
      ExcelGenerator.generate({
        fileName: `order-${formatDate(new Date(), "yyyyMMddhhmmss")}`,
        columns: [
          { field: "code", title: "รหัสคำสั่งซื้อ" },
          { field: "factoryName", title: "ชื่อโรงงาน" },
          {
            field: "fishName",
            title: "ชื่อปลา",
          },
          {
            field: "amount",
            title: "รับปลาแล้ว (กก.)",
          },
          {
            field: "netProfit",
            title: "กำไรสุทธิ",
          },
          {
            field: "shippingAt",
            title: "วันที่ส่งปลา",
          },
          {
            field: "status",
            title: "สถานะ",
          },
        ],
        data: tableData,
      });
    }, [tableData]);

    const pageActions = useMemo(() => {
      return [
        {
          children: "ออกรายงาน",
          startIcon: <ExportIcon />,
          onClick: downloadExcel,
          color: "default",
        },
        {
          children: "สร้างคำสั่งซื้อเอง",
          startIcon: <Admin />,
          onClick: () => paths.orderNewPath().push(),
          color: "default",
        },
        {
          children: "สร้างคำสั่งซื้ออัตโนมัติ",
          startIcon: <Settings />,
          onClick: () => paths.orderAutomaticNewPath().push(),
          color: "primary",
        },
      ];
    }, [downloadExcel]);

    return {
      tableData,
      tabState,
      changeTabState,
      loading,
      downloadExcel,
      columns,
      rowCount: 0,
      breadcrumbs,
      pageActions,
    };
  })
);

export default enhancer(OrderIndexPage);

import {
  Box,
  Field,
  Grid,
  Select,
  TextField,
  Form,
  Typography,
  Link,
  Hidden,
  FieldArray,
} from "components";
import { compose, withHooks } from "enhancers";
import { toCurrency, formatPhoneNumber } from "utils/helper";
import {
  PlaceOutlined as PlaceIcon,
  LocalPhoneOutlined as TelephoneIcon,
} from "@material-ui/icons";
import FileImageUpload from "components/advance/FileImageUpload";
import { fishDepreciationList } from "common/fishDepreciation";

const boxStyle = {
  background: "#F6F9FC",
  borderRadius: 8,
};

const placeIconStyle = {
  color: "#A1A1A1",
  width: 16,
  height: 16,
};

const linkStyle = {
  textDecoration: "underline",
  color: "#376FD0",
};

const DeliverFishByFishermanModal = (props) => (
  <Form>
    <Box
      p={4}
      mt={2}
      mb={4}
      display="flex"
      justifyContent="space-between"
      style={boxStyle}
    >
      <Box>
        <Box display="flex" mb={3}>
          <Typography variant="h6" color="Text/Black">
            {props.fishermanName}
          </Typography>
        </Box>
        <Box display="flex" mb={1}>
          <Box mr={2}>
            <PlaceIcon style={placeIconStyle} />
          </Box>
          <Link variant="body1" color="Other/Info" mr={2} style={linkStyle}>
            {props.fishermanAddress}
          </Link>
        </Box>
        <Box display="flex" mb={0}>
          <Box mr={2}>
            <TelephoneIcon style={placeIconStyle} />
          </Box>
          <Typography variant="body1" color="Text/Black">
            {formatPhoneNumber(props.fishermanPhoneNumber)}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        <Typography variant="h2" color="Other/Info">
          {toCurrency(props.amount ?? 0, {
            minimumFractionDigits: 0,
          })}
        </Typography>
        <Box ml={1} mt={2}>
          <Typography variant="caption" color="Text/Dark Grey">
            {"กก."}
          </Typography>
        </Box>
      </Box>
    </Box>
    <Grid container spacing={6} mb={0}>
      <Grid item xs={6}>
        <Field
          component={TextField}
          name="fishReceivedWeight"
          type="number"
          label="น้ำหนักปลาที่ได้รับ"
          fullWidth
          unit="กิโลกรัม"
          required
          fast={false}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={Select}
          name="fishReceivedGrade"
          label="เกรดของปลาที่ได้รับ"
          fullWidth
          options={fishDepreciationList}
          required
          fast={false}
        />
      </Grid>
    </Grid>

    {/* USE WHEN ADMIN */}
    <Hidden when={props.userType === "Shipper"}>
      <Box display="flex" alignItems="center" fullWidth mt={2} mb={5}>
        <Box display="flex" flex={1} flexDirection="row" fullWidth>
          <Typography variant="body1" color="Text/Black">
            จำนวนเงินที่ต้องจ่ายให้ชาวประมง
          </Typography>
        </Box>
        <Typography variant="h6" color="Text/Black">
          {toCurrency(props.purchasePrice ?? 0, {
            minimumFractionDigits: 0,
          }) + " บาท"}
        </Typography>
      </Box>
      <Box mt={3} display="flex">
        <Typography variant="h6" color="Text/Black">
          หลักฐานการโอนเงิน
        </Typography>{" "}
        <Typography variant="h6" color="#F34336">
          *
        </Typography>
      </Box>
      <FieldArray
        component={FileImageUpload}
        name="supplyPaymentSlipImage"
        addButtonLabel="เพิ่มรูป"
      />
    </Hidden>
  </Form>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const { useFormikContext, useMemo, useEffect } = hooks;
    const {
      fishSupply,
      factoryFishDemandData,
      type: userType,
      onCalculateNetProfit,
    } = props;

    const formikBag = useFormikContext();

    const { fisherman, amount, fishReceivedGrade, fishReceivedWeight, images } =
      fishSupply || {};
    const {
      name: fishermanName = "",
      phoneNumber: fishermanPhoneNumber = "",
      address,
    } = fisherman || {};

    const fishermanAddress = `${address?.address ?? ""} ${
      address?.district ?? ""
    } ${address?.subDistrict ?? ""} ${address?.province ?? ""} ${
      address?.zipCode ?? ""
    }`;

    useEffect(() => {
      formikBag.values.fishReceivedGrade = fishReceivedGrade;
      formikBag.values.fishReceivedWeight = fishReceivedWeight;
      formikBag.values.supplyPaymentSlipImage = images;
      formikBag.setValues(formikBag.values);
    }, []);

    const purchasePrice = useMemo(() => {
      let sum = 0;
      if (
        formikBag.values?.fishReceivedGrade &&
        formikBag.values?.fishReceivedWeight
      ) {
        const fishDep = factoryFishDemandData.factory.fishDepreciation.find(
          (fishDepreciation) =>
            factoryFishDemandData.fish.id === fishDepreciation.fishId
        );

        let factoryFishDemandPurchasePrice =
          factoryFishDemandData.purchasePrice;

        if (formikBag.values?.fishReceivedGrade === "A") {
          factoryFishDemandPurchasePrice =
            factoryFishDemandPurchasePrice *
            (fishDep.gradeADepreciationPct / 100);
        } else if (formikBag.values?.fishReceivedGrade === "B") {
          factoryFishDemandPurchasePrice =
            factoryFishDemandPurchasePrice *
            (fishDep.gradeBDepreciationPct / 100);
        } else if (formikBag.values?.fishReceivedGrade === "C") {
          factoryFishDemandPurchasePrice =
            factoryFishDemandPurchasePrice *
            (fishDep.gradeCDepreciationPct / 100);
        } else if (formikBag.values?.fishReceivedGrade === "D") {
          factoryFishDemandPurchasePrice =
            factoryFishDemandPurchasePrice *
            (fishDep.gradeDDepreciationPct / 100);
        }

        const buyingPriceFormFishermanPerKg =
          factoryFishDemandPurchasePrice *
          (factoryFishDemandData.fish.factoryPurchasePricePct / 100);

        sum =
          formikBag.values?.fishReceivedWeight * buyingPriceFormFishermanPerKg;
      }
      return sum;
    }, [
      formikBag.values?.fishReceivedGrade,
      formikBag.values?.fishReceivedWeight,
      factoryFishDemandData.factory.fishDepreciation,
      factoryFishDemandData.fish.factoryPurchasePricePct,
      factoryFishDemandData.fish.id,
    ]);

    useEffect(() => {
      onCalculateNetProfit(purchasePrice);
    }, [purchasePrice, onCalculateNetProfit]);

    return {
      fishermanName,
      fishermanPhoneNumber,
      fishermanAddress,
      amount,
      userType,
      purchasePrice,
    };
  })
);

export default enhancer(DeliverFishByFishermanModal);

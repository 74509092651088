import React from "react";
import { compose, withHooks, defaultProps } from "enhancers";
import { FormControlLabel, Radio } from "@material-ui/core";
import { RadioGroup as MuiRadioGroup } from "formik-material-ui";

import { ReactComponent as RadioUncheckedCircleIcon } from "assets/icon/radio_unchecked_circle_icon.svg";
import { ReactComponent as RadioCheckedCircleIcon } from "assets/icon/radio_checked_circle_icon.svg";
import Typography from "./Typography";

const RadioGroup = ({ options, disabled, row, handleDeselect, ...rest }) => (
  <MuiRadioGroup {...rest} row={row}>
    {options.map((option, index) => (
      <FormControlLabel
        key={index}
        value={option.value}
        control={
          <Radio
            checked={rest.field.value === option.value}
            icon={<RadioUncheckedCircleIcon />}
            checkedIcon={<RadioCheckedCircleIcon />}
            disabled={disabled}
            onClick={() => handleDeselect(option.value)}
          />
        }
        label={
          <>
            <Typography variant="body1">{option.label}</Typography>
            {option.subLabel && (
              <Typography variant="caption" color="Text/Dark Grey">
                {option.subLabel}
              </Typography>
            )}
          </>
        }
        disabled={disabled}
      />
    ))}
  </MuiRadioGroup>
);

const enhancer = compose(
  defaultProps({
    options: [],
  }),
  withHooks((props, hooks) => {
    const { options, disabled, ...rest } = props;
    const { useCallback } = hooks;

    const name = props.field.name;
    const value = props.field.value;
    const setFieldValue = props.form.setFieldValue;
    const handleDeselect = useCallback(
      (selectedValue) => {
        if (value === selectedValue) {
          setFieldValue(name, null);
        }
      },
      [name, value, setFieldValue]
    );

    return {
      options,
      disabled,
      handleDeselect,
      ...rest,
    };
  })
);

export default enhancer(RadioGroup);

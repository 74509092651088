import { compose, withHooks } from "enhancers";
import {
  Box,
  Grid,
  Typography,
  Form,
  Field,
  TextField,
  Button,
  Modal,
  Select,
} from "components";

import { ReactComponent as TrashIcon } from "assets/icon/trash_icon.svg";
import { FieldArray } from "formik";
import { find } from "lodash";

const fishes = (props) => (
  <Form>
    <Box display="flex" flex={1} flexDirection="row" fullWidth>
      <Grid item xs={12}>
        <Typography variant="h4" mb={6}>
          ข้อมูลความต้องการซื้อปลา
        </Typography>

        <Grid container spacing={6} mb={6}>
          <Grid item xs={4}>
            <Typography variant="h6" pl={4}>
              ชื่อปลา
            </Typography>
          </Grid>
          <Grid item flex={1}>
            <Typography variant="h6">ราคาขายให้โรงงาน</Typography>
          </Grid>
          <Grid item flex={1}>
            <Typography variant="h6">ปริมาณขั้นต่ำ</Typography>
          </Grid>
          <Grid item flex={1}>
            <Typography variant="h6">ปริมาณที่โรงงานต้องการ</Typography>
          </Grid>
          <Grid item xs={1}></Grid>

          <Grid
            item
            xs={12}
            style={{
              borderBottom: "1px solid #E0E0E0",
              padding: 0,
              margin: "0px 12px",
            }}
          ></Grid>
        </Grid>

        <FieldArray
          name="fishes"
          render={(arrayHelpers) => (
            <div>
              {props.fishDepList && props.fishDepList.length > 0 ? (
                props.fishDepList.map((fb, index) => (
                  <Grid container spacing={6} mb={6}>
                    <Grid item xs={4} pr="6px !important">
                      <Field
                        component={Select}
                        name={`fishes[${index}].id`}
                        type="text"
                        label=""
                        options={props.fishOptions}
                        fullWidth
                        // required
                      />
                    </Grid>
                    <Grid item flex={1} pr="6px !important" pl="6px !important">
                      <Field
                        component={TextField}
                        type="number"
                        name={`fishes[${index}].purchasePrice`}
                        label=""
                        fullWidth
                        unit="บาท"
                        // required
                      />
                    </Grid>
                    <Grid item flex={1} pr="6px !important" pl="6px !important">
                      <Field
                        component={TextField}
                        type="number"
                        fullWidth
                        name={`fishes[${index}].minPurchaseVolume`}
                        label=""
                        unit="กก."
                        // required
                      />
                    </Grid>
                    <Grid item flex={1} pr="6px !important" pl="6px !important">
                      <Field
                        component={TextField}
                        type="number"
                        fullWidth
                        name={`fishes[${index}].maxPurchaseVolume`}
                        label=""
                        unit="กก."
                        // required
                      />
                    </Grid>
                    <Grid item xs={1} pl="6px !important">
                      <Box
                        display="flex"
                        flex={1}
                        alignItems="center"
                        fullWidth
                        style={{ height: "100%" }}
                      >
                        <TrashIcon
                          style={{
                            width: "20px",
                            height: "20px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            Modal.open({
                              title: `ลบข้อมูลความต้องการซื้อปลา${
                                fb.id.label ?? ""
                              }`,
                              children: `ข้อมูลจะไม่สามารถกู้คืนได้`,
                              cancelButtonLabel: "ปิด",
                              okButtonLabel: "ลบข้อมูล",
                              onOk: async ({ close }) => {
                                close();
                                arrayHelpers.remove(index);
                              },
                            });
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                ))
              ) : (
                <Typography
                  variant="body1"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  color="Text/Dark Grey"
                  mb={6}
                >
                  ไม่มีรายการความต้องการซื้อปลา กรุณาเพิ่มรายการ
                </Typography>
              )}
              <Grid
                item
                xs={12}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  startIcon="add"
                  width={171}
                  onClick={() => arrayHelpers.push({})}
                >
                  เพิ่มรายการ
                </Button>
              </Grid>
            </div>
          )}
        />
      </Grid>
    </Box>
  </Form>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const { fishDepreciation, fishes, setFieldValue } = props;
    const { useMemo } = hooks;

    const fishOptions = useMemo(() => {
      const fishList = fishDepreciation?.map((fb, index) => {
        const { fish, purchasePrice, gradeADepreciationPct } = fb;

        const calPurchasePrice = purchasePrice * (gradeADepreciationPct / 100);

        return {
          label: fish.name,
          value: fish.id,
          purchasePrice: calPurchasePrice,
        };
      });

      return fishList;
    }, [fishDepreciation]);

    const fishDepList = useMemo(() => {
      let response = [];
      if (fishes)
        response = fishes.map((item, index) => {
          const fishDep = find(fishOptions, { value: item.id });
          if (!item.purchasePrice && fishDep) {
            setFieldValue(
              `fishes['${index}'].purchasePrice`,
              fishDep?.purchasePrice
            );
          }
          return {
            id: {
              label: fishDep?.label,
              value: item.id,
            },
            purchasePrice: item?.purchasePrice || fishDep?.purchasePrice,
            minPurchaseVolume: item?.minPurchaseVolume,
            maxPurchaseVolume: item?.maxPurchaseVolume,
          };
        });

      return response;
    }, [fishes, fishOptions, setFieldValue]);

    return {
      fishOptions,
      fishDepList,
    };
  })
);

export default enhancer(fishes);

import { compose, withHooks } from "enhancers";
import { PageContent } from "layouts";
import { Box, Table, Typography, Tabs, Tab } from "components";
import { formatDate, gql, paths } from "utils/helper";
import { parse } from "date-fns";
import { ReactComponent as ViewIcon } from "assets/icon/view-icon.svg";
import { ReactComponent as AddIcon } from "assets/icon/add-icon.svg";

const BookingIndexPage = (props) => (
  <PageContent
    title="จัดการการจองคิว"
    breadcrumbs={props.breadcrumbs}
    pageActions={props.pageActions}
  >
    <Box width="100%" mb={-6}>
      <Typography variant="h4" mb={4}>
        รายการจองคิว
      </Typography>
      <Tabs value={props.tabState} onChange={props.changeTabState}>
        <Tab className="FullWidthTab" label="ทั้งหมด" />
        <Tab className="FullWidthTab" label="รอรับปลา" />
        <Tab className="FullWidthTab" label="รอโอนเงิน" />
        <Tab className="FullWidthTab" label="รอยืนยัน" />
        <Tab className="FullWidthTab" label="สำเร็จ" />
        <Tab className="FullWidthTab" label="ยกเลิก" />
      </Tabs>
      <Table
        columns={props.columns}
        rows={props.tableData}
        loading={props.loading}
        density="compact"
        autoHeight
        disableSelectionOnClick
        rowCount={props.rowCount}
        initialFilter={props.initialFilter}
      />
    </Box>
  </PageContent>
);

export const API = {
  FETCH_BOOKINGS: gql`
    query FETCH_BOOKINGS($filters: JSON) {
      bookings(filters: $filters) {
        bookings {
          id
          code
          amount
          status
          maxPurchasePrice
          receiveDate
          fisherman {
            code
            name
          }
          fish {
            name
          }
        }
      }
    }
  `,
};

const enhancer = compose(
  withHooks((props, hooks) => {
    const {
      useMemo,
      useCallback,
      useQuery,
      useEffect,
      useState,
      useLocation,
    } = hooks;
    const { loading, data, error, refetch } = useQuery(API.FETCH_BOOKINGS, {
      variables: {
        sorts: [{ field: "code", sort: "asc" }],
        filters: {},
      },
    });
    const [tabState, setTabState] = useState(0);

    const location = useLocation();
    const hashTag = location.hash;

    useEffect(() => {
      switch (hashTag) {
        case "#waiting_for_shipping":
          refetch({
            filters: { status: "waiting_for_shipping" },
          });
          return setTabState(1);
        case "#waiting_for_payment":
          refetch({
            filters: { status: "waiting_for_payment" },
          });
          return setTabState(2);
        case "#waiting_for_confirm":
          refetch({
            filters: { status: "waiting_for_confirm" },
          });
          return setTabState(3);
        case "#completed":
          refetch({
            filters: { status: "completed" },
          });
          return setTabState(4);
        case "#canceled":
          refetch({
            filters: { status: "canceled" },
          });
          return setTabState(5);
        default:
          refetch({ filters: {} });
          return setTabState(0);
      }
    }, [hashTag, refetch]);

    const changeTabState = useCallback((e, value) => {
      switch (value) {
        case 0:
          return paths.bookingsPath().push("");
        case 1:
          return paths.bookingsPath().push("#waiting_for_shipping");
        case 2:
          return paths.bookingsPath().push("#waiting_for_payment");
        case 3:
          return paths.bookingsPath().push("#waiting_for_confirm");
        case 4:
          return paths.bookingsPath().push("#completed");
        case 5:
          return paths.bookingsPath().push("#canceled");
        default:
          return paths.bookingsPath().push("");
      }
    }, []);

    const gotoViewBooking = useCallback((params) => {
      const { id } = params;
      paths.bookingsEditPath(id).push();
    }, []);

    const initialTableData = useMemo(() => {
      return [];
    }, []);

    const tableData = useMemo(() => {
      if (loading || error) {
        return initialTableData;
      }
      return data.bookings.bookings.map((booking) => {
        const {
          id,
          code,
          amount,
          status,
          fisherman,
          fish,
          maxPurchasePrice,
          receiveDate,
        } = booking;

        const fishermanName = `[${fisherman.code}] ${fisherman.name}`;

        return {
          id,
          code,
          fisherman: fishermanName,
          fishType: fish.name,
          status,
          amount,
          maxPurchasePrice: maxPurchasePrice,
          // receiveDate: formatDate(receiveDate, "dd/MM/yyyy"),
          receiveDate: parse(
            formatDate(receiveDate, "dd/MM/yyyy"),
            "dd/MM/yyyy",
            new Date()
          ),
          actions: [{ Icon: ViewIcon, onClick: gotoViewBooking }],
        };
      });
    }, [loading, data, error, gotoViewBooking, initialTableData]);

    const breadcrumbs = useMemo(() => {
      return [
        { path: paths.homePath(), label: "หน้าแรก" },
        { path: null, label: "จัดการการจองคิว" },
      ];
    }, []);

    const pageActions = useMemo(() => {
      return [
        {
          children: "สร้างการจองคิว",
          startIcon: <AddIcon />,
          onClick: () => paths.bookingsNewPath().push(),
          color: "primary",
        },
      ];
    }, []);

    const columns = useMemo(() => {
      return [
        { width: 180, field: "code", headerName: "รหัสคำสั่งซื้อ" },
        { width: 220, field: "fisherman", headerName: "ชาวประมง" },
        {
          width: 150,
          field: "fishType",
          headerName: "ชนิดปลา",
        },
        {
          width: 130,
          type: "currency",
          field: "amount",
          headerName: "ปริมาณ",
        },
        {
          width: 150,
          type: "currency",
          field: "maxPurchasePrice",
          headerName: "ราคาสูงสุด",
        },
        {
          width: 120,
          type: "fishStatus",
          field: "status",
          headerName: "สถานะ",
        },
        {
          width: 160,
          field: "receiveDate",
          headerName: "วันที่รับปลา",
          type: "dateOnly",
        },
        {
          width: 40,
          field: "actions",
          headerName: " ",
          filterable: false,
          sortable: false,
          type: "actions",
        },
      ];
    }, []);

    const initialFilter = useMemo(() => {
      return {
        items: [],
      };
    }, []);

    return {
      tableData,
      tabState,
      changeTabState,
      loading,
      breadcrumbs,
      pageActions,
      columns,
      initialFilter,
    };
  })
);

export default enhancer(BookingIndexPage);

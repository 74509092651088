import { findKey } from "lodash";
import { isClient } from "common/helper";

const config = {
  production: {
    // TODO: Change to production url when available
    HOST: "https://backoffice.fishyuofficial.com",
    API_ENDPOINT: "https://backoffice.fishyuofficial.com/graphql",
    REST_API_ENDPOINT: "https://api.fishyuofficial.com/api/v1",
  },
  staging: {
    HOST: "https://dev.backoffice.fishyu.legaldrive.moveplus.dynu.net",
    API_ENDPOINT:
      "https://dev.backoffice.fishyu.legaldrive.moveplus.dynu.net/graphql",
    REST_API_ENDPOINT:
      "https://dev.api.fishyu.legaldrive.moveplus.dynu.net/api/v1",
  },
  uat: {
    HOST: "https://uat.backoffice.fishyu.legaldrive.moveplus.dynu.net",
    API_ENDPOINT:
      "https://uat.backoffice.fishyu.legaldrive.moveplus.dynu.net/graphql",
    REST_API_ENDPOINT:
      "https://uat.backoffice.fishyu.legaldrive.moveplus.dynu.net/api/v1",
  },
  e2e: {
    HOST: "https://e2e-dev.backoffice.fishyu.legaldrive.moveplus.dynu.net",
    API_ENDPOINT:
      "https://e2e-dev.backoffice.fishyu.legaldrive.moveplus.dynu.net/graphql",
    REST_API_ENDPOINT:
      "https://e2e-dev.api.fishyu.legaldrive.moveplus.dynu.net/api/v1",
  },
  local: {
    HOST: "http://localhost:20802",
    // API_ENDPOINT: "https://api.moreloop.ws/graphql",
    API_ENDPOINT: "http://localhost:20801/graphql",
    REST_API_ENDPOINT: "http://localhost:20801/api/v1",
  },
};

const getEnvByHost = () =>
  isClient ? findKey(config, { HOST: window.location.origin }) : null;

export const env = getEnvByHost() || process.env.APP_ENV || "local";

export default config[env];

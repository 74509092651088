import {
  Box,
  Field,
  Grid,
  TextField,
  Typography,
  Hidden,
  Link,
  FieldArray,
} from "components";
import { compose, withHooks } from "enhancers";
import { toCurrency, formatPhoneNumber } from "utils/helper";
import {
  PlaceOutlined as PlaceIcon,
  LocalPhoneOutlined as TelephoneIcon,
} from "@material-ui/icons";
import FileImageUpload from "components/advance/FileImageUpload";

const boxStyle = {
  background: "#F6F9FC",
  borderRadius: 8,
};

const placeIconStyle = {
  color: "#A1A1A1",
  width: 16,
  height: 16,
};

const linkStyle = {
  textDecoration: "underline",
  color: "#376FD0",
};

const DeliverFishToFactoryModal = (props) => (
  <>
    <Box
      p={4}
      mt={2}
      display="flex"
      justifyContent="space-between"
      style={boxStyle}
    >
      <Box>
        <Box display="flex" mb={3}>
          <Typography variant="h6" color="Text/Black">
            {props.factoryFishDemandData?.factory?.name}
          </Typography>
        </Box>
        <Box display="flex" mb={1}>
          <Box mr={2}>
            <PlaceIcon style={placeIconStyle} />
          </Box>
          <Link variant="body1" color="Other/Info" mr={2} style={linkStyle}>
            {props.factoryAddress}
          </Link>
        </Box>
        <Box display="flex" mb={0}>
          <Box mr={2}>
            <TelephoneIcon style={placeIconStyle} />
          </Box>
          <Typography variant="body1" color="Text/Black">
            {formatPhoneNumber(
              props.factoryFishDemandData?.factory?.phoneNumber
            )}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        <Typography variant="h2" color="Other/Info">
          {toCurrency(props.sumReceivedSequence ?? 0, {
            minimumFractionDigits: 0,
          })}
        </Typography>
        <Box ml={1} mt={2}>
          <Typography variant="caption" color="Text/Dark Grey">
            {"กก."}
          </Typography>
        </Box>
      </Box>
    </Box>
    <Box display="flex" alignItems="center" fullWidth mt={10} mb={5}>
      <Box display="flex" flex={1} flexDirection="row" fullWidth>
        <Typography variant="h6" color="Text/Black">
          ข้อมูลเกรดปลาที่โรงงานพิจารณารับซื้อ
        </Typography>
      </Box>
      <Typography variant="body1" color="Text/Black">
        {"ส่งแล้ว "}
        {toCurrency(props.deliverAmount ?? 0, {
          minimumFractionDigits: 0,
        })}
        {"/" +
          toCurrency(props.sumReceivedSequence ?? 0, {
            minimumFractionDigits: 0,
          }) +
          " "}
        กก.
      </Typography>
    </Box>
    <Grid container spacing={6} mb={0}>
      <Grid item xs={6}>
        <Field
          component={TextField}
          name="gradeAFishDeliveredWeight"
          type="number"
          label="เกรด A"
          fullWidth
          unit="กิโลกรัม"
          minimumFractionDigits={2}
          fast={false}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextField}
          name="gradeBFishDeliveredWeight"
          type="number"
          label="เกรด B"
          unit="กิโลกรัม"
          fullWidth
          fast={false}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextField}
          name="gradeCFishDeliveredWeight"
          label="เกรด C"
          type="number"
          fullWidth
          unit="กิโลกรัม"
          fast={false}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextField}
          name="gradeDFishDeliveredWeight"
          label="เกรด D"
          type="number"
          fullWidth
          unit="กิโลกรัม"
          fast={false}
        />
      </Grid>
    </Grid>
    <Box display="flex" alignItems="center" fullWidth mt={2} mb={5}>
      <Box display="flex" flex={1} flexDirection="row" fullWidth>
        <Typography variant="body1" color="Text/Black">
          จำนวนเงินที่จะได้รับจากโรงงาน
        </Typography>
      </Box>
      <Typography variant="h6" color="Text/Black">
        {toCurrency(props.receivePrice ?? 0, {
          minimumFractionDigits: 0,
        }) + " บาท"}
      </Typography>
    </Box>
    <Hidden
      when={
        props.orderDemandData?.status !== "กำลังดำเนินการ" &&
        props.deliverStatus?.title !== "กำลังดำเนินการ" &&
        props.deliverStatus?.title !== "รอโอนเงิน"
      }
    >
      <Box mt={3} display="flex">
        <Typography variant="h6" color="Text/Black">
          หลักฐานการส่งปลาให้โรงงาน
        </Typography>
        <Typography variant="h6" color="#F34336">
          *
        </Typography>
      </Box>
      <Typography variant="caption" color="Text/Dark Grey">
        ใบสรุปราคาจากโรงงาน หรือรูปภาพขณะชั่งน้ำหนักปลา
      </Typography>
      <FieldArray
        component={FileImageUpload}
        name="fishDeliveryImage"
        addButtonLabel="เพิ่มรูป"
      />
    </Hidden>
    <Hidden when={props.type === "Shipper"}>
      <Box mt={3}>
        <Typography variant="h6" color="Text/Black">
          หลักฐานการโอนเงิน
        </Typography>
      </Box>
      <FieldArray
        component={FileImageUpload}
        name="paymentSlipImage"
        addButtonLabel="เพิ่มรูป"
      />
    </Hidden>
  </>
);

export const API = {};

const enhancer = compose(
  withHooks((props, hooks) => {
    const { useFormikContext, useMemo, useEffect, useState } = hooks;
    const {
      factoryFishDemandData,
      factoryAddress,
      orderSupplies,
      orderDemandData,
      orderStatus,
      deliverStatus,
      type,
      onCalculateNetProfit,
    } = props;
    const [fishDepreciation, setFishDepreciation] = useState(null);

    const formikBag = useFormikContext();

    const { values } = formikBag || {};

    useEffect(() => {
      formikBag.values.gradeAFishDeliveredWeight =
        orderDemandData.gradeAFishDeliveredWeight;
      formikBag.values.gradeBFishDeliveredWeight =
        orderDemandData.gradeBFishDeliveredWeight;
      formikBag.values.gradeCFishDeliveredWeight =
        orderDemandData.gradeCFishDeliveredWeight;
      formikBag.values.gradeDFishDeliveredWeight =
        orderDemandData.gradeDFishDeliveredWeight;
      formikBag.values.fishDeliveryImage = orderDemandData.fishDeliverImages;
      formikBag.values.paymentSlipImage = orderDemandData.paymentSlipImages;
      formikBag.setValues(formikBag.values);
    }, []);

    const sumReceivedSequence = useMemo(() => {
      const sum = orderSupplies.reduce(
        (total, currentItem) => (total = total + currentItem?.amount),
        0
      );
      return sum;
    }, [orderSupplies]);

    useEffect(() => {
      factoryFishDemandData.factory.fishDepreciation.forEach(
        (fishDepreciation) => {
          if (factoryFishDemandData.fish.id === fishDepreciation.fishId) {
            setFishDepreciation(fishDepreciation);
          }
        }
      );
    }, [factoryFishDemandData]);

    const deliverAmount = useMemo(() => {
      const gradeA = values?.gradeAFishDeliveredWeight ?? 0;
      const gradeB = values?.gradeBFishDeliveredWeight ?? 0;
      const gradeC = values?.gradeCFishDeliveredWeight ?? 0;
      const gradeD = values?.gradeDFishDeliveredWeight ?? 0;
      const amount = gradeA + gradeB + gradeC + gradeD;
      return amount;
    }, [values]);

    const receivePrice = useMemo(() => {
      const gradeA = values?.gradeAFishDeliveredWeight ?? 0;
      const gradeB = values?.gradeBFishDeliveredWeight ?? 0;
      const gradeC = values?.gradeCFishDeliveredWeight ?? 0;
      const gradeD = values?.gradeDFishDeliveredWeight ?? 0;

      return (
        gradeA *
          factoryFishDemandData?.purchasePrice *
          (fishDepreciation?.gradeADepreciationPct / 100) +
        gradeB *
          factoryFishDemandData?.purchasePrice *
          (fishDepreciation?.gradeBDepreciationPct / 100) +
        gradeC *
          factoryFishDemandData?.purchasePrice *
          (fishDepreciation?.gradeCDepreciationPct / 100) +
        gradeD *
          factoryFishDemandData?.purchasePrice *
          (fishDepreciation?.gradeDDepreciationPct / 100)
      );
    }, [values, fishDepreciation, factoryFishDemandData]);

    useEffect(() => {
      onCalculateNetProfit(receivePrice);
    }, [receivePrice, onCalculateNetProfit]);

    return {
      factoryFishDemandData,
      factoryAddress,
      sumReceivedSequence,
      orderDemandData,
      deliverAmount,
      receivePrice,
      orderStatus,
      deliverStatus,
      type,
    };
  })
);

export default enhancer(DeliverFishToFactoryModal);

import { Box, Grid, Typography, Divider, Link } from "components";
import { formatPhoneNumber, formatDate, toCurrency } from "utils/helper";
import {
  PlaceOutlined as PlaceIcon,
  LocalPhoneOutlined as TelephoneIcon,
} from "@material-ui/icons";
import { compose, defaultProps, withHooks } from "enhancers";

const boxStyle = {
  background: "#E7F4FD",
  borderRadius: 8,
};

const placeIconStyle = {
  color: "#A1A1A1",
  width: 16,
  height: 16,
};

const linkStyle = {
  textDecoration: "underline",
  color: "#376FD0",
};

const FactoryDemandPanel = (props) => (
  <Box display="flex" mb={4}>
    <Grid item xs={9} mr={6}>
      <Box style={boxStyle} p={4} mt={4}>
        <Box display="flex" mb={3}>
          <Typography variant="h6" color="Text/Black">
            {props.factoryFishDemandData?.factory?.name ?? ""}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" mb={1}>
          <Box mr={2}>
            <PlaceIcon style={placeIconStyle} />
          </Box>
          <Box display="flex" maxWidth="736px">
            <Link
              component="button"
              variant="body1"
              color="Other/Info"
              style={linkStyle}
              onClick={props.handleAddressClick}
            >
              {props.factoryAddress ?? ""}
            </Link>
          </Box>
        </Box>
        <Box display="flex" mb={2}>
          <Box mr={2}>
            <TelephoneIcon style={placeIconStyle} />
          </Box>
          <Typography variant="body1" color="Text/Black">
            {formatPhoneNumber(
              props.factoryFishDemandData?.factory?.phoneNumber ?? ""
            )}
          </Typography>
        </Box>
        <Divider mt={3} mb={1} />
        <Box display="flex" mt={2}>
          <Typography variant="caption" color="Text/Black">
            {"หมายเหตุ : "}
            {props.factoryFishDemandData?.factory?.remark ?? "-"}
          </Typography>
        </Box>
      </Box>
    </Grid>
    <Grid item xs={3}>
      <Box style={boxStyle} p={4} mt={4}>
        <Box display="flex" alignItems="center" justifyContent="center" mb={4}>
          <Typography variant="h6" color="Text/Black">
            {props.factoryFishDemandData?.fish?.name ?? ""}
            {" - "}
            {formatDate(
              props.factoryFishDemandData?.fishDeliverDate,
              "dd/MM/yyyy"
            )}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" mb={4}>
          <Typography variant="h1" color="Other/Info">
            {toCurrency(props.factoryFishDemandData?.maxPurchaseVolume ?? 0, {
              minimumFractionDigits: 0,
            })}
          </Typography>
          <Typography variant="caption" color="Text/Dark Grey" ml={1} mt={3}>
            {"กก."}
          </Typography>
        </Box>
        <Divider mt={5} mb={1} />
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Typography variant="caption" color="Text/Dark Grey">
            ปริมาณขั้นต่ำ :
          </Typography>
          <Typography variant="caption" color="Text/Dark Grey">
            {toCurrency(props.factoryFishDemandData?.minPurchaseVolume ?? 0, {
              minimumFractionDigits: 0,
            }) + " kg"}
          </Typography>
        </Box>
      </Box>
    </Grid>
  </Box>
);

const enhancer = compose(
  defaultProps({}),
  withHooks((props, hooks) => {
    const { useCallback } = hooks;
    const { factoryFishDemandData, factoryAddress } = props;

    const handleAddressClick = useCallback(() => {
      window.open(factoryFishDemandData?.factory?.googleMapLink);
    }, [factoryFishDemandData]);

    return { factoryFishDemandData, factoryAddress, handleAddressClick };
  })
);

export default enhancer(FactoryDemandPanel);

export const fishDepreciationList = [
  {
    label: "เกรด A",
    value: "A",
  },
  {
    label: "เกรด B",
    value: "B",
  },
  {
    label: "เกรด C",
    value: "C",
  },
  {
    label: "เกรด D",
    value: "D",
  },
];

import { compose, withHooks } from "enhancers";
import { PageContent } from "layouts";
import { Box, Table, Typography, Modal } from "components";
import { formatDate, gql, paths, toCurrency } from "utils/helper";
import { format, parseISO, parse } from "date-fns";
import { ReactComponent as AddIcon } from "assets/icon/add-icon.svg";
import { ReactComponent as TrashIcon } from "assets/icon/trash_icon.svg";
import { ReactComponent as WarningAmber } from "assets/icon/warning_amber.svg";

const FactoryOrderScheduleIndexPage = (props) => (
  <PageContent
    title="จัดการความต้องการซื้อ"
    breadcrumbs={props.breadcrumbs}
    pageActions={props.pageActions}
  >
    <Box width="100%" mb={-6}>
      <Typography variant="h4" mb={4}>
        รายการความต้องการซื้อ
      </Typography>
      <Table
        columns={props.columns}
        rows={props.tableData}
        loading={props.loading}
        density="compact"
        autoHeight
        disableSelectionOnClick
        autoDetectInitialFilterOnUrlQueryParams
      />
    </Box>
  </PageContent>
);

export const API = {
  FETCH_FACTORY_FISH_DEMANDS: gql`
    query FETCH_FACTORY_FISH_DEMANDS {
      factoryFishDemands {
        id
        code
        purchasePrice
        minPurchaseVolume
        maxPurchaseVolume
        fishDeliverDate
        isMatched
        canDelete
        factory {
          name
        }
        fish {
          name
        }
      }
    }
  `,
  DELETE_FACTORY_ORDER_SCHEDULE: gql`
    mutation DELETE_FACTORY_ORDER_SCHEDULE($id: String!) {
      deleteFactoryOrderSchedule(input: { id: $id }) {
        factorySchedules {
          code
        }
      }
    }
  `,
};

const enhancer = compose(
  withHooks((props, hooks) => {
    const { useMemo, useCallback, useQuery, useEffect, useMutation } = hooks;
    const { loading, data, error, refetch } = useQuery(
      API.FETCH_FACTORY_FISH_DEMANDS,
      {
        variables: {
          sorts: [{ field: "code", sort: "asc" }],
        },
      }
    );

    const [deleteFactoryOrderSchedule] = useMutation(
      API.DELETE_FACTORY_ORDER_SCHEDULE
    );

    useEffect(() => {
      refetch();
    }, [refetch]);

    const deleteFactoryOrderScheduleHandler = useCallback(
      (id, params) => {
        const {
          fishName,
          factoryName,
          maxPurchaseVolume,
          fishDeliverDate,
          isMatched,
        } = params || {};

        Modal.open({
          title: "ลบความต้องการซื้อ",
          children: (
            <Box>
              {isMatched && (
                <Box
                  display="flex"
                  padding={2}
                  style={{ backgroundColor: "#FFF4E5" }}
                  mb={6}
                  flexDirection="row"
                  alignItems="center"
                >
                  <Box
                    mr={2}
                    mt={1}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <WarningAmber />
                  </Box>
                  <Typography color="#FF9800">
                    ถ้าลบรายการนี้จะมีบางการจองคิวของชาวประมงที่ถูกปฏิเสธ
                  </Typography>
                </Box>
              )}
              <Typography color="Other/Danger">
                ลบความต้องการซื้อ {fishName} - {factoryName} -{" "}
                {`${toCurrency(maxPurchaseVolume, {
                  minimumFractionDigits: 0,
                })} `}
                กก. - วันที่ {format(parseISO(fishDeliverDate), "dd/MM/yyyy")}{" "}
                ข้อมูลจะไม่สามารถกู้คืนได้
              </Typography>
            </Box>
          ),
          cancelButtonLabel: "ปิด",
          okButtonLabel: "ลบข้อมูล",
          onOk: async ({ close }) => {
            await deleteFactoryOrderSchedule({ variables: { id: id } });
            refetch();
            close();
          },
        });
      },
      [deleteFactoryOrderSchedule, refetch]
    );

    const initialTableData = useMemo(() => {
      return [];
    }, []);

    const breadcrumbs = useMemo(() => {
      return [
        { path: paths.homePath(), label: "หน้าแรก" },
        { path: null, label: "จัดการความต้องการซื้อ" },
      ];
    }, []);

    const pageActions = useMemo(() => {
      return [
        {
          children: "สร้างความต้องการซื้อ",
          startIcon: <AddIcon />,
          onClick: () => paths.factoryOrderScheduleNewPath().push(),
          color: "primary",
        },
      ];
    }, []);

    const columns = useMemo(() => {
      return [
        { width: 150, field: "code", headerName: "รหัส" },
        { width: 350, field: "factoryName", headerName: "ชื่อโรงงาน" },
        {
          width: 170,
          field: "fishName",
          headerName: "ชื่อปลา",
        },
        {
          width: 190,
          type: "currency",
          field: "purchasePrice",
          headerName: "ราคารับซื้อ/กก.",
        },
        {
          width: 200,
          type: "currency",
          field: "minPurchaseVolume",
          headerName: "ปริมาณขั้นต่ำ",
        },
        {
          width: 200,
          type: "currency",
          field: "maxPurchaseVolume",
          headerName: "ปริมาณที่ต้องการ",
        },
        {
          width: 150,
          field: "fishDeliverDate",
          headerName: "วันที่ส่งปลา",
          type: "dateOnly",
        },
        {
          width: 40,
          field: "actions",
          headerName: " ",
          type: "actions",
          filterable: false,
          sortable: false,
        },
      ];
    }, []);

    const tableData = useMemo(() => {
      if (loading || error) {
        return initialTableData;
      }

      return data.factoryFishDemands.map((factoryFishDemand) => {
        const {
          id,
          code,
          factory,
          fish,
          purchasePrice,
          minPurchaseVolume,
          maxPurchaseVolume,
          fishDeliverDate,
          isMatched,
          canDelete,
        } = factoryFishDemand;

        const { name: factoryName } = factory || {};
        const { name: fishName } = fish || {};

        const deleteParams = {
          factoryName,
          fishName,
          maxPurchaseVolume,
          fishDeliverDate,
          isMatched,
        };

        return {
          id,
          code,
          factoryName,
          fishName,
          purchasePrice,
          minPurchaseVolume,
          maxPurchaseVolume,
          // fishDeliverDate: formatDate(fishDeliverDate, "dd/MM/yyyy"),
          fishDeliverDate: parse(
            formatDate(fishDeliverDate, "dd/MM/yyyy"),
            "dd/MM/yyyy",
            new Date()
          ),
          actions: canDelete
            ? [
                {
                  Icon: TrashIcon,
                  onClick: () =>
                    deleteFactoryOrderScheduleHandler(id, deleteParams),
                },
              ]
            : [],
        };
      });
    }, [
      loading,
      data,
      error,
      deleteFactoryOrderScheduleHandler,
      initialTableData,
    ]);

    return {
      tableData,
      rowCount: data?.factoryFishDemands?.rowCount ?? 0,
      loading,
      breadcrumbs,
      pageActions,
      columns,
    };
  })
);

export default enhancer(FactoryOrderScheduleIndexPage);

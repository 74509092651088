import { compose, withFormik, withHooks, defaultProps } from "enhancers";
import {
  Box,
  Grid,
  Field,
  TextField,
  Select,
  Typography,
  RadioGroup,
  Hidden,
} from "components";
import {
  subDistrictOptions,
  districtOptions,
  provinceOptions,
  zipCodeOptions,
} from "./helper";
import DeliveryAddressOnModal from "./DeliveryAddressOnModal";
import { isNil } from "lodash";

const AddressComponent = (props) => (
  <Box {...props}>
    {/* <Hidden when={props.withFishermanInfo}>
      <Box display="flex" alignItems="center" fullWidth mb={6}>
        <Box display="flex" flex={1} flexDirection="row" fullWidth>
          <Typography variant="h4" display="inline">
            {props.title}
          </Typography>
          <Typography variant="h4" color="#F34336" display="inline">
            {props.requiredSymbol}
          </Typography>
        </Box>
        <Hidden when={!props.withTaxInfo || props.withoutTaxType}>
          <Field
            component={RadioGroup}
            name="taxPayerType"
            options={props.taxPayerTypeOptions}
            row
            className="tax_payer_type_radio_group"
            disabled={props.disabled}
          />
        </Hidden>
        <Hidden when={!props.withFishermanInfo}>
          <Typography variant="body1" display="inline">
            สถานะ
          </Typography>
          <Field
            component={RadioGroup}
            name="fishermanStatus"
            options={props.fishermanAvailableStatus}
            row
            className="tax_payer_type_radio_group"
            disabled={props.disabled}
          />
        </Hidden>
      </Box>
    </Hidden> */}
    <Grid container spacing={6}>
      {/* <Hidden when={!props.withFishermanInfo}>
        <Grid item xs={12}>
          <Field
            component={TextField}
            name="name"
            type="text"
            label="ชื่อ-นามสกุล"
            fullWidth
            disabled={props.disabled}
            required
          />
        </Grid>
      </Hidden> */}
      {/* <Hidden
        when={props.taxPayerType !== "personal" || props.withFishermanInfo}
      >
        <Grid item xs={6}>
          <Field
            component={TextField}
            name="firstName"
            type="text"
            label="ชื่อ"
            fullWidth
            disabled={props.disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            component={TextField}
            name="lastName"
            type="text"
            label="นามสกุล"
            fullWidth
            disabled={props.disabled}
          />
        </Grid>
      </Hidden>
      <Hidden
        when={props.taxPayerType !== "corporation" || props.withFishermanInfo}
      >
        <Grid item xs={6}>
          <Field
            component={TextField}
            name="companyName"
            type="text"
            label={props.companyNameLabel ?? "ชื่อนิติบุคคล"}
            fullWidth
            disabled={props.disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <Hidden when={props.noBranch}>
            <Field
              component={TextField}
              name="branchName"
              type="text"
              label="สำนักงาน/สาขา"
              fullWidth
              disabled={props.disabled}
            />
          </Hidden>
        </Grid>
      </Hidden>
      <Hidden when={props.withFishermanInfo}>
        <Grid item xs={6}>
          <Field
            component={TextField.PhoneNumber}
            name="phoneNumber"
            label="เบอร์โทรศัพท์"
            fullWidth
            disabled={props.disabled}
          />
        </Grid>
      </Hidden> */}
      {/* <Hidden when={!props.hasGmap}>
        <Grid item xs={6}>
          <Field
            component={TextField}
            name="gmapUrl"
            label="แผนที่"
            fullWidth
            disabled={props.disabled}
          />
        </Grid>
      </Hidden>

      <Hidden when={!props.withTaxInfo}>
        <Grid item xs={6}>
          <Field
            component={TextField.TaxId}
            name="taxId"
            label="เลขประจำตัวผู้เสียภาษี"
            fullWidth
            disabled={props.disabled}
          />
        </Grid>
      </Hidden> */}

      <Grid item xs={12}>
        <Field
          component={TextField}
          name="address"
          type="text"
          label="ที่อยู่"
          fullWidth
          required
          disabled={props.disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={Select}
          name="subDistrict"
          type="text"
          label="แขวง/ตำบล"
          options={subDistrictOptions}
          transformDisplay={props.transformDisplaySubDistrict}
          freeSolo
          fullWidth
          fuse
          required
          disabled={props.disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={Select}
          name="district"
          type="text"
          label="เขต/อำเภอ"
          options={districtOptions}
          freeSolo
          fullWidth
          required
          disabled={props.disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={Select}
          name="province"
          type="text"
          label="จังหวัด"
          options={provinceOptions}
          fullWidth
          required
          disabled={props.disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={Select}
          name="zipCode"
          type="text"
          label="รหัสไปรษณีย์"
          options={zipCodeOptions}
          freeSolo
          fullWidth
          required
          disabled={props.disabled}
        />
      </Grid>
      {/* <Hidden when={props.withFishermanInfo}>
        <Grid item xs={12}>
          <Field
            component={TextField}
            name="remark"
            type="text"
            label="หมายเหตุ"
            fullWidth
            disabled={props.disabled}
          />
        </Grid>
      </Hidden> */}
    </Grid>
  </Box>
);

const enhancer = compose(
  defaultProps({
    taxPayerTypeOptions: [
      { label: "บุคคลธรรมดา", value: "personal" },
      { label: "นิติบุคคล", value: "corporation" },
    ],
    fishermanAvailableStatus: [
      { label: "ใช้งาน", value: "true" },
      { label: "ไม่ใช้งาน", value: "false" },
    ],
  }),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props) => ({
      // addressType: props.type,
      // taxPayerType: "personal",
      // fishermanStatus: "true",
      // firstName: null,
      // lastName: null,
      // phoneNumber: null,
      // taxId: null,
      // address: null,
      // subDistrict: null,
      // district: null,
      // province: null,
      // zipCode: null,
      ...props.field.value,
    }),
  }),
  withHooks((props, hooks) => {
    const { useCallback, useEffect, useMemo } = hooks;
    const {
      form,
      field,
      values,
      setFieldValue,
      setValues,
      type,
      disabled,
      title,
      ...rest
    } = props;

    const outerSetFieldValue = form.setFieldValue;
    const outerFieldName = field.name;
    const onChange = useCallback(
      (address) => {
        outerSetFieldValue(outerFieldName, address);
      },
      [outerSetFieldValue, outerFieldName]
    );

    useEffect(() => {
      if (!`${values.subDistrict}`.includes(" » ")) {
        onChange(values);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(values)]);

    const currentSubDistrict = values.subDistrict;

    useEffect(() => {
      if (currentSubDistrict) {
        const [
          subDistrict,
          district,
          province,
          zipCode,
        ] = currentSubDistrict.split(" » ");

        const hasNestedField = !isNil(zipCode);
        if (hasNestedField) {
          setFieldValue("subDistrict", subDistrict);
          setFieldValue("district", district);
          setFieldValue("province", province);
          setFieldValue("zipCode", zipCode);
        }
      }
    }, [currentSubDistrict, setFieldValue]);

    const transformDisplaySubDistrict = useCallback((label) => {
      return "XXXXX-" + label;
    }, []);

    const requiredSymbol = useMemo(() => {
      const symbol = title?.charAt(title.length - 1);
      return symbol === "*" ? symbol : null;
    }, [title]);

    const customTitle = useMemo(() => {
      return requiredSymbol ? title?.slice(0, -1) : title;
    }, [requiredSymbol, title]);

    const taxPayerType = values.taxPayerType;
    const fishermanStatus = values.fishermanStatus;
    const withTaxInfo = type === "billing";
    // const withFishermanInfo = type === "fisherman";

    return {
      requiredSymbol,
      title: customTitle,
      taxPayerType,
      fishermanStatus,
      withTaxInfo,
      // withFishermanInfo,
      transformDisplaySubDistrict,
      disabled,
      ...rest,
    };
  })
);

const Address = enhancer(AddressComponent);
const DeliveryOnModal = enhancer(DeliveryAddressOnModal);

Address.Billing = defaultProps({ type: "billing" })(Address);
// Address.Fisherman = defaultProps({ type: "fisherman" })(Address);
Address.Delivery = defaultProps({ type: "delivery" })(Address);
Address.DeliveryOnModal = defaultProps({ type: "delivery" })(DeliveryOnModal);

export default Address;

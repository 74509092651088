import React from "react";
import { compose, withHooks } from "enhancers";
import { PageContent } from "layouts";
import { Box, Table, Typography } from "components";
import { gql, paths } from "utils/helper";
import { ReactComponent as AddIcon } from "assets/icon/add-icon.svg";
import { ReactComponent as EditIcon } from "assets/icon/edit.svg";

const FactoryIndexPage = (props) => (
  <PageContent
    title="จัดการโรงงาน"
    breadcrumbs={props.breadcrumbs}
    pageActions={props.pageActions}
  >
    <Box width="100%" mb={-6}>
      <Typography variant="h4" mb={4}>
        รายการโรงงาน
      </Typography>
      <Table
        columns={[
          { width: 150, field: "code", headerName: "รหัสโรงงาน" },
          { width: 175, field: "name", headerName: "ชื่อ" },
          {
            width: 170,
            field: "phoneNumber",
            headerName: "เบอร์โทรศัพท์",
            type: "phoneNumber",
          },
          {
            width: 350,
            field: "address",
            headerName: "ที่อยู่",
          },
          {
            width: 120,
            type: "tag",
            field: "status",
            headerName: "สถานะ",
          },
          {
            width: 55,
            field: "edit",
            headerName: " ",
            type: "actions",
            filterable: false,
            sortable: false,
          },
        ]}
        rows={props.tableData}
        loading={props.loading}
        density="compact"
        autoHeight
        disableSelectionOnClick
        autoDetectInitialFilterOnUrlQueryParams
      />
    </Box>
  </PageContent>
);

export const API = {
  FETCH_FACTORIES: gql`
    query FETCH_FACTORIES {
      factories {
        id
        code
        googleMapLink
        name
        phoneNumber
        remark
        availableStatus
        address {
          address
          district
          subDistrict
          province
          zipCode
        }
      }
    }
  `,
};

const enhancer = compose(
  withHooks((props, hooks) => {
    const { useMemo, useQuery, useEffect } = hooks;
    const { loading, data, error, refetch } = useQuery(API.FETCH_FACTORIES, {
      variables: {
        sorts: [{ field: "code", sort: "asc" }],
      },
    });

    const breadcrumbs = useMemo(() => {
      return [
        { path: paths.homePath(), label: "หน้าแรก" },
        { path: null, label: "จัดการโรงงาน" },
      ];
    }, []);

    const pageActions = useMemo(() => {
      return [
        {
          children: "เพิ่มโรงงาน",
          startIcon: <AddIcon />,
          onClick: () => paths.factoryNewPath().push(),
          color: "primary",
        },
      ];
    }, []);

    useEffect(() => {
      refetch();
    }, [refetch]);

    const tableData = useMemo(() => {
      if (loading || error) {
        return [];
      }

      return data.factories.map((factory) => {
        const {
          id,
          availableStatus,
          code,
          googleMapLink,
          name,
          phoneNumber,
          remark,
          address,
        } = factory;
        const addressInfo = `${address.address} ${address.district} ${address.subDistrict} ${address.province} ${address.zipCode}`;

        return {
          id,
          code,
          googleMapLink,
          name,
          phoneNumber,
          remark,
          address: addressInfo,
          status: availableStatus ? "ใช้งาน" : "ไม่ใช้งาน",
          edit: [
            { Icon: EditIcon, onClick: () => paths.factoryEditPath(id).push() },
          ],
        };
      });
    }, [loading, data, error]);

    return {
      tableData,
      rowCount: data?.fishermen?.rowCount ?? 0,
      loading,
      refetch,
      breadcrumbs,
      pageActions,
    };
  })
);

export default enhancer(FactoryIndexPage);

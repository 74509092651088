import { compose, withFormik, withHooks, defaultProps } from "enhancers";
import { PageContent } from "layouts";
import { Modal, Typography } from "components";
import {
  gql,
  notifyError,
  getErrorMessage,
  notifySuccess,
  paths,
  toCurrency,
  cleanTypename,
} from "utils/helper";
import { find } from "lodash";
import { BookingForm } from "./new";

const BookingEditPage = (props) => (
  <PageContent
    title={props.title}
    breadcrumbs={props.breadcrumbs}
    status={props.statusWithColor}
  >
    <BookingForm {...props} />
  </PageContent>
);

const API = {
  FETCH_FISHERMEN: gql`
    query FETCH_FISHERMEN {
      fishermen {
        id
        code
        name
        phoneNumber
        lineId
        remark
        googleMapLink
        availableStatus
        address {
          address
          district
          subDistrict
          province
          zipCode
        }
      }
    }
  `,
  FETCH_BOOKING: gql`
    query FETCH_BOOKING($id: ID!) {
      booking(id: $id) {
        id
        code
        amount
        status
        fishDemandVolume
        minPrice
        maxPrice
        maxPurchasePrice
        receiveDate
        fisherman {
          id
          code
          name
        }
        fish {
          id
          name
        }
      }
    }
  `,
  CANCEL_BOOKING: gql`
    mutation CANCEL_BOOKING($id: ID!) {
      cancelBooking(input: { id: $id }) {
        booking {
          id
          code
          status
        }
      }
    }
  `,
};

const enhancer = compose(
  defaultProps({}),
  withFormik({
    mapPropsToValues: () => ({
      fisherman: "",
    }),
  }),
  withHooks((props, hooks) => {
    const {
      useMemo,
      useMutation,
      useCallback,
      useEffect,
      useQuery,
      useState,
      useParams,
    } = hooks;
    const { values, setValues } = props;
    const { fisherman, reserveVolume, fishDemandData, status, amount } = values;

    const { id } = useParams();
    const { loading, data, error, refetch } = useQuery(API.FETCH_BOOKING, {
      variables: { id },
    });
    const fetchFishermen = useQuery(API.FETCH_FISHERMEN);

    const [cancelBooking] = useMutation(API.CANCEL_BOOKING);
    const [address, setAddress] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [remark, setRemark] = useState("");

    const title = `รายละเอียดการจองคิว`;
    const breadcrumbs = useMemo(() => {
      return [
        { path: paths.homePath(), label: "หน้าแรก" },
        { path: paths.bookingsPath(), label: "จัดการการจองคิว" },
        { path: null, label: title },
      ];
    }, [title]);

    useEffect(() => {
      refetch();
    }, [refetch]);

    const booking = useMemo(() => {
      if (loading) {
        return null;
      }
      if (error) {
        const message = getErrorMessage(error);
        notifyError(message);
        paths.bookingsPath().push();
        return null;
      }

      return data.booking;
    }, [loading, data, error]);

    const fishermanOptions = useMemo(() => {
      if (fetchFishermen.loading || fetchFishermen.error) {
        return [];
      }
      return fetchFishermen.data.fishermen.map((fisherman) => {
        const { id, code, name, phoneNumber, remark, address } = fisherman;

        const addressInfo = address
          ? `${address?.address} ${address?.district} ${address?.subDistrict} ${address?.province} ${address?.zipCode}`
          : "-";
        const label = `[${code}] ${name}`;

        return {
          value: id,
          label,
          phoneNumber,
          remark: remark ? remark : "-",
          address: addressInfo,
        };
      });
    }, [fetchFishermen]);

    useEffect(() => {
      if (booking) {
        const values = cleanTypename(booking);

        const {
          fisherman,
          fish,
          amount,
          receiveDate,
          fishDemandVolume,
          minPrice,
          maxPrice,
        } = values;

        const purchasePrice =
          minPrice === maxPrice
            ? `${toCurrency(minPrice)}`
            : `${toCurrency(minPrice)} - ${toCurrency(maxPrice)}`;

        const fishermanId = fisherman.id;
        const fishDemandData = {
          fishId: fish.id,
          fishName: fish.name,
          purchaseVolume: fishDemandVolume || 0,
          purchasePrice,
          receiveDate,
          minPurchasePrice: minPrice || 0,
          maxPurchasePrice: maxPrice || 0,
        };

        setValues({
          ...values,
          fisherman: fishermanId,
          fishDemandData,
          reserveVolume: amount,
        });
      }
    }, [setValues, booking]);

    useEffect(() => {
      if (fisherman) {
        const foundedFisherman = find(fishermanOptions, {
          value: fisherman,
        });

        setAddress(foundedFisherman?.address);
        setPhoneNumber(foundedFisherman?.phoneNumber);
        setRemark(foundedFisherman?.remark);
      }
    }, [fisherman, fishermanOptions, setAddress, setPhoneNumber, setRemark]);

    const onCancelBooking = useCallback(async () => {
      Modal.open({
        title: "ยกเลิกการจอง",
        children: (
          <Typography color="Other/Danger">
            ยกเลิกการจองคิวหาปลา{fishDemandData.fishName} {toCurrency(amount)}{" "}
            กก. มูลค่า {toCurrency(booking?.maxPurchasePrice)} บาท
          </Typography>
        ),
        cancelButtonLabel: "ปิด",
        okButtonLabel: "ยกเลิกการจอง",
        onOk: async ({ close }) => {
          try {
            close();
            await cancelBooking({
              variables: { id },
            });
            await paths.bookingsPath().push();
            notifySuccess("แก้ไขข้อมูลสำเร็จ");
          } catch (e) {}
        },
      });
    }, [fishDemandData, amount, cancelBooking, id, booking]);

    const totalPrice = useMemo(() => {
      let result = "0";
      let minTotalPrice = 0;
      let maxTotalPrice = 0;

      if (fishDemandData && reserveVolume) {
        minTotalPrice = reserveVolume * fishDemandData.minPurchasePrice;
        maxTotalPrice = reserveVolume * fishDemandData.maxPurchasePrice;

        result =
          minTotalPrice === maxTotalPrice
            ? `${toCurrency(minTotalPrice)}`
            : `${toCurrency(minTotalPrice)} - ${toCurrency(maxTotalPrice)}`;
      }

      return result;
    }, [reserveVolume, fishDemandData]);

    const statusWithColor = useMemo(() => {
      let color = "767676";
      let title = "รอยืนยัน";

      switch (status) {
        case "waiting_for_payment":
          color = "#FF9800";
          title = "รอโอนเงิน";
          break;
        case "waiting_for_shipping":
          color = "#376FD0";
          title = "รอรับปลา";
          break;
        case "waiting_confirm":
          color = "#767676";
          title = "รอยืนยัน";
          break;
        case "completed":
          color = "#4CAF4F";
          title = "สำเร็จ";
          break;
        case "canceled":
          color = "#F34336";
          title = "ยกเลิก";
          break;
        default:
          color = "#767676";
          title = "รอยืนยัน";
          break;
      }

      const result = {
        color,
        title,
      };

      return result;
    }, [status]);

    const goToBooking = useCallback(() => {
      paths.bookingsPath().push();
    }, []);

    return {
      title,
      breadcrumbs,
      cancelButtonLabel: "ยกเลิก",
      submitButtonLabel: "สร้างการจองคิว",

      fishermanOptions,
      address,
      phoneNumber,
      remark,
      fishDemandData,
      totalPrice,
      onCancelBooking,
      isView: true,
      isShowCancelButton: status === "waiting_for_confirm",
      statusWithColor,
      goToBooking,
    };
  })
);

export default enhancer(BookingEditPage);

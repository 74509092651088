import React from "react";
import { compose, withHooks } from "enhancers";
import { Button } from "components";

const BrowseButton = ({
  getRootProps,
  getInputProps,
  children,
  ...buttonProps
}) => (
  <Button {...buttonProps} {...getRootProps()}>
    <input autoComplete="off" value={undefined} {...getInputProps()} />
    {children}
  </Button>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const { onBrowse, accept, multiple = false, ...rest } = props;
    const { useState, useCallback, useDropzone } = hooks;
    const [loading, setLoading] = useState(false);

    const onDrop = useCallback(
      async (files) => {
        if (onBrowse) {
          setLoading(true);
          try {
            await onBrowse(files[0]);
          } catch (e) {}
          setLoading(false);
        }
        setLoading(false);
      },
      [onBrowse]
    );

    const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      accept,
      multiple,
    });

    return {
      getRootProps,
      getInputProps,
      ...rest,
      loading,
    };
  })
);

export default enhancer(BrowseButton);

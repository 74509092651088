import {
  compose,
  withFormik,
  withHooks,
  defaultProps,
  withStores,
} from "enhancers";
import { PageContent } from "layouts";
import {
  Box,
  Grid,
  Typography,
  Form,
  Divider,
  Button,
  Hidden,
  Modal,
  IconButton,
  Chip,
  Link,
  Field,
  FieldArray,
  SummaryPrice,
} from "components";
import {
  gql,
  notify,
  paths,
  formatPhoneNumber,
  formatDate,
  getErrorMessage,
  notifyError,
  notifySuccess,
  cleanTypename,
  toCurrency,
} from "utils/helper";
import { ReactComponent as WarningAmberIcon } from "assets/icon/warning_amber.svg";
import DeliverFishToFactoryModal from "./DeliverFishToFactoryModal";
import AssignShipper from "./AssignShipper";
import {
  PlaceOutlined as PlaceIcon,
  LocalPhoneOutlined as TelephoneIcon,
  EditOutlined as EditIcon,
} from "@material-ui/icons";
import FileImage from "components/advance/FileImage";
import FishSupplies from "./FishSupplies";
import { compact, isEmpty, maxBy, some, map, cloneDeep } from "lodash";
import FactoryDemandPanel from "./edit/FactoryDemandPanel";

const placeIconStyle = {
  color: "#A1A1A1",
  width: 16,
  height: 16,
};

const linkStyle = {
  textDecoration: "underline",
  color: "#376FD0",
};

const OrderEditPage = (props) => (
  <PageContent
    title={props.title}
    status={props.orderStatus}
    breadcrumbs={props.breadcrumbs}
  >
    <Form>
      <Typography variant="h4" mb={2}>
        ข้อมูลคำสั่งซื้อจากโรงงาน
      </Typography>

      <FactoryDemandPanel
        factoryFishDemandData={props.factoryFishDemandData}
        factoryAddress={props.factoryAddress}
      />

      <Box display="flex" alignItems="center" fullWidth mt={10}></Box>

      <Hidden when={!props.isFishSupplyListEmpty}>
        <Box style={{ background: "#F6F9FC", borderRadius: 8 }} p={8} mt={4}>
          <Box display="flex" justifyContent="center">
            <WarningAmberIcon fontSize="large" />
            <Typography variant="body1" color="Text/Dark Grey" ml={1}>
              ไม่มีข้อมูลรายการจองคิวจากชาวประมง กรุณาแก้ไขลำดับการรับปลา
            </Typography>
          </Box>
        </Box>
      </Hidden>

      <FieldArray
        name="orderSupplies"
        component={FishSupplies}
        type={props.type}
        orderStatus={props.orderStatus}
        factoryFishDemandData={props.factoryFishDemandData}
        totalFishDemandAmount={props.totalFishDemandAmount}
        onSelect={props.handleSelectNewBooking}
        onFishReceived={props.handleFishReceive}
      />

      <Hidden when={props.type === "Shipper"}>
        <Field
          name="shipperId"
          component={AssignShipper}
          shipperPerWeight={props.shipperPerWeight}
          orderStatus={props.orderStatus}
          fast={false}
        />
      </Hidden>

      <Hidden
        when={
          props.orderStatus.title === "รอยืนยัน" ||
          props.orderStatus.title === "ยกเลิก"
        }
      >
        <Typography variant="h4" mb={2} mt={10}>
          การจัดส่งปลาให้โรงงาน
        </Typography>
        <Box mb={2}>
          <Box display="flex">
            <Grid item xs={8}>
              <Box p={4} mt={2} display="flex" justifyContent="space-between">
                <Box>
                  <Box display="flex" mb={3}>
                    <Typography variant="h6" color="Text/Black">
                      {props.factoryFishDemandData?.factory?.name}
                    </Typography>
                  </Box>
                  <Box display="flex" mb={1}>
                    <Box mr={2}>
                      <PlaceIcon style={placeIconStyle} />
                    </Box>
                    <Link
                      variant="body1"
                      color="Other/Info"
                      mr={2}
                      style={linkStyle}
                      onClick={props.handleAddressClick}
                    >
                      {props.factoryAddress}
                    </Link>
                  </Box>
                  <Box display="flex" mb={2}>
                    <Box mr={2}>
                      <TelephoneIcon style={placeIconStyle} />
                    </Box>
                    <Typography variant="body1" color="Text/Black">
                      {formatPhoneNumber(
                        props.factoryFishDemandData?.factory?.phoneNumber
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box>
                <Box mb={8}></Box>
                <Chip
                  size="small"
                  label={props.deliverStatus?.title}
                  mr={1}
                  style={{
                    fontSize: 12,
                    lineHeight: 14,
                    color: "white",
                    backgroundColor: `${props.deliverStatus?.color}`,
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={3}>
              <Box
                p={4}
                display="flex"
                alignItems="center"
                justifyContent="end"
              >
                <Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Hidden when={false}>
                      <Typography
                        variant="h1"
                        color={
                          props.deliverStatus?.title === "กำลังดำเนินการ"
                            ? "Other/Info"
                            : "Other/Success"
                        }
                      >
                        {props.deliverStatus?.title === "กำลังดำเนินการ"
                          ? toCurrency(props.totalFishDemandAmount, {
                              minimumFractionDigits: 0,
                            })
                          : toCurrency(props.totalFishDeliveryAmount, {
                              minimumFractionDigits: 0,
                            })}
                      </Typography>
                    </Hidden>
                    <Typography
                      variant="caption"
                      color="Text/Dark Grey"
                      ml={1}
                      mt={3}
                    >
                      {"กก."}
                    </Typography>
                  </Box>
                  <Hidden when={props.deliverStatus.title === "กำลังดำเนินการ"}>
                    <Typography variant="caption" color="Text/Black">
                      {props.type === "Shipper"
                        ? "เกรด " + props.grade
                        : "เกรด " +
                          props.grade +
                          " • " +
                          toCurrency(props.netProfit, {
                            minimumFractionDigits: 0,
                          }) +
                          " บาท"}
                    </Typography>
                  </Hidden>
                </Box>

                <IconButton onClick={props.openDeliverFishToFactoryModal}>
                  <EditIcon style={{ color: "#000000", width: 20 }} />
                </IconButton>
              </Box>
            </Grid>
          </Box>
          <Hidden when={false}>
            <Box display="flex" justifyContent="center" mb={3}>
              <FieldArray component={FileImage} name="fishDeliverImages" />
            </Box>
            <Box display="flex" justifyContent="center" mb={3}>
              <FieldArray component={FileImage} name="paymentSlipImages" />
            </Box>
          </Hidden>
          <Divider mt={0} mb={1} />
        </Box>
      </Hidden>

      <Hidden when={props.type === "Shipper"}>
        <SummaryPrice
          orderSupplies={props.orderSupplies}
          orderDemand={props.orderDemand}
          onResult={props.setSummaryData}
          type={props.type}
        />
        <Divider mt={10} />
      </Hidden>

      <Box display="flex" alignItems="center" fullWidth mb={6}>
        <Hidden when={props.orderStatus.title !== "รอยืนยัน"}>
          <Button
            onClick={props.handleCancelOrderClicked}
            width={150}
            mt={6}
            style={{ color: "red" }}
          >
            ยกเลิกคำสั่งซื้อ
          </Button>
        </Hidden>

        <Box display="flex" flex={1} flexDirection="row" fullWidth></Box>
        <Button color="" mt={6} mr={4} width={150} onClick={props.cancel}>
          {props.cancelButtonLabel}
        </Button>
        <Hidden when={props.orderStatus.title === "ยกเลิก"}>
          <Button onClick={props.submit} color="primary" width={150} mt={6}>
            {props.submitButtonLabel}
          </Button>
        </Hidden>
      </Box>
    </Form>
  </PageContent>
);

const API = {
  FETCH_ORDER: gql`
    query FETCH_ORDER($id: ID!) {
      order(id: $id) {
        id
        code
        shipperId
        purchasePrice
        fuelPrice
        icePrice
        wage
        sellingPrice
        netProfit
        orderDemands {
          id
          gradeAFishDeliveredWeight
          gradeBFishDeliveredWeight
          gradeCFishDeliveredWeight
          gradeDFishDeliveredWeight
          images
          fishDeliverImages
          paymentSlipImages
          factoryFishDemand {
            id
            factory {
              id
              name
              phoneNumber
              remark
              googleMapLink
              address {
                address
                district
                subDistrict
                province
                zipCode
              }
              fishDepreciation {
                fishId
                gradeADepreciationPct
                gradeBDepreciationPct
                gradeCDepreciationPct
                gradeDDepreciationPct
                purchasePrice
              }
            }
            fish {
              id
              name
              factoryPurchasePricePct
            }
            fishDeliverDate
            purchasePrice
            minPurchaseVolume
            maxPurchaseVolume
          }
          status
        }
        orderSupplies {
          id
          fishReceivedWeight
          fishReceivedGrade
          receivedSequence
          status
          images
          netProfit
          booking {
            id
            amount
            fisherman {
              id
              name
              phoneNumber
              remark
              googleMapLink
              address {
                address
                district
                subDistrict
                province
                zipCode
              }
            }
          }
        }
        status
      }
    }
  `,
  FETCH_SETTING: gql`
    query FETCH_SETTING {
      setting {
        carryOnWeightPerEmployee
      }
    }
  `,
  UPDATE_ORDER: gql`
    mutation UPDATE_ORDER(
      $id: String!
      $shipperId: String
      $orderDemands: OrderDemandArguments
      $orderSupplies: [OrderSupplyArguments!]
      $status: String
      $purchasePrice: Float
      $fuelPrice: Float
      $icePrice: Float
      $wage: Float
      $sellingPrice: Float
      $netProfit: Float
    ) {
      updateOrder(
        input: {
          id: $id
          shipperId: $shipperId
          orderDemands: $orderDemands
          orderSupplies: $orderSupplies
          status: $status
          purchasePrice: $purchasePrice
          fuelPrice: $fuelPrice
          icePrice: $icePrice
          wage: $wage
          sellingPrice: $sellingPrice
          netProfit: $netProfit
        }
      ) {
        order {
          id
        }
      }
    }
  `,
  CANCEL_ORDER: gql`
    mutation CANCEL_ORDER(
      $id: String!
      $status: String
      $orderSupplies: [OrderSupplyArguments!]
    ) {
      cancelOrder(
        input: { id: $id, status: $status, orderSupplies: $orderSupplies }
      ) {
        order {
          id
          status
        }
      }
    }
  `,
  CALCULATE_SUMMARY: gql`
    mutation CALCULATE_SUMMARY($factoryFishDemandId: ID!, $bookingIds: [ID!]!) {
      calculateSummary(
        input: {
          factoryFishDemandId: $factoryFishDemandId
          bookingIds: $bookingIds
        }
      ) {
        summary {
          purchasePrice
          fuelPrice
          icePrice
          wage
          sellingPrice
          netProfit
        }
      }
    }
  `,
};

const enhancer = compose(
  withStores((stores) => ({
    currentUser: stores.appStore.currentUser,
  })),
  defaultProps({}),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props) => {
      const orderDemand = isEmpty(props.orderDemands)
        ? []
        : props.orderDemands[0];
      return {
        shipperId: props.shipperId,
        orderSupplies: props.orderSupplies || [],
        orderDemands: props.orderDemands || [],
        fishDeliverImages: orderDemand?.fishDeliverImages,
        paymentSlipImages: orderDemand?.paymentSlipImages,
      };
    },
  }),
  withHooks((props, hooks) => {
    const {
      useMemo,
      useMutation,
      useCallback,
      useEffect,
      useQuery,
      useState,
      useParams,
    } = hooks;
    const { values, setValues, setPropsToFormikBag, dirty } = props;
    const { id } = useParams();

    const { loading, data, error, refetch } = useQuery(API.FETCH_ORDER, {
      variables: { id },
    });
    const { data: settingData } = useQuery(API.FETCH_SETTING);

    const [updateOrder] = useMutation(API.UPDATE_ORDER);
    const [cancelOrder] = useMutation(API.CANCEL_ORDER);
    const [calculateSummary] = useMutation(API.CALCULATE_SUMMARY);

    const { currentUser } = props;
    const { ownerData } = currentUser ?? {};
    const { type } = ownerData || {};

    const [summaryData, setSummaryData] = useState({});

    const [fishSuppliesData, setFishSuppliesData] = useState([]);

    const order = useMemo(() => {
      if (loading) {
        return {};
      }
      if (error) {
        const message = getErrorMessage(error);
        notifyError(message);
        return {};
      }

      return data.order;
    }, [loading, data, error]);

    const title = order?.code;

    const breadcrumbs = useMemo(() => {
      return [
        { path: paths.homePath(), label: "หน้าแรก" },
        {
          path:
            type === "Shipper" ? paths.shipperOrderPath() : paths.ordersPath(),
          label: "จัดการคำสั่งซื้อ",
        },
        { path: null, label: title },
      ];
    }, [title, type]);

    useEffect(() => {
      if (!isEmpty(order)) {
        const customOrderSupplies = order.orderSupplies?.map((item) => {
          const { booking } = item || {};
          const customOrderSupplyList = {
            amount: booking.amount,
            fisherman: booking.fisherman,
            id: item.id,
            bookingId: booking.id,
            status: item.status,
            fishReceivedWeight: item.fishReceivedWeight,
            fishReceivedGrade: item.fishReceivedGrade,
            netProfit: item.netProfit,
            receivedSequence: item.receivedSequence,
            images: item.images,
          };
          return customOrderSupplyList;
        });

        const customOrderDemands = order.orderDemands?.map((item) => {
          return {
            ...item,
            fishDeliverImages: item.fishDeliverImages,
            paymentSlipImages: item.paymentSlipImages,
          };
        });

        const customOrder = {
          ...order,
          orderSupplies: customOrderSupplies,
          orderDemands: customOrderDemands,
        };

        const values = cleanTypename(customOrder);
        setFishSuppliesData(customOrderSupplies);
        setPropsToFormikBag({ ...values });
      }
    }, [order]);

    const factoryAddress = useMemo(() => {
      const orderDemand =
        order?.orderDemands?.length > 0 ? order?.orderDemands[0] : [];

      const { factoryFishDemand } = orderDemand || {};
      const { factory } = factoryFishDemand || {};
      const { address: factoryAddress } = factory || {};

      return `${factoryAddress?.address ?? ""} ${
        factoryAddress?.district ?? ""
      } ${factoryAddress?.subDistrict ?? ""} ${
        factoryAddress?.province ?? ""
      } ${factoryAddress?.zipCode ?? ""}`;
    }, [order]);

    const factoryFishDemandData = useMemo(() => {
      const { factoryFishDemand } =
        order?.orderDemands?.length > 0 ? order?.orderDemands[0] : [];
      const { fish, maxPurchaseVolume, factory } = factoryFishDemand || {};

      return {
        ...factoryFishDemand,
        factoryId: factory?.id,
        fishId: fish?.id,
        fishName: fish?.name,
        amount: maxPurchaseVolume,
      };
    }, [order]);

    const isFishSupplyListEmpty = useMemo(() => {
      const { orderSupplies } = order;
      return !!orderSupplies?.length === 0;
    }, [order]);

    useEffect(() => {
      refetch();
    }, [refetch]);

    const totalFishDemandAmount = useMemo(() => {
      const sum = fishSuppliesData?.reduce(
        (total, currentItem) => (total = total + currentItem.amount),
        0
      );
      return sum;
    }, [fishSuppliesData]);

    const fishDemandDeliveryData = useMemo(() => {
      const { orderDemands = [] } = values;
      const orderDemand = orderDemands[0] || {};

      if (orderDemand && factoryFishDemandData?.factory) {
        const gradeA = orderDemand.gradeAFishDeliveredWeight ?? 0;
        const gradeB = orderDemand.gradeBFishDeliveredWeight ?? 0;
        const gradeC = orderDemand.gradeCFishDeliveredWeight ?? 0;
        const gradeD = orderDemand.gradeDFishDeliveredWeight ?? 0;
        const amount = gradeA + gradeB + gradeC + gradeD;

        let fishDepreciations = {};

        factoryFishDemandData?.factory?.fishDepreciation.forEach(
          (fishDepreciation) => {
            if (factoryFishDemandData.fish.id === fishDepreciation.fishId) {
              fishDepreciations = fishDepreciation;
            }
          }
        );

        const grades = [];
        if (gradeA > 0) {
          grades.push({ grade: "A", weight: gradeA });
        }

        if (gradeB > 0) {
          grades.push({ grade: "B", weight: gradeB });
        }

        if (gradeC > 0) {
          grades.push({ grade: "C", weight: gradeC });
        }

        if (gradeD > 0) {
          grades.push({ grade: "D", weight: gradeD });
        }

        const mostWeight = maxBy(grades.reverse(), (grade) => grade.weight);

        const netProfit =
          gradeA *
            factoryFishDemandData?.purchasePrice *
            (fishDepreciations?.gradeADepreciationPct / 100) +
          gradeB *
            factoryFishDemandData?.purchasePrice *
            (fishDepreciations?.gradeBDepreciationPct / 100) +
          gradeC *
            factoryFishDemandData?.purchasePrice *
            (fishDepreciations?.gradeCDepreciationPct / 100) +
          gradeD *
            factoryFishDemandData?.purchasePrice *
            (fishDepreciations?.gradeDDepreciationPct / 100);

        return {
          totalFishDeliveryAmount: amount,
          grade: mostWeight?.grade ?? "",
          netProfit: netProfit ?? 0,
        };
      } else {
        return {
          totalFishDeliveryAmount: null,
          grade: null,
          netProfit: null,
        };
      }
    }, [values, factoryFishDemandData]);

    const shipperPerWeight = useMemo(() => {
      return (
        factoryFishDemandData?.maxPurchaseVolume /
        settingData?.setting?.carryOnWeightPerEmployee
      );
    }, [settingData, factoryFishDemandData]);

    const orderStatus = useMemo(() => {
      let status = "";
      let color = "";

      if (
        values.status === "waiting_for_payment" ||
        values.status === "success"
      ) {
        switch (values.status) {
          case "waiting_for_payment":
            status = "รอโอนเงิน";
            color = "#FF9800";
            break;
          case "in_progress":
            status = "กำลังดำเนินการ";
            color = "#376FD0";
            break;
          case "waiting_for_confirm":
            status = "รอยืนยัน";
            color = "#767676";
            break;
          case "success":
            status = "สำเร็จ";
            color = "#4CAF4F";
            break;
          case "canceled":
            status = "ยกเลิก";
            color = "#F34336";
            break;
          default:
            color = "#767676";
            break;
        }
        return { title: status, color: color };
      } else {
        switch (order.status) {
          case "waiting_for_payment":
            status = "รอโอนเงิน";
            color = "#FF9800";
            break;
          case "in_progress":
            status = "กำลังดำเนินการ";
            color = "#376FD0";
            break;
          case "waiting_for_confirm":
            status = "รอยืนยัน";
            color = "#767676";
            break;
          case "success":
            status = "สำเร็จ";
            color = "#4CAF4F";
            break;
          case "canceled":
            status = "ยกเลิก";
            color = "#F34336";
            break;
          default:
            color = "#767676";
            break;
        }
        return { title: status, color: color };
      }
    }, [order, JSON.stringify(values)]);

    const deliverStatus = useMemo(() => {
      let color = "";
      let status = "";

      const { orderDemands = [] } = values || {};

      const orderDemandData = orderDemands[0] || {};

      status = orderDemandData?.status;

      switch (status) {
        case "in_progress":
          status = "กำลังดำเนินการ";
          color = "#376FD0";
          break;
        case "waiting_for_payment":
          status = "รอโอนเงิน";
          color = "#FF9800";
          break;
        case "success":
          status = "สำเร็จ";
          color = "#4CAF4F";
          break;
        default:
          color = "#376FD0";
          break;
      }
      return { title: status, color: color };
    }, [values]);

    const closeModal = useCallback(
      ({ close }) => {
        close();
        if (type === "Shipper") {
          paths.shipperOrderEditPath(id).push();
        } else if (type === "Admin") {
          paths.orderEditPath(id).push();
        }
        paths.orderEditPath(id).push();
      },
      [id, type]
    );

    const handleFactoryReceive = useCallback(
      (editedDeliveryDemandData) => {
        const orderDemand =
          order.orderDemands && order.orderDemands.length > 0
            ? order.orderDemands[0]
            : [];

        const {
          gradeAFishDeliveredWeight = 0,
          gradeBFishDeliveredWeight = 0,
          gradeCFishDeliveredWeight = 0,
          gradeDFishDeliveredWeight = 0,
          fishDeliveryImage,
          paymentSlipImage,
        } = editedDeliveryDemandData || {};

        const customFishDeliverWeight = [
          { grade: "A", weight: gradeAFishDeliveredWeight },
          { grade: "B", weight: gradeBFishDeliveredWeight },
          { grade: "C", weight: gradeCFishDeliveredWeight },
          { grade: "D", weight: gradeDFishDeliveredWeight },
        ];

        const hasSomeDeliverWeight = some(
          customFishDeliverWeight,
          (item) => item.weight && item.weight !== 0
        );

        const orderDemandStatus =
          paymentSlipImage && paymentSlipImage.length > 0
            ? "success"
            : fishDeliveryImage && fishDeliveryImage.length > 0
            ? "waiting_for_payment"
            : "in_progress";

        const customValues = {
          ...values,
          fishDeliverImages:
            fishDeliveryImage && fishDeliveryImage.length > 0
              ? fishDeliveryImage
              : orderDemand?.fishDeliverImages ?? [],
          paymentSlipImages:
            paymentSlipImage && paymentSlipImage.length > 0
              ? paymentSlipImage
              : orderDemand?.paymentSlipImages ?? [],
          orderDemands: [
            {
              ...values.orderDemands[0],
              gradeAFishDeliveredWeight,
              gradeBFishDeliveredWeight,
              gradeCFishDeliveredWeight,
              gradeDFishDeliveredWeight,
              fishDeliverImages:
                fishDeliveryImage && fishDeliveryImage.length > 0
                  ? fishDeliveryImage
                  : orderDemand?.fishDeliverImages ?? [],
              paymentSlipImages:
                paymentSlipImage && paymentSlipImage.length > 0
                  ? paymentSlipImage
                  : orderDemand?.paymentSlipImages ?? [],
              status: hasSomeDeliverWeight ? orderDemandStatus : "in_progress",
            },
          ],
        };

        setValues(customValues);
      },

      [values, setValues, order]
    );

    const openDeliverFishToFactoryModal = useCallback(() => {
      let netProfit = 0;

      function calculateNetProfit(data) {
        netProfit = data;
      }

      const { orderSupplies = [], orderDemands } = values || {};
      const orderDemandData = orderDemands[0] || {};

      Modal.open({
        className: "DeliverFishModal",
        title: "แก้ไขการจัดส่งปลาให้โรงงาน",
        children: (
          <DeliverFishToFactoryModal
            factoryFishDemandData={factoryFishDemandData}
            factoryAddress={factoryAddress}
            orderSupplies={orderSupplies}
            orderDemandData={orderDemandData}
            id={id}
            orderStatus={orderStatus}
            deliverStatus={deliverStatus}
            type={type}
            onCalculateNetProfit={calculateNetProfit}
          />
        ),
        cancelButtonLabel: "ยกเลิก",
        okButtonLabel: "ยืนยัน",
        onClose: async ({ close }) => {
          close();
        },
        onCancel: async ({ close }) => {
          close();
        },
        onOk: async ({ close, ...rest }) => {
          const { values } = rest || {};

          const {
            gradeAFishDeliveredWeight = 0,
            gradeBFishDeliveredWeight = 0,
            gradeCFishDeliveredWeight = 0,
            gradeDFishDeliveredWeight = 0,
            fishDeliveryImage = [],
            paymentSlipImage = [],
          } = values || {};

          const customFishDeliverWeight = [
            { grade: "A", weight: gradeAFishDeliveredWeight },
            { grade: "B", weight: gradeBFishDeliveredWeight },
            { grade: "C", weight: gradeCFishDeliveredWeight },
            { grade: "D", weight: gradeDFishDeliveredWeight },
          ];

          const hasSomeDeliverWeight = some(
            customFishDeliverWeight,
            (item) => item.weight && item.weight !== 0
          );

          if (fishDeliveryImage.length === 0 || !hasSomeDeliverWeight) {
            Modal.alert({
              title: "เกิดข้อผิดพลาด",
              children: (
                <Box>
                  <Typography color="Text/Dark Grey" mb={2}>
                    กรุณากรอกข้อมูลต่อไปนี้ให้ครบ
                  </Typography>
                  <Typography color="Other/Danger">
                    {!hasSomeDeliverWeight
                      ? " • ข้อมูลเกรดปลาที่โรงงานพิจารณารับซื้อ"
                      : ""}
                  </Typography>
                  <Typography color="Other/Danger">
                    {fishDeliveryImage.length === 0
                      ? " • หลักฐานการส่งปลาให้โรงงาน"
                      : ""}
                  </Typography>
                </Box>
              ),
              okButtonLabel: "ปิด",
            });
            return;
          }

          const fishDeliveryAmount =
            gradeAFishDeliveredWeight +
            gradeBFishDeliveredWeight +
            gradeCFishDeliveredWeight +
            gradeDFishDeliveredWeight;

          const grades = [];
          if (gradeAFishDeliveredWeight > 0) {
            grades.push("A");
          }

          if (gradeBFishDeliveredWeight > 0) {
            grades.push("B");
          }

          if (gradeCFishDeliveredWeight > 0) {
            grades.push("C");
          }

          if (gradeDFishDeliveredWeight > 0) {
            grades.push("D");
          }

          const orderDeliveryResult = {
            fishDeliveryAmount,
            grades: grades[0],
            fishDeliveryImage,
            paymentSlipImage,
            netProfit,
            gradeAFishDeliveredWeight,
            gradeBFishDeliveredWeight,
            gradeCFishDeliveredWeight,
            gradeDFishDeliveredWeight,
          };

          handleFactoryReceive(orderDeliveryResult);

          close();
        },
      });
    }, [
      factoryAddress,
      factoryFishDemandData,
      id,
      handleFactoryReceive,
      orderStatus,
      type,
      values,
      deliverStatus,
    ]);

    useEffect(() => {
      const {
        orderDemands,
        orderSupplies,
        fishDeliverImages,
        paymentSlipImages,
      } = values || {};

      let isReceiveFishFromFishermanSuccess = true;
      let isDeliverFishToFactorySuccess = true;

      orderSupplies.forEach((orderSupply) => {
        if (
          orderSupply.status !== "waiting_for_payment" &&
          orderSupply.status !== "success"
        ) {
          isReceiveFishFromFishermanSuccess = false;
        }
      });

      orderDemands.forEach((orderDemand) => {
        if (
          orderDemand.status !== "waiting_for_payment" &&
          orderDemand.status !== "success"
        ) {
          isDeliverFishToFactorySuccess = false;
        }
      });

      // const isReceiveFishFromFishermanSuccess = every(orderSupplies, [
      //   "status",
      //   "waiting_for_payment" && "success",
      // ]);
      // const isDeliverFishToFactorySuccess = every(orderDemands, [
      //   "status",
      //   "waiting_for_payment" && "sucecss",
      // ]);
      const hasFishDeliverImage =
        fishDeliverImages && fishDeliverImages.length > 0;

      let hasPayToFisherman = true;
      let hasPayToFactory = true;

      orderSupplies.forEach((orderSupply) => {
        if (orderSupply.status !== "success") {
          hasPayToFisherman = false;
        }
      });

      orderDemands.forEach((orderDemand) => {
        if (orderDemand.status !== "success") {
          hasPayToFactory = false;
        }
      });

      // const hasPayToFisherman = every(orderSupplies, ["status", "success"]);
      // const hasPayToFactory = every(orderDemands, ["status", "success"]);

      const hasPaymentSlipImage =
        paymentSlipImages && paymentSlipImages.length > 0;

      let orderStatus = "in_progress";

      if (
        isReceiveFishFromFishermanSuccess &&
        isDeliverFishToFactorySuccess &&
        hasFishDeliverImage
      ) {
        orderStatus = "waiting_for_payment";
      }

      if (hasPayToFisherman && hasPayToFactory && hasPaymentSlipImage) {
        orderStatus = "success";
      }

      setValues({ ...values, status: orderStatus });
    }, [JSON.stringify(values), setValues]);

    const cancel = useCallback(() => {
      if (order.status === "canceled" || !dirty) {
        paths.ordersPath().push();
        return;
      }

      Modal.open({
        className: "ConfirmLeavePage",
        title: "ยกเลิกการแก้ไขข้อมูล",
        children: `ข้อมูลจะไม่ถูกบันทึกและไม่สามารถกู้คืนได้`,
        cancelButtonLabel: "ปิด",
        okButtonLabel: "ยกเลิกการแก้ไขข้อมูล",
        onOk: async ({ close }) => {
          close();
          paths.ordersPath().push();
        },
      });
    }, [dirty, order.status]);

    const submit = useCallback(async () => {
      if (values.shipperId == null || values.orderSupplies.length < 1) {
        Modal.alert({
          title: "เกิดข้อผิดพลาด",
          children: (
            <Box>
              <Typography color="Text/Dark Grey" mb={2}>
                กรุณากรอกข้อมูลต่อไปนี้ให้ครบ
              </Typography>
              <Box ml="8px">
                <Typography color="Other/Danger">
                  {values.orderSupplies.length < 1
                    ? " • ลำดับการรับปลาจากชาวประมง"
                    : ""}
                </Typography>
              </Box>
              <Box ml="8px">
                <Typography color="Other/Danger">
                  {values.shipperId == null
                    ? " • ข้อมูลพนักงานขนส่งที่รับผิดชอบ"
                    : ""}
                </Typography>
              </Box>
            </Box>
          ),
          okButtonLabel: "ปิด",
        });
        return;
      }

      let bookingIds = [];

      try {
        let sumAmount = 0;
        const supply = await Promise.all(
          values.orderSupplies.map(async (data, index) => {
            const supImage =
              data?.images && data?.images.length > 0
                ? await Promise.all(
                    data.images?.map(async (image) => {
                      if (image.url) {
                        const blob = await fetch(image.url).then((r) =>
                          r.blob()
                        );
                        const file = new File([blob], image.filename, {
                          type: blob.type,
                        });
                        return file;
                      } else {
                        return image;
                      }
                    })
                  )
                : [];

            let nextOrderSupplyStatus = "in_progress";

            if (data.status === "waiting_for_payment") {
              nextOrderSupplyStatus = data.status;
            } else if (data.status === "success") {
              nextOrderSupplyStatus = data.status;
            }

            const supplies = {
              id: data.bookingId ? data.id : null,
              orderId: id,
              bookingId: data.bookingId ?? data.id,
              status: nextOrderSupplyStatus,
              receivedSequence: index + 1,
              netProfit: data.netProfit,
              fishReceivedWeight: data.fishReceivedWeight,
              fishReceivedGrade: data.fishReceivedGrade,
              receivedByType:
                data.fishReceivedWeight === null ? null : "Shipper",
              receivedById:
                data.fishReceivedWeight === null ? null : values.shipperId,
              images: supImage,
            };

            bookingIds.push(data.bookingId);
            sumAmount += data.amount;
            return supplies;
          })
        );

        const orderDemand = values.orderDemands[0] || [];

        if (
          order.status === "waiting_for_confirm" &&
          sumAmount > orderDemand.factoryFishDemand.maxPurchaseVolume
        ) {
          Modal.alert({
            title: "เกิดข้อผิดพลาด",
            children: (
              <Box>
                <Typography color="Text/Dark Grey" mb={2}>
                  {"ปริมาณปลาที่รับซื้อมากกว่าที่โรงงานต้องการ (" +
                    toCurrency(sumAmount ?? 0, {
                      minimumFractionDigits: 0,
                    }) +
                    "/" +
                    toCurrency(
                      orderDemand.factoryFishDemand.maxPurchaseVolume ?? 0,
                      {
                        minimumFractionDigits: 0,
                      }
                    ) +
                    " kg) "}
                </Typography>
                <Typography color="Text/Dark Grey">
                  {"กรุณาแก้ไขการรับปลาจากชาวประมง"}
                </Typography>
              </Box>
            ),
            okButtonLabel: "ปิด",
            onOk: async ({ close }) => {
              close();
            },
          });
          return;
        }

        let summaryRawData = {
          purchasePrice: summaryData.purchasePrice,
          fuelPrice: summaryData.fuelPrice,
          icePrice: summaryData.icePrice,
          wage: summaryData.wage,
          sellingPrice: summaryData.sellingPrice,
          netProfit: summaryData.netProfit,
        };

        if (type === "Shipper") {
          const ids = compact(bookingIds);
          try {
            const summaryResponse = await calculateSummary({
              variables: {
                factoryFishDemandId: factoryFishDemandData.id,
                bookingIds: ids,
              },
            });
            const { data } = summaryResponse || {};
            const { calculateSummary: calculateSummaryData } = data || {};
            const { summary } = calculateSummaryData || {};

            summaryRawData = {
              purchasePrice: summary.purchasePrice,
              fuelPrice: summary.fuelPrice,
              icePrice: summary.icePrice,
              wage: summary.wage,
              sellingPrice: summary.sellingPrice,
              netProfit: summary.netProfit,
            };
          } catch (e) {
            console.log(e);
          }
        }

        let nextOrderDemandStatus = "in_progress";

        if (orderDemand?.status === "waiting_for_payment") {
          nextOrderDemandStatus = orderDemand?.status;
        } else if (orderDemand?.status === "success") {
          nextOrderDemandStatus = orderDemand?.status;
        }

        const currentOrderStatus =
          values.status === "waiting_for_payment" || values.status === "success"
            ? values.status
            : order.status;

        const fishDeliverImageFiles = await Promise.all(
          orderDemand?.fishDeliverImages.map(async (image) => {
            if (image.url) {
              const blob = await fetch(image.url).then((r) => r.blob());
              const file = new File([blob], image.filename, {
                type: blob.type,
              });
              return file;
            } else {
              return image;
            }
          })
        );

        const paymentSlipImageFiles = await Promise.all(
          orderDemand?.paymentSlipImages.map(async (image) => {
            if (image.url) {
              const blob = await fetch(image.url).then((r) => r.blob());
              const file = new File([blob], image.filename, {
                type: blob.type,
              });
              return file;
            } else {
              return image;
            }
          })
        );

        const mapDataUpdate = {
          id: id,
          shipperId: values.shipperId,
          status: currentOrderStatus,
          purchasePrice: summaryRawData.purchasePrice,
          fuelPrice: summaryRawData.fuelPrice,
          icePrice: summaryRawData.icePrice,
          wage: summaryRawData.wage,
          sellingPrice: summaryRawData.sellingPrice,
          netProfit: summaryRawData.netProfit,
          orderSupplies: supply,
          orderDemands: {
            status: nextOrderDemandStatus,
            gradeAFishDeliveredWeight: orderDemand?.gradeAFishDeliveredWeight,
            gradeBFishDeliveredWeight: orderDemand?.gradeBFishDeliveredWeight,
            gradeCFishDeliveredWeight: orderDemand?.gradeCFishDeliveredWeight,
            gradeDFishDeliveredWeight: orderDemand?.gradeDFishDeliveredWeight,
            // images: orderDemand?.images,
            // fishDeliverImages: orderDemand?.fishDeliverImages,
            fishDeliverImages: fishDeliverImageFiles,
            // paymentSlipImages: orderDemand?.paymentSlipImages,
            paymentSlipImages: paymentSlipImageFiles,
            deliveredByType: "Shipper",
            deliveredById: values.shipperId,
          },
        };

        await updateOrder({ variables: mapDataUpdate });
        notifySuccess("แก้ไขข้อมูลสำเร็จ");
        paths.ordersPath().push();
      } catch (e) {
        Modal.alert({
          title: "เกิดข้อผิดพลาด",
          children: `${getErrorMessage(e)}`,
          okButtonLabel: "ปิด",
          okButtonVariant: "button",
          onOk: closeModal,
        });
      }
    }, [
      values,
      summaryData,
      calculateSummary,
      id,
      order,
      factoryFishDemandData,
      type,
      updateOrder,
      closeModal,
    ]);

    const handleSelectNewBooking = useCallback(
      (value) => {
        const customOrderSupplies = value.map((item) => {
          if (item.bookingId) {
            return item;
          } else {
            const status =
              orderStatus.title === "กำลังดำเนินการ" ? "in_progress" : null;
            const { amount, fisherman, id } = item || {};
            const customOrderSupply = {
              id: null,
              bookingId: id,
              status: status,
              amount: amount,
              fisherman: fisherman,
            };
            return customOrderSupply;
          }
        });
        setValues({ ...values, orderSupplies: customOrderSupplies });
        setFishSuppliesData(customOrderSupplies);
      },
      [orderStatus, setValues, values]
    );

    useEffect(() => {
      const customOrderSupplies = values.orderSupplies.map((item) => {
        if (item.bookingId) {
          return item;
        } else {
          const status =
            orderStatus.title === "กำลังดำเนินการ" ? "in_progress" : null;
          const { amount, fisherman, id } = item || {};
          const customOrderSupply = {
            id: null,
            bookingId: id,
            status: status,
            amount: amount,
            fisherman: fisherman,
          };
          return customOrderSupply;
        }
      });
      setValues({ ...values, orderSupplies: customOrderSupplies });
      setFishSuppliesData(customOrderSupplies);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map(values.orderSupplies, "id").join("-")]);

    const handleFishReceive = useCallback(
      (editedSupplyData) => {
        const customOrderSupplies = values.orderSupplies.map((supply) => {
          if (supply.id === editedSupplyData.id) {
            return editedSupplyData;
          } else {
            return supply;
          }
        });

        setValues({ ...values, orderSupplies: customOrderSupplies });
        setFishSuppliesData(customOrderSupplies);
      },

      [values, setValues]
    );

    const handleCancelOrderClicked = useCallback(async () => {
      Modal.open({
        className: "ConfirmLeavePage",
        title: "ยกเลิกคำสั่งซื้อ",
        children: `ลบคำสั่งซื้อ${factoryFishDemandData?.fishName} ${toCurrency(
          factoryFishDemandData?.maxPurchaseVolume ?? 0,
          {
            minimumFractionDigits: 0,
          }
        )} กก. ส่งวันที่ ${formatDate(
          factoryFishDemandData?.fishDeliverDate,
          "dd/MM/yyyy"
        )}`,
        subChildren: `ข้อมูลจะไม่สามารถกู้คืนได้`,
        cancelButtonLabel: "ปิด",
        okButtonLabel: "ยกเลิกคำสั่งซื้อ",
        onOk: async ({ close }) => {
          try {
            const params = {
              id: id,
              status: order.status,
              orderSupplies: order.orderSupplies.map((data, index) => {
                const supplies = {
                  id: data.id,
                  orderId: id,
                  bookingId: data.bookingId,
                  status: data.status,
                };
                return supplies;
              }),
            };
            await cancelOrder({ variables: params });
            close();
            notify("แก้ไขข้อมูลสำเร็จ");
            paths.ordersPath().push();
          } catch (e) {
            await close();

            Modal.alert({
              title: "เกิดข้อผิดพลาด",
              children: `${getErrorMessage(e)}`,
              okButtonLabel: "ปิด",
              okButtonVariant: "button",
              onOk: async ({ close }) => {
                close();
              },
            });
          }
        },
      });
    }, [cancelOrder, order, id, factoryFishDemandData]);

    const handleAddressClick = useCallback(() => {
      window.open(factoryFishDemandData?.factory?.googleMapLink);
    }, [factoryFishDemandData]);

    const { totalFishDeliveryAmount, grade, netProfit } =
      fishDemandDeliveryData || {};

    const orderDemand = useMemo(() => {
      return {
        ...values?.orderDemands[0],
        fishDeliverImages: undefined,
        images: undefined,
        paymentSlipImages: undefined,
      };
    }, [values.orderDemands]);
    const orderSupplies = useMemo(() => {
      return values.orderSupplies.map((orderSupply) => ({
        ...orderSupply,
        images: undefined,
        fisherman: undefined,
      }));
    }, [values.orderSupplies]);
    return {
      breadcrumbs,
      cancel,
      factoryAddress,
      orderDemand,
      orderSupplies,
      shipperPerWeight,
      factoryFishDemandData,
      cancelButtonLabel: "ยกเลิก",
      submitButtonLabel: "บันทึก",
      submit,
      title,
      orderStatus,
      deliverStatus,
      openDeliverFishToFactoryModal,
      type,
      handleCancelOrderClicked,
      totalFishDemandAmount,
      totalFishDeliveryAmount,
      grade,
      netProfit,
      isFishSupplyListEmpty,
      setSummaryData,
      handleSelectNewBooking,
      handleFishReceive,
      handleFactoryReceive,
      handleAddressClick,
    };
  })
);
export default enhancer(OrderEditPage);

import { compose, withFormik, withHooks, defaultProps } from "enhancers";
import { PageContent } from "layouts";
import {
  Box,
  Grid,
  Typography,
  Form,
  Divider,
  Button,
  Hidden,
  Modal,
  Link,
  FieldArray,
} from "components";
import {
  gql,
  getErrorMessage,
  notifySuccess,
  paths,
  formatPhoneNumber,
  formatDate,
  toCurrency,
} from "utils/helper";
import { ReactComponent as WarningAmberIcon } from "assets/icon/warning_amber.svg";
import FactoryFishDemandModal from "./FactoryFishDemandModal";
import {
  PlaceOutlined as PlaceIcon,
  LocalPhoneOutlined as TelephoneIcon,
} from "@material-ui/icons";
import SummaryPrice from "components/advance/SummaryPrice";
import FishSupplies from "./FishSupplies";

const boxStyle = { background: "#F6F9FC", borderRadius: 8 };

const innerBoxStyle = { background: "#E7F4FD", borderRadius: 8 };

const placeIconStyle = { color: "#A1A1A1", width: 16, height: 16 };

const styleOverflow = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const linkStyle = {
  textDecoration: "underline",
  color: "#376FD0",
  ...styleOverflow,
};

export const OrderForm = (props) => (
  <Form>
    <Typography variant="h4" mb={2}>
      ข้อมูลคำสั่งซื้อจากโรงงาน
    </Typography>
    <Hidden when={props.factoryFishDemandData}>
      <Box style={boxStyle} p={8} mt={4}>
        <Box display="flex" justifyContent="center">
          <WarningAmberIcon fontSize="large" />
          <Typography variant="body1" color="Text/Dark Grey" ml={1}>
            ไม่มีข้อมูลคำสั่งซื้อจากโรงงาน แก้ไขคำสั่งซื้อจากโรงงาน
          </Typography>
        </Box>
      </Box>
    </Hidden>
    <Hidden when={!props.factoryFishDemandData}>
      <Box display="flex" mb={4}>
        <Grid item xs={9} mr={6}>
          <Box style={innerBoxStyle} p={4} mt={4}>
            <Box display="flex" mb={3}>
              <Typography variant="h6" color="Text/Black">
                {props.factoryFishDemandData?.factoryName}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" mb={1}>
              <Box mr={2}>
                <PlaceIcon style={placeIconStyle} />
              </Box>
              <Box display="flex" maxWidth="736px">
                <Link
                  component="button"
                  variant="body1"
                  color="Other/Info"
                  style={linkStyle}
                  onClick={props.handleAddressClick}
                >
                  {props.factoryFishDemandData?.address}
                </Link>
              </Box>
            </Box>
            <Box display="flex" mb={2}>
              <Box mr={2}>
                <TelephoneIcon style={placeIconStyle} />
              </Box>
              <Typography variant="body1" color="Text/Black">
                {formatPhoneNumber(
                  props.factoryFishDemandData?.factoryPhoneNumber
                )}
              </Typography>
            </Box>
            <Divider mt={3} mb={1} />
            <Box display="flex" mt={2}>
              <Typography variant="caption" color="Text/Black">
                {"หมายเหตุ : "}
                {props.factoryFishDemandData?.remark ?? "-"}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box style={innerBoxStyle} p={4} mt={4}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              mb={4}
            >
              <Typography variant="h6" color="Text/Black">
                {props.factoryFishDemandData?.fishName}
                {" - "}
                {formatDate(
                  props.factoryFishDemandData?.receiveDate,
                  "dd/MM/yyyy"
                )}
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              mb={4}
            >
              <Typography variant="h1" color="Other/Info">
                {toCurrency(props.factoryFishDemandData?.amount ?? 0, {
                  minimumFractionDigits: 0,
                })}
              </Typography>
              <Typography
                variant="caption"
                color="Text/Dark Grey"
                ml={1}
                mt={3}
              >
                {"kg"}
              </Typography>
            </Box>
            <Divider mt={5} mb={1} />
            <Box display="flex" justifyContent="space-between" mt={2}>
              <Typography variant="caption" color="Text/Dark Grey">
                ปริมาณขั้นต่ำ :
              </Typography>
              <Typography variant="caption" color="Text/Dark Grey">
                {toCurrency(
                  props.factoryFishDemandData?.minPurchaseVolume ?? 0,
                  {
                    minimumFractionDigits: 0,
                  }
                ) + " kg"}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Box>
    </Hidden>
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      fullWidth
      mt={6}
    >
      <Button
        color=""
        startIcon="edit"
        onClick={props.openFactoryFishDemandModal}
      >
        แก้ไขคำสั่งซื้อจากโรงงาน
      </Button>
    </Box>

    <FieldArray
      name="orderSupplies"
      component={FishSupplies}
      type={props.type}
      orderStatus={props.orderStatus}
      factoryFishDemandData={props.factoryFishDemandData}
      onSelect={props.setFishSuppliesData}
      isCreate
    />

    <SummaryPrice
      orderSupplies={props.orderSupplies}
      orderDemand={props.orderDemand}
      type={"Admin"}
      onResult={props.setSummaryData}
    />

    <Divider mt={10} />
    <Box display="flex" alignItems="center" fullWidth mb={6}>
      <Box display="flex" flex={1} flexDirection="row" fullWidth></Box>
      <Button color="" mt={6} mr={4} width={150} onClick={props.onCancel}>
        {props.cancelButtonLabel}
      </Button>
      <Button onClick={props.submit} color="primary" width={150} mt={6}>
        {props.submitButtonLabel}
      </Button>
    </Box>
  </Form>
);

const OrderNewPage = (props) => (
  <PageContent title={props.title} breadcrumbs={props.breadcrumbs}>
    <OrderForm {...props} />
  </PageContent>
);

const API = {
  FETCH_FISHERMEN: gql`
    query FETCH_FISHERMEN {
      fishermen {
        id
        code
        name
        phoneNumber
        lineId
        remark
        googleMapLink
        availableStatus
        address {
          address
          district
          subDistrict
          province
          zipCode
        }
      }
    }
  `,
  FETCH_SETTING: gql`
    query FETCH_SETTING {
      setting {
        id
        fuelConsumptionPerLitre
        fuelPricePerLitre
        iceUsagePct
        icePricePerKilo
        carryOnWeightPerEmployee
        shipperWagePerEmployeePerOrder
        fishyuOfficeGoogleMapUrl
      }
    }
  `,
  FETCH_FISHES: gql`
    query FETCH_FISHES {
      fishes {
        id
        name
      }
    }
  `,
  FETCH_FACTORIES: gql`
    query FETCH_FACTORIES {
      factories {
        id
        name
      }
    }
  `,
  CREATE_ORDER: gql`
    mutation CREATE_ORDER($order: CreateOrderArguments) {
      createOrder(input: { order: $order }) {
        order {
          code
        }
      }
    }
  `,
};

const enhancer = compose(
  defaultProps({}),
  withFormik({
    mapPropsToValues: () => ({
      fisherman: "",
      purchasePrice: 0,
      fuelPrice: 0,
      icePrice: 0,
      wage: 0,
      sellingPrice: 0,
      netProfit: 0,
    }),
  }),
  withHooks((props, hooks) => {
    const { useMemo, useMutation, useCallback, useQuery, useState } = hooks;
    const { values, setFieldValue } = props;

    const { data: factoryData } = useQuery(API.FETCH_FACTORIES);
    const { data: fishData } = useQuery(API.FETCH_FISHES);

    const [createOrder] = useMutation(API.CREATE_ORDER);
    const [factoryFishDemandData, setFactoryFishDemandData] = useState(null);
    const [fishSuppliesData, setFishSuppliesData] = useState(null);
    const [summaryData, setSummaryData] = useState({});

    const title = `สร้างคำสั่งซื้อเอง`;
    const breadcrumbs = useMemo(() => {
      return [
        { path: paths.homePath(), label: "หน้าแรก" },
        { path: paths.ordersPath(), label: "จัดการคำสั่งซื้อ" },
        { path: null, label: title },
      ];
    }, [title]);

    const calculateSumAmount = useCallback((fishSuppliesData) => {
      const sum = fishSuppliesData.reduce(
        (total, currentItem) => (total = total + currentItem?.amount),
        0
      );

      return sum;
    }, []);

    const openFactoryFishDemandModal = useCallback(() => {
      let selectedData = null;

      function setSelectedData(data) {
        selectedData = data;
      }

      Modal.open({
        className: "FactoryFishDemandModal",
        title: "แก้ไขคำสั่งซื้อจากโรงงาน",
        children: (
          <FactoryFishDemandModal
            factoryData={factoryData}
            fishData={fishData}
            setSelectedData={setSelectedData}
            factoryFishDemandData={factoryFishDemandData}
          />
        ),
        cancelButtonLabel: "ยกเลิก",
        okButtonLabel: "ยืนยัน",
        onClose: async ({ close }) => {
          close();
        },
        onCancel: async ({ close }) => {
          close();
        },
        onOk: async ({ close }) => {
          console.log("values", values);
          setFieldValue("orderSupplies", []);
          setFactoryFishDemandData(selectedData);
          close();
          notifySuccess("แก้ไขข้อมูลสำเร็จ");
        },
      });
    }, [
      setFactoryFishDemandData,
      factoryFishDemandData,
      factoryData,
      fishData,
      values,
    ]);

    const displayAlertModal = useCallback((e) => {
      Modal.alert({
        title: "เกิดข้อผิดพลาด",
        children: (
          <Typography color="Other/Danger">{getErrorMessage(e)}</Typography>
        ),
        okButtonLabel: "ปิด",
        okButtonVariant: "button",
        onOk: ({ close }) => {
          close();
          paths.orderNewPath().push();
        },
      });
    }, []);

    const onCancel = useCallback(async (params) => {
      paths.ordersPath().push();
    }, []);

    const submit = useCallback(async () => {
      const { orderSupplies: fishSuppliesData } = values || {};
      if (
        !factoryFishDemandData ||
        !fishSuppliesData ||
        fishSuppliesData.length === 0
      ) {
        Modal.alert({
          title: "เกิดข้อผิดพลาด",
          children: (
            <Box>
              <Typography color="Text/Dark Grey" mb={2}>
                {"กรุณากรอกข้อมูลต่อไปนี้ให้ครบ"}
              </Typography>
              <Typography color="Other/Danger">
                {!factoryFishDemandData ? " • ข้อมูลคำสั่งซื้อจากโรงงาน" : ""}
              </Typography>
              <Typography color="Other/Danger">
                {!fishSuppliesData || fishSuppliesData.length === 0
                  ? " • ลำดับการรับปลาจากชาวประมง"
                  : ""}
              </Typography>
            </Box>
          ),
          okButtonLabel: "ปิด",
          onOk: ({ close }) => {
            close();
            // paths.orderNewPath().push();
          },
        });
        return;
      }

      const sumAmount = calculateSumAmount(fishSuppliesData);

      if (sumAmount < factoryFishDemandData.minPurchaseVolume) {
        Modal.alert({
          title: "เกิดข้อผิดพลาด",
          children: (
            <Box>
              <Typography color="Text/Dark Grey" mb={2}>
                {"ปริมาณปลาที่รับซื้อต้องมากกว่า " +
                  toCurrency(factoryFishDemandData.minPurchaseVolume ?? 0, {
                    minimumFractionDigits: 0,
                  }) +
                  " กิโลกรัม "}
              </Typography>
              <Typography color="Text/Dark Grey">
                {"กรุณาแก้ไขลำดับการรับปลาจากชาวประมง"}
              </Typography>
            </Box>
          ),
          okButtonLabel: "ปิด",
          onOk: ({ close }) => {
            close();
            // paths.orderNewPath().push();
          },
        });
        return;
      }

      const orderSupplyList = fishSuppliesData.map((fishSupply, index) => {
        const customFishSupply = {
          bookingId: fishSupply.id,
          receivedSequence: index + 1,
        };

        return customFishSupply;
      });

      if (sumAmount > factoryFishDemandData.amount) {
        Modal.alert({
          title: "เกิดข้อผิดพลาด",
          children: (
            <Box>
              <Typography color="Text/Dark Grey" mb={2}>
                {"ปริมาณปลาที่รับซื้อมากกว่าที่โรงงานต้องการ (" +
                  toCurrency(sumAmount ?? 0, {
                    minimumFractionDigits: 0,
                  }) +
                  "/" +
                  toCurrency(factoryFishDemandData.amount ?? 0, {
                    minimumFractionDigits: 0,
                  }) +
                  " kg) "}
              </Typography>
              <Typography color="Text/Dark Grey">
                {"กรุณาแก้ไขการรับปลาจากชาวประมง"}
              </Typography>
            </Box>
          ),
          okButtonLabel: "ปิด",
          onOk: async ({ close }) => {
            close();
          },
        });
      } else {
        try {
          await createOrder({
            variables: {
              order: {
                purchasePrice: summaryData.purchasePrice,
                fuelPrice: summaryData.fuelPrice,
                icePrice: summaryData.icePrice,
                wage: summaryData.wage,
                sellingPrice: summaryData.sellingPrice,
                netProfit: summaryData.netProfit,
                orderDemands: {
                  factoryFishDemandId: factoryFishDemandData.id,
                },
                orderSupplies: orderSupplyList,
              },
            },
          });
          paths.ordersPath().push();
          notifySuccess("เพิ่มข้อมูลสำเร็จ");
        } catch (e) {
          displayAlertModal(e);
        }
      }
    }, [
      factoryFishDemandData,
      // fishSuppliesData,
      values,
      createOrder,
      displayAlertModal,
      calculateSumAmount,
      summaryData,
    ]);

    const handleAddressClick = useCallback(() => {
      window.open(factoryFishDemandData?.googleMapLink);
    }, [factoryFishDemandData]);

    const fishermanAvailableStatus = useMemo(() => {
      return [
        { label: "ใช้งาน", value: "true" },
        { label: "ไม่ใช้งาน", value: "false" },
      ];
    }, []);

    const orderDemand = useMemo(() => {
      return {
        factoryFishDemandId: factoryFishDemandData?.id,
      };
    }, [factoryFishDemandData]);
    const orderSupplies = useMemo(() => {
      return values.orderSupplies?.map((orderSupply) => ({
        bookingId: orderSupply.id,
      }));
    }, [values.orderSupplies]);
    return {
      title,
      breadcrumbs,
      cancelButtonLabel: "ยกเลิก",
      submitButtonLabel: "สร้างคำสั่งซื้อ",
      fishermanAvailableStatus,
      openFactoryFishDemandModal,
      factoryFishDemandData,
      onCancel,
      orderDemand,
      orderSupplies,
      setFishSuppliesData,
      submit,
      setSummaryData,
      handleAddressClick,
    };
  })
);

export default enhancer(OrderNewPage);

import compose from "enhancers/compose";
import { withFormik } from "formik";
import { withStateHandlers } from "recompose";

const withStateFormik = (config) =>
  compose(
    withStateHandlers(
      {},
      {
        setPropsToFormikBag: (formikBagProps) => (props) => ({
          ...formikBagProps,
          ...props,
        }),
      }
    ),
    withFormik({
      mapPropsToValues: () => ({}),
      validateOnMount: true,
      validateOnChange: true,
      ...config,
      handleSubmit: async (values, formikBag) => {
        const { handleSubmit } = formikBag.props;
        if (handleSubmit) {
          await handleSubmit(values);
        }
      },
    })
  );

export default withStateFormik;

import { compose, withFormik, withHooks, defaultProps } from "enhancers";
import { PageContent } from "layouts";
import {
  Box,
  Grid,
  Typography,
  Form,
  Field,
  TextField,
  RadioGroup,
  Address,
  BankAccount,
  Divider,
  Button,
  Hidden,
  Modal,
  Select,
} from "components";
import {
  gql,
  getErrorMessage,
  notifySuccess,
  notifyError,
  paths,
  Yup,
} from "utils/helper";
import { ReactComponent as PlaceIcon } from "assets/icon/place.svg";

export const FishermanForm = (props) => (
  <Form>
    <Box display="flex" alignItems="center" fullWidth mb={1}>
      <Box display="flex" flex={1} flexDirection="row" fullWidth>
        <Typography variant="h4" mb={6}>
          ข้อมูลชาวประมง
        </Typography>
      </Box>
      <Typography variant="body1" display="inline" mb={6}>
        สถานะ
      </Typography>
      <Field
        component={RadioGroup}
        name="fishermanStatus"
        options={props.fishermanAvailableStatus}
        row
        className="tax_payer_type_radio_group"
        mb={6}
      />
    </Box>
    <Grid container spacing={6} mb={3}>
      <Grid item xs={12}>
        <Field
          component={TextField}
          name="name"
          type="text"
          label="ชื่อ-นามสกุล"
          required
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextField.PhoneNumber}
          name="phoneNumber"
          label="เบอร์โทรศัพท์"
          required
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextField}
          name="lineId"
          type="text"
          label="ID LINE"
          fullWidth
        />
      </Grid>
    </Grid>

    <Field component={Address} name="address" title="ข้อมูลชาวประมง" />

    <Grid container spacing={6} mt={3}>
      <Grid item xs={12}>
        <Field
          component={TextField}
          name="remark"
          type="text"
          label="หมายเหตุ"
          fullWidth
          placeholder="เช่น อยู่ตรงข้ามเซเว่น"
        />
      </Grid>
      <Grid item xs={11}>
        <Field
          component={TextField}
          name="googleMapLink"
          type="text"
          label="Google map link"
          required
          fullWidth
        />
      </Grid>
      <Grid item xs={1}>
        <Hidden when={!props.googleMapLink}>
          <Button
            color="primary"
            variant="outlined"
            onClick={props.handleGoogleMapButtonClick}
            size="large"
            width={56}
            height={56}
          >
            <PlaceIcon />
          </Button>
        </Hidden>
        <Hidden when={props.googleMapLink}>
          <Button
            color="default"
            variant="outlined"
            size="large"
            width={56}
            height={56}
            disabled
          >
            <PlaceIcon />
          </Button>
        </Hidden>
      </Grid>
    </Grid>

    <Field
      component={Select}
      fullWidth
      name="affordableFish"
      label="ปลาที่จับได้"
      options={props.fishOptions}
      multiple
      blurOnSelect={false}
      fast={false}
      mt={6}
    />

    <Field
      component={BankAccount}
      name="bankAccount"
      title="ข้อมูลบัญชีธนาคาร"
      isFisherman
      mt={10}
    />

    <Divider mt={10} />
    <Box display="flex" alignItems="center" fullWidth mb={6}>
      <Box display="flex" flex={1} flexDirection="row" fullWidth></Box>
      <Button color="" mt={6} onClick={props.handleCancel} width={160}>
        {props.cancelButtonLabel}
      </Button>
      <Box width={24}></Box>
      <Button onClick={props.submit} color="primary" mt={6} width={160}>
        {props.submitButtonLabel}
      </Button>
    </Box>
  </Form>
);

const FishermanNewPage = (props) => (
  <PageContent title={props.title} breadcrumbs={props.breadcrumbs}>
    <FishermanForm
      fishermanAvailableStatus={props.fishermanAvailableStatus}
      googleMapLink={props.googleMapLink}
      handleGoogleMapButtonClick={props.handleGoogleMapButtonClick}
      handleCancel={props.handleCancel}
      submit={props.submit}
      submitButtonLabel={props.submitButtonLabel}
      cancelButtonLabel={props.cancelButtonLabel}
      fishOptions={props.fishOptions}
    />
  </PageContent>
);

const API = {
  FETCH_FISHES: gql`
    query FETCH_FISHES {
      fishes {
        id
        code
        name
        factoryPurchasePricePct
        remark
        availableStatus
      }
    }
  `,
  CREATE_FISHERMAN: gql`
    mutation CREATE_FISHERMAN(
      $name: String
      $phoneNumber: String
      $lineId: String
      $remark: String
      $googleMapLink: String
      $availableStatus: Boolean
      $affordableFish: [String!]
      $address: AddressArguments
      $bankAccount: BankAccountArguments
    ) {
      createFisherman(
        input: {
          name: $name
          phoneNumber: $phoneNumber
          lineId: $lineId
          remark: $remark
          googleMapLink: $googleMapLink
          availableStatus: $availableStatus
          affordableFish: $affordableFish
          address: $address
          bankAccount: $bankAccount
        }
      ) {
        fisherman {
          id
          name
        }
      }
    }
  `,
};

const enhancer = compose(
  defaultProps({
    fishermanAvailableStatus: [
      { label: "ใช้งาน", value: "true" },
      { label: "ไม่ใช้งาน", value: "false" },
    ],
  }),
  withFormik({
    mapPropsToValues: () => ({
      fishermanStatus: "true",
    }),
    validationSchema: Yup.object().shape({
      name: Yup.string().required("ต้องไม่เว้นว่างไว้"),
      phoneNumber: Yup.string().required("ต้องไม่เว้นว่างไว้"),
      googleMapLink: Yup.string().required("ต้องไม่เว้นว่างไว้"),
      address: Yup.object().shape({
        address: Yup.string().required("ต้องไม่เว้นว่างไว้"),
        district: Yup.string().required("ต้องไม่เว้นว่างไว้"),
        subDistrict: Yup.string().required("ต้องไม่เว้นว่างไว้"),
        province: Yup.string().required("ต้องไม่เว้นว่างไว้"),
        zipCode: Yup.string().required("ต้องไม่เว้นว่างไว้"),
      }),
    }),
  }),
  withHooks((props, hooks) => {
    const { useMemo, useMutation, useCallback, useQuery } = hooks;
    const { values, errors, fishermanAvailableStatus } = props;
    const { loading, data, error, refetch } = useQuery(API.FETCH_FISHES);
    const [createFisherman] = useMutation(API.CREATE_FISHERMAN);

    const title = `เพิ่มชาวประมง`;
    const breadcrumbs = useMemo(() => {
      return [
        { path: paths.homePath(), label: "หน้าแรก" },
        { path: paths.fishermenPath(), label: "จัดการชาวประมง" },
        { path: null, label: title },
      ];
    }, [title]);

    const fishOptions = useMemo(() => {
      if (loading) {
        return null;
      }
      if (error) {
        const message = getErrorMessage(error);
        notifyError(message);
        return null;
      }

      const fishList = data.fishes.map((fb, index) => {
        return {
          label: fb.name,
          value: fb.id,
        };
      });

      return fishList;
    }, [loading, data, error]);

    const isDirty = useCallback((values) => {
      if (values.fishermanStatus === "false") return true;
      if (
        values.name ||
        values.phoneNumber ||
        values.lineId ||
        values.remark ||
        values.googleMapLink ||
        values.address.address ||
        values.address.district ||
        values.address.subDistrict ||
        values.address.province ||
        values.address.zipCode ||
        values.bankAccount.bankAccountName ||
        values.bankAccount.bankAccountNumber ||
        values.bankAccount.bankName !== undefined
      )
        return true;
      return false;
    }, []);

    const handleCancel = useCallback(() => {
      if (isDirty(values)) {
        Modal.open({
          title: "ยกเลิกการเพิ่มข้อมูล",
          children: (
            <Typography color="Other/Danger">
              ข้อมูลจะไม่ถูกบันทึกและไม่สามารถกู้คืนได้
            </Typography>
          ),
          cancelButtonLabel: "ปิด",
          okButtonLabel: "ยกเลิกการเพิ่มข้อมูล",
          onOk: ({ close }) => {
            close();
            paths.fishermenPath().push();
          },
        });
      } else {
        paths.fishermenPath().push();
      }
    }, [values, isDirty]);

    const submit = useCallback(async () => {
      values.availableStatus = values.fishermanStatus === "true";
      try {
        if (Object.keys(errors).length !== 0) {
          Modal.alert({
            title: "เกิดข้อผิดพลาด",
            children: (
              <Box>
                <Typography color="Text/Dark Grey" mb={2}>
                  กรุณากรอกข้อมูลต่อไปนี้ให้ครบ
                </Typography>
                <Typography color="Other/Danger" mt={1}>
                  {errors.name ? " • ชื่อ-นามสกุล" : ""}
                </Typography>
                <Typography color="Other/Danger">
                  {errors.phoneNumber ? "• เบอร์โทรศัพท์" : ""}
                </Typography>
                <Typography color="Other/Danger">
                  {errors.address && errors.address.address ? " • ที่อยู่" : ""}
                </Typography>
                <Typography color="Other/Danger">
                  {errors.address && errors.address.subDistrict
                    ? " • แขวง/ตำบล"
                    : ""}
                </Typography>
                <Typography color="Other/Danger">
                  {errors.address && errors.address.district
                    ? " • เขต/อำเภอ"
                    : ""}
                </Typography>
                <Typography color="Other/Danger">
                  {errors.address && errors.address.province
                    ? " • จังหวัด"
                    : ""}
                </Typography>
                <Typography color="Other/Danger">
                  {errors.address && errors.address.zipCode
                    ? " • รหัสไปรษณีย์"
                    : ""}
                </Typography>
                <Typography color="Other/Danger">
                  {errors.googleMapLink ? " • Google map link" : ""}
                </Typography>
              </Box>
            ),
            okButtonLabel: "ปิด",
          });
        } else {
          console.log("values", values);
          await createFisherman({ variables: values });
          paths.fishermenPath().push();
          notifySuccess("เพิ่มข้อมูลสำเร็จ");
        }
      } catch (e) {
        if (
          getErrorMessage(e) ===
          "Validation failed: Phone number has already been taken"
        ) {
          Modal.alert({
            title: "เกิดข้อผิดพลาด",
            children: (
              <Box>
                <Typography color="Text/Dark Grey" mb={2}>
                  {"ข้อมูลต่อไปนี้ต้องไม่ซ้ำกับที่มีอยู่ในระบบ"}
                </Typography>
                <Typography color="Other/Danger">
                  {" • เบอร์โทรศัพท์"}
                </Typography>
              </Box>
            ),
            okButtonLabel: "ปิด",
            okButtonVariant: "button",
            onOk: ({ close }) => {
              close();
              paths.fishermanNewPath().push();
            },
          });
        } else {
          Modal.alert({
            title: "เกิดข้อผิดพลาด",
            children: `${getErrorMessage(e)}`,
            okButtonLabel: "ปิด",
            okButtonVariant: "button",
            onOk: ({ close }) => {
              close();
              paths.fishermanNewPath().push();
            },
          });
        }
      }
    }, [values, errors, createFisherman]);

    const googleMapLink = values.googleMapLink;

    const handleGoogleMapButtonClick = useCallback(() => {
      window.open(googleMapLink, "_blank", "noreferrer");
    }, [googleMapLink]);

    console.log("fishOptions", fishOptions);

    return {
      title,
      breadcrumbs,
      cancelButtonLabel: "ยกเลิก",
      submitButtonLabel: "เพิ่มชาวประมง",
      submit,
      handleCancel,
      fishermanAvailableStatus,
      handleGoogleMapButtonClick,
      googleMapLink,
      fishOptions: fishOptions,
    };
  })
);

export default enhancer(FishermanNewPage);

import { compose, withHooks, withFormik, defaultProps } from "enhancers";
import { PageContent } from "layouts";
import { Modal, Typography, Box } from "components";
import {
  gql,
  notifyError,
  notifySuccess,
  paths,
  cleanTypename,
  getErrorMessage,
} from "utils/helper";
import { FishesForm } from "./new";

const FishEditPage = (props) => (
  <PageContent title={props.title} breadcrumbs={props.breadcrumbs}>
    <FishesForm {...props} />
  </PageContent>
);

const API = {
  FETCH_FISH: gql`
    query FETCH_FISH($id: ID!) {
      fish(id: $id) {
        id
        code
        name
        factoryPurchasePricePct
        remark
        availableStatus
      }
    }
  `,
  UPDATE_FISH: gql`
    mutation UPDATE_FISH(
      $id: String!
      $name: String
      $factoryPurchasePricePct: Float
      $remark: String
      $availableStatus: Boolean
    ) {
      updateFish(
        input: {
          id: $id
          name: $name
          factoryPurchasePricePct: $factoryPurchasePricePct
          remark: $remark
          availableStatus: $availableStatus
        }
      ) {
        fish {
          id
          availableStatus
          name
          factoryPurchasePricePct
          remark
        }
      }
    }
  `,
};

const enhancer = compose(
  defaultProps({
    fishAvailableStatus: [
      { label: "ใช้งาน", value: "true" },
      { label: "ไม่ใช้งาน", value: "false" },
    ],
  }),
  withFormik(),
  withHooks((props, hooks) => {
    const { setValues, values, fishAvailableStatus, errors } = props;
    const {
      useMemo,
      useQuery,
      useEffect,
      useParams,
      useMutation,
      useState,
      useCallback,
    } = hooks;

    const { id } = useParams();

    const { loading, data, error, refetch } = useQuery(API.FETCH_FISH, {
      variables: { id },
    });

    const [updateFish] = useMutation(API.UPDATE_FISH);
    const [oldValue, setOldValue] = useState();

    useEffect(() => {
      refetch();
    }, [refetch]);

    const fish = useMemo(() => {
      if (loading) {
        return null;
      }
      if (error) {
        const message = getErrorMessage(error);
        notifyError(message);
        paths.fishManagementPath().push();
        return null;
      }

      if (data.fish !== undefined) {
        setOldValue(data.fish);
      }
      return data.fish;
    }, [loading, data, error]);

    const title = fish?.code;

    const breadcrumbs = useMemo(() => {
      return [
        { path: paths.homePath(), label: "หน้าแรก" },
        { path: paths.fishManagementPath(), label: "จัดการปลา" },
        { path: null, label: title },
      ];
    }, [title]);

    useEffect(() => {
      if (fish) {
        const values = cleanTypename(fish);
        setValues(values);
        values.fishStatus = values.availableStatus.toString();
      }
    }, [setValues, fish]);

    const isDirty = useCallback(
      (values) => {
        if (
          values.fishStatus !== oldValue.availableStatus.toString() ||
          values.name !== oldValue.name ||
          values.factoryPurchasePricePct !== oldValue.factoryPurchasePricePct ||
          values.remark !== oldValue.remark
        ) {
          return true;
        } else {
          return false;
        }
      },
      [oldValue]
    );

    const cancelFish = useCallback(() => {
      if (isDirty(values)) {
        Modal.open({
          title: "ยกเลิกการแก้ไขข้อมูล",
          children: `ข้อมูลจะไม่ถูกบันทึกและไม่สามารถกู้คืนได้`,
          cancelButtonLabel: "ปิด",
          okButtonLabel: "ยกเลิกการแก้ไขข้อมูล",
          onOk: ({ close }) => {
            close();
            paths.fishManagementPath().push();
          },
        });
      } else {
        paths.fishManagementPath().push();
      }
    }, [isDirty, values]);

    const submit = useCallback(async () => {
      values.availableStatus = values.fishStatus === "true";
      try {
        if (Object.keys(errors).length !== 0) {
          Modal.alert({
            title: "เกิดข้อผิดพลาด",
            children: (
              <Box>
                <Typography color="Text/Dark Grey" mb={2}>
                  กรุณากรอกข้อมูลต่อไปนี้ให้ครบ
                </Typography>
                <Typography color="Other/Danger" mt={1}>
                  {errors.name ? " • ชื่อ" : ""}
                </Typography>
                <Typography color="Other/Danger" mt={1}>
                  {errors.factoryPurchasePricePct
                    ? " • ราคารับซื้อจากชาวประมง (คิดเป็น % จากราคาโรงงาน)"
                    : ""}
                </Typography>
              </Box>
            ),
            okButtonLabel: "ปิด",
          });
        } else {
          await updateFish({ variables: values });
          paths.fishManagementPath().push();
          notifySuccess("แก้ไขข้อมูลสำเร็จ");
        }
      } catch (e) {
        if (
          getErrorMessage(e) ===
          "Validation failed: Name has already been taken"
        ) {
          Modal.alert({
            title: "เกิดข้อผิดพลาด",
            children: (
              <Box>
                <Typography color="Text/Dark Grey" mb={2}>
                  {"ข้อมูลต่อไปนี้ต้องไม่ซ้ำกับที่มีอยู่ในระบบ"}
                </Typography>
                <Typography color="Other/Danger">{" • ชื่อ"}</Typography>
              </Box>
            ),
            okButtonLabel: "ปิด",
            okButtonVariant: "button",
            onOk: ({ close }) => {
              close();
            },
          });
        } else {
          Modal.alert({
            title: "เกิดข้อผิดพลาด",
            children: `${getErrorMessage(e)}`,
            okButtonLabel: "ปิด",
            okButtonVariant: "button",
            onOk: ({ close }) => {
              close();
            },
          });
        }
      }
    });

    return {
      title,
      breadcrumbs,
      cancelButtonLabel: "ยกเลิก",
      submitButtonLabel: "บันทึก",
      cancelFish,
      fishAvailableStatus,
      submit,
    };
  })
);

export default enhancer(FishEditPage);

import { compose, withHooks } from "enhancers";
import Alert from "@material-ui/lab/Alert";

const ErrorMessage = ({ errorMessage, ...alertProps }) =>
  errorMessage ? (
    <Alert severity="error" {...alertProps}>
      {errorMessage}
    </Alert>
  ) : null;

const enhancer = compose(
  withHooks((props, hooks) => {
    const { name, ...alertProps } = props;
    const { useMemo, useFormikContext } = hooks;
    const formikBag = useFormikContext();

    const errorMessage = useMemo(() => {
      return formikBag ? formikBag.errors[name] : null;
    }, [formikBag, name]);

    return {
      errorMessage,
      ...alertProps,
    };
  })
);

export default enhancer(ErrorMessage);

import { Backdrop, Grid, Typography } from "components/common";
import { Hidden, Modal } from "components";
import { compose, withHooks } from "enhancers";
import { gql, toCurrency, getErrorMessage } from "utils/helper";
import { isEmpty } from "lodash";

export const SummaryPriceComponent = (props) => (
  <Grid container mt={10}>
    <Grid item xs={7} />
    <Grid container xs={5}>
      <Grid container mb={4}>
        <Grid item xs={6} textAlign="right">
          ราคารับซื้อปลารวม
        </Grid>
        <Grid item xs={6} textAlign="right">
          {toCurrency(props.purchasePrice)}
        </Grid>
      </Grid>
      <Grid container mb={4}>
        <Grid item xs={6} textAlign="right">
          ค่าน้ำมันรวม
        </Grid>
        <Grid item xs={6} textAlign="right">
          {toCurrency(props.fuelPrice)}
        </Grid>
      </Grid>
      <Grid container mb={4}>
        <Grid item xs={6} textAlign="right">
          ค่าน้ำแข็งรวม
        </Grid>
        <Grid item xs={6} textAlign="right">
          {toCurrency(props.icePrice)}
        </Grid>
      </Grid>
      <Grid container mb={4}>
        <Grid item xs={6} textAlign="right">
          ค่าแรงพนักงานรวม
        </Grid>
        <Grid item xs={6} textAlign="right">
          {toCurrency(props.wage)}
        </Grid>
      </Grid>
      <Grid container mb={4}>
        <Grid item xs={6} textAlign="right">
          ราคาขายให้โรงงานรวม
        </Grid>
        <Grid item xs={6} textAlign="right">
          {toCurrency(props.sellingPrice)}
        </Grid>
      </Grid>
      <Hidden when={props.type !== "Admin"}>
        <Grid container mb={4}>
          <Grid item xs={6} textAlign="right">
            กำไรสุทธิ
          </Grid>
          <Grid item xs={6} textAlign="right">
            <Typography color="Other/Info" style={{ fontWeight: 600 }}>
              {toCurrency(props.netProfit)}
            </Typography>
          </Grid>
        </Grid>
      </Hidden>
    </Grid>
  </Grid>
);

const API = {
  CALCULATE_SUMMARY: gql`
    mutation CALCULATE_SUMMARY(
      $orderDemand: OrderDemandArguments
      $orderSupplies: [OrderSupplyArguments!]
    ) {
      calculateSummary(
        input: { orderDemand: $orderDemand, orderSupplies: $orderSupplies }
      ) {
        summary {
          purchasePrice
          fuelPrice
          icePrice
          wage
          sellingPrice
          netProfit
        }
      }
    }
  `,
};

const enhancer = compose(
  withHooks((props, hooks) => {
    const { useMutation, useEffect, useState, useFormikContext } = hooks;
    const [calculateSummary] = useMutation(API.CALCULATE_SUMMARY);
    const [summaryResult, setSummaryResult] = useState(null);
    const { onResult, type } = props || {};
    const formikBag = useFormikContext();

    const [isReady, setReady] = useState(false);

    useEffect(() => {
      setReady(true);
    }, []);

    useEffect(() => {
      if (isReady) {
        const getSummary = async () => {
          Backdrop.open({ delay: 500 });

          try {
            const res = await calculateSummary({
              variables: {
                orderDemand: props.orderDemand,
                orderSupplies: props.orderSupplies,
              },
            });
            if (res.data.calculateSummary.summary) {
              const summary = res.data.calculateSummary.summary;

              const result = {
                purchasePrice: summary.purchasePrice,
                fuelPrice: summary.fuelPrice,
                icePrice: summary.icePrice,
                wage: summary.wage,
                sellingPrice: summary.sellingPrice,
                netProfit: summary.netProfit,
              };

              setSummaryResult(result);

              if (onResult) {
                onResult(result);
              } else {
                formikBag.values.purchasePrice = summary.purchasePrice;
                formikBag.values.fuelPrice = summary.fuelPrice;
                formikBag.values.icePrice = summary.icePrice;
                formikBag.values.wage = summary.wage;
                formikBag.values.sellingPrice = summary.sellingPrice;
                formikBag.values.netProfit = summary.netProfit;
                formikBag.setValues(formikBag.values);
              }
            }
            Backdrop.close();
          } catch (error) {
            const isSpecificError = ["FactoryFishDemand not found"].includes(
              getErrorMessage(error)
            );

            const errorMessage = isSpecificError ? (
              <Typography color="Other/Danger">
                ความต้องการซื้อมีการเปลี่ยนแปลง กรุณาแก้ไขข้อมูลอีกครั้ง
              </Typography>
            ) : (
              `${getErrorMessage(error)}`
            );

            Backdrop.close();
            Modal.alert({
              title: "เกิดข้อผิดพลาด",
              children: errorMessage,
              okButtonLabel: "ปิด",
              onOk: async ({ close }) => {
                close();
              },
            });
          }
        };

        if (!isEmpty(props.orderSupplies) && !isEmpty(props.orderDemand)) {
          getSummary();
        } else {
          const result = {
            purchasePrice: 0,
            fuelPrice: 0,
            icePrice: 0,
            wage: 0,
            sellingPrice: 0,
            netProfit: 0,
          };
          setSummaryResult(result);

          if (onResult) {
            onResult(result);
          } else {
            formikBag.values.purchasePrice = 0;
            formikBag.values.fuelPrice = 0;
            formikBag.values.icePrice = 0;
            formikBag.values.wage = 0;
            formikBag.values.sellingPrice = 0;
            formikBag.values.netProfit = 0;
            formikBag.setValues(formikBag.values);
          }
        }
      }

      return () => {
        Backdrop.close();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.orderDemand, props.orderSupplies, isReady]);

    return { ...summaryResult, type };
  })
);

export default enhancer(SummaryPriceComponent);

import { combineReducers } from "redux";
import { createSlice, configureStore } from "@reduxjs/toolkit";

import deepmerge from "deepmerge";
import { get } from "lodash";

const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray;

const dataSlice = createSlice({
  name: "data",
  initialState: {},
  reducers: {
    mergeStoreData: (state, action) => {
      const { storeName, storeData } = action.payload;
      state[storeName] = deepmerge(state[storeName] || {}, storeData || {}, {
        arrayMerge: overwriteMerge,
        clone: false,
      });
    },
  },
});

const store = configureStore({
  reducer: combineReducers({
    data: dataSlice.reducer,
  }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export class BaseStore {
  constructor(storeName, initialStoreData = {}) {
    this.storeName = storeName;
    this.setState(initialStoreData);
  }

  get state() {
    return store.getState().data[this.storeName];
  }

  setState(storeData) {
    store.dispatch(
      dataSlice.actions.mergeStoreData({
        storeName: this.storeName,
        storeData: storeData,
      })
    );
  }

  $get(selector, defaultValue) {
    return get(this, selector, defaultValue);
  }
}

export default store;

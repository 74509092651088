import { compose, withHooks } from "enhancers";
import { PageContent } from "layouts";
import { Box, Table, Typography } from "components";
import { gql, paths } from "utils/helper";
import { ReactComponent as AddIcon } from "assets/icon/add-icon.svg";
import { ReactComponent as TrashIcon } from "assets/icon/pen-edit.svg";

const FishManagementIndexPage = (props) => (
  <PageContent
    title="จัดการปลา"
    breadcrumbs={props.breadcrumbs}
    pageActions={props.pageActions}
  >
    <Box width="100%" mb={-6}>
      <Typography variant="h4" mb={4}>
        รายการปลา
      </Typography>
      <Table
        columns={props.columns}
        rows={props.tableData}
        loading={props.loading}
        density="compact"
        autoHeight
        disableSelectionOnClick
        autoDetectInitialFilterOnUrlQueryParams
      />
    </Box>
  </PageContent>
);

export const API = {
  FETCH_FISHES: gql`
    query FETCH_FISHES {
      fishes {
        id
        code
        name
        factoryPurchasePricePct
        remark
        availableStatus
      }
    }
  `,
};

const enhancer = compose(
  withHooks((props, hooks) => {
    const { useQuery, useMemo, useEffect, useCallback } = hooks;
    const { loading, data, error, refetch } = useQuery(API.FETCH_FISHES);
    useEffect(() => {
      refetch();
    }, [refetch]);

    const breadcrumbs = useMemo(() => {
      return [
        { path: paths.homePath(), label: "หน้าแรก" },
        { path: null, label: "จัดการปลา" },
      ];
    }, []);

    const pageActions = useMemo(() => {
      return [
        {
          children: "เพิ่มปลา",
          startIcon: <AddIcon />,
          onClick: () => paths.fishNewPath().push(),
          color: "primary",
        },
      ];
    }, []);

    const handleEditFish = useCallback((params) => {
      const { id } = params.row;
      paths.fishEditPath(id).push();
    }, []);

    const columns = useMemo(() => {
      return [
        { width: 170, field: "code", headerName: "รหัสปลา" },
        { width: 300, field: "name", headerName: "ชื่อ" },
        {
          width: 170,
          field: "factoryPurchasePricePct",
          headerName: "ราคารับซื้อ(%)",
        },
        { width: 200, field: "remark", headerName: "หมายเหตุ" },
        {
          width: 115,
          field: "availableStatus",
          headerName: "สถานะ",
          type: "tag",
        },
        {
          width: 40,
          field: "actions",
          headerName: " ",
          filterable: false,
          sortable: false,
          type: "actions",
        },
      ];
    }, []);

    const initialTableData = useMemo(() => {
      return [];
    }, []);

    const tableData = useMemo(() => {
      if (loading || error) {
        return initialTableData;
      }

      return data.fishes.map((fish) => {
        const {
          id,
          code,
          availableStatus,
          factoryPurchasePricePct,
          name,
          remark,
        } = fish;
        return {
          id,
          code,
          name,
          remark,
          factoryPurchasePricePct,
          availableStatus: availableStatus ? "ใช้งาน" : "ไม่ใช้งาน",
          actions: [
            {
              Icon: TrashIcon,
              onClick: handleEditFish,
            },
          ],
        };
      });
    }, [loading, data, error, handleEditFish, initialTableData]);

    return {
      tableData,
      loading,
      breadcrumbs,
      pageActions,
      columns,
    };
  })
);

export default enhancer(FishManagementIndexPage);

import { compose, withHooks, withFormik } from "enhancers";
import { Box, Field, Select } from "components";
import { range, map, toString, isString } from "lodash";
import { format, parseISO, isValid, lastDayOfMonth, isEqual } from "date-fns";
import { sprintf, Yup } from "utils/helper";

const DateSelector = (props) => (
  <Box display="flex" {...props}>
    <Field
      component={Select}
      name="day"
      label="วันเกิด"
      width={`${(100 / (100 + 246 + 150)) * 100}%`}
      options={props.dayOptions}
    />
    <Box m={1} />
    <Field
      component={Select}
      name="month"
      label="เดือน"
      width={`${(246 / (100 + 246 + 150)) * 100}%`}
      options={props.monthOptions}
    />
    <Box m={1} />
    <Field
      component={Select}
      name="year"
      label="ปี"
      width={`${(150 / (100 + 246 + 150)) * 100}%`}
      options={props.yearOptions}
    />
  </Box>
);

const enhancer = compose(
  withFormik({
    validationSchema: Yup.object().shape({
      day: Yup.string().required(),
      month: Yup.string().required(),
      year: Yup.string().required(),
    }),
  }),
  withHooks((props, hooks) => {
    const { useMemo, useEffect } = hooks;
    const { setFieldValue, values, form, field } = props;

    const { year, month, day } = values;
    // const currentDate = useMemo(() => {
    //   const date = parseISO(
    //     sprintf("%d-%02d-%02d", year ?? 0, month ?? 0, day ?? 0)
    //   );
    //   return isValid(date) ? date : null;
    // }, [year, month, day]);

    const outerFieldName = field.name;
    const outerFieldValue = isString(field.value)
      ? parseISO(field.value)
      : field.value;
    const outerSetFieldValue = form.setFieldValue;

    const yearOptions = useMemo(() => {
      return map(range(1900, new Date().getFullYear() + 1), (i) => ({
        label: toString(i + 543),
        value: i,
      }));
    }, []);

    const monthOptions = useMemo(() => {
      return map(range(1, 12 + 1), (i) => ({
        label: [
          "มกราคม",
          "กุมภาพันธ์",
          "มีนาคม",
          "เมษายน",
          "พฤษภาคม",
          "มิถุนายน",
          "กรกฎาคม",
          "สิงหาคม",
          "กันยายน",
          "ตุลาคม",
          "พฤศจิกายน",
          "ธันวาคม",
        ][i - 1],
        value: i,
      }));
    }, []);

    const mockDate = parseISO(sprintf("%d-%02d-01", year ?? 0, month ?? 0));
    const lastDay = isValid(mockDate) ? lastDayOfMonth(mockDate).getDate() : 31;
    const dayOptions = useMemo(() => {
      return map(range(1, lastDay + 1), (i) => ({
        label: sprintf("%02d", i),
        value: i,
      }));
    }, [lastDay]);

    useEffect(() => {
      const adjustDay = day && day > lastDay ? lastDay : day;

      if (adjustDay !== day) {
        setFieldValue("day", adjustDay);
      }

      let adjustDate = parseISO(
        sprintf("%d-%02d-%02d", year ?? 0, month ?? 0, adjustDay ?? 0)
      );
      adjustDate = isValid(adjustDate) ? adjustDate : null;

      if (!isEqual(outerFieldValue, adjustDate)) {
        outerSetFieldValue(outerFieldName, adjustDate);
      }
    }, [
      outerSetFieldValue,
      outerFieldName,
      outerFieldValue,
      setFieldValue,
      day,
      year,
      month,
      lastDay,
    ]);

    const outerFieldValueString = outerFieldValue
      ? format(outerFieldValue, "dd/MM/yyyy")
      : "";
    useEffect(() => {
      if (!outerFieldValueString) {
        return;
      }

      let outerDay, outerMonth, outerYear;
      try {
        outerDay = outerFieldValue.getDate();
        outerMonth = outerFieldValue.getMonth() + 1;
        outerYear = outerFieldValue.getFullYear();
      } catch (e) {
        outerDay = null;
        outerMonth = null;
        outerYear = null;
      }

      setFieldValue("day", outerDay);
      setFieldValue("month", outerMonth);
      setFieldValue("year", outerYear);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [outerFieldValueString]);

    return {
      dayOptions,
      monthOptions,
      yearOptions,
    };
  })
);

export default enhancer(DateSelector);

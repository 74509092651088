/* eslint-disable */
import { useEffect } from "react";
import { compose, withHooks } from "enhancers";
import { useQuery, gql } from "@apollo/client";
import paths from "routes/paths";

import appStore from "stores/appStore";

import { Switch, Route, Redirect } from "components";
import { BlankLayout, MenuLayout } from "layouts";

import SignInPage from "pages/auth/SignIn";
import SetupPasswordPage from "pages/auth/SetupPassword";
import ResetPasswordPage from "pages/auth/ResetPassword";

import OrderIndexPage from "pages/main/orders";
import OrderNewPage from "pages/main/orders/new";
import AutomaticOrderNewPage from "pages/main/orders/new/automatic";
import OrderEditPage from "pages/main/orders/edit";

import ShipperOrderIndexPage from "pages/main/shippers/orders";

import FishermanIndexPage from "pages/main/fishermen";
import FishermanNewPage from "pages/main/fishermen/new";
import FishermanEditPage from "pages/main/fishermen/edit";

import FishManagementIndexPage from "pages/main/fish_management/index";
import FishNewPage from "pages/main/fish_management/new";
import FishEditPage from "pages/main/fish_management/edit";

// prettier-ignore
import BookingIndexPage from "pages/main/bookings/index";
import BookingNewPage from "pages/main/bookings/new";
import BookingEditPage from "pages/main/bookings/edit";

import FactoryIndexPage from "pages/main/factory/index";
import FactoryNewPage from "pages/main/factory/new";
import FactoryEditPage from "pages/main/factory/edit";

import ShipperIndexPage from "pages/main/shippers";
import ShipperNewPage from "pages/main/shippers/new";

import SettingsIndexPage from "pages/main/settings/index";

import ShipperEditPage from "pages/main/shippers/edit";

import FactoryOrderScheduleIndexPage from "pages/main/factory_order_schedules";
import FactoryOrderScheduleNewPage from "pages/main/factory_order_schedules/new";

import { isAfterGivenDate } from "utils/helper";
import { env } from "env";

FactoryOrderScheduleNewPage;
// prettier-ignore
const InitialPages = () => (
  <BlankLayout>
    <Switch>
      <Route path={paths.signInPath()} exact component={SignInPage} />
      <Redirect to={paths.signInPath()} />
    </Switch>
  </BlankLayout>
);

// prettier-ignore
const GuestPages = () => (
  <BlankLayout>
    <Switch>
      <Route path={paths.signInPath()} exact component={SignInPage} />
      <Route
        path={paths.setupPasswordPath()}
        exact
        component={SetupPasswordPage}
      />
      <Route
        path={paths.resetPasswordPath()}
        exact
        component={ResetPasswordPage}
      />
      <Route
        path={paths.factoriesPath()}
        exact
        layout={MenuLayout}
        component={FactoryIndexPage}
      />
      <Redirect to={paths.signInPath()} />
    </Switch>
  </BlankLayout>
);

const ADMIN_ROLES = ["Admin"];
const SELLER_ROLES = ["Seller"];

const shouldRedirectToOrder = isAfterGivenDate(env);

// prettier-ignore
const MainPages = () => (
  <Switch>
    <Route
      path={paths.fishermenPath()}
      exact
      layout={MenuLayout}
      component={FishermanIndexPage}
    />
    <Route
      path={paths.fishermanNewPath()}
      exact
      layout={MenuLayout}
      component={FishermanNewPage}
    />
    <Route
      path={paths.fishermanEditPath(":id")}
      exact
      layout={MenuLayout}
      component={FishermanEditPage}
    />
    <Route
      path={paths.fishManagementPath()}
      exact
      layout={MenuLayout}
      component={FishManagementIndexPage}
    />
    <Route
      path={paths.fishNewPath()}
      exact
      layout={MenuLayout}
      component={FishNewPage}
    />
    <Route
      path={paths.fishEditPath(":id")}
      exact
      layout={MenuLayout}
      component={FishEditPage}
    />
    <Route
      path={paths.factoriesPath()}
      exact
      layout={MenuLayout}
      component={FactoryIndexPage}
    />
    <Route
      path={paths.factoryNewPath()}
      exact
      layout={MenuLayout}
      component={FactoryNewPage}
    />
    <Route
      path={paths.factoryEditPath(":id")}
      exact
      layout={MenuLayout}
      component={FactoryEditPage}
    />

    <Route
      path={paths.shippersPath()}
      exact
      layout={MenuLayout}
      component={ShipperIndexPage}
    />
    <Route
      path={paths.shipperNewPath()}
      exact
      layout={MenuLayout}
      component={ShipperNewPage}
    />
    <Route
      path={paths.shipperEditPath(":id")}
      exact
      layout={MenuLayout}
      component={ShipperEditPage}
    />

    <Route
      path={paths.bookingsPath()}
      exact
      layout={MenuLayout}
      component={BookingIndexPage}
    />
    <Route
      path={paths.bookingsNewPath()}
      exact
      layout={MenuLayout}
      component={BookingNewPage}
    />
    <Route
      path={paths.bookingsEditPath(":id")}
      exact
      layout={MenuLayout}
      component={BookingEditPage}
    />

    <Route
      path={paths.factoryOrderSchedulePath()}
      exact
      layout={MenuLayout}
      component={FactoryOrderScheduleIndexPage}
    />
    <Route
      path={paths.factoryOrderScheduleNewPath()}
      exact
      layout={MenuLayout}
      component={FactoryOrderScheduleNewPage}
    />

    <Route
      path={paths.settingsPath()}
      exact
      layout={MenuLayout}
      component={SettingsIndexPage}
    />

    <Route
      path={paths.shippersPath()}
      exact
      layout={MenuLayout}
      component={ShipperIndexPage}
    />
    <Route
      path={paths.shipperNewPath()}
      exact
      layout={MenuLayout}
      component={ShipperNewPage}
    />
    <Route
      path={paths.shipperEditPath(":id")}
      exact
      layout={MenuLayout}
      component={ShipperEditPage}
    />

    <Route
      path={paths.bookingsPath()}
      exact
      layout={MenuLayout}
      component={BookingIndexPage}
    />
    <Route
      path={paths.bookingsNewPath()}
      exact
      layout={MenuLayout}
      component={BookingNewPage}
    />
    <Route
      path={paths.ordersPath()}
      exact
      layout={MenuLayout}
      component={OrderIndexPage}
    />
    <Route
      path={paths.orderNewPath()}
      exact
      layout={MenuLayout}
      component={OrderNewPage}
    />
    <Route
      path={paths.orderAutomaticNewPath()}
      exact
      layout={MenuLayout}
      component={AutomaticOrderNewPage}
    />
    <Route
      path={paths.orderEditPath(":id")}
      exact
      layout={MenuLayout}
      component={OrderEditPage}
    />

    <Redirect to={shouldRedirectToOrder ? paths.ordersPath() : paths.bookingsPath()} />
  </Switch>
);

// prettier-ignore
const ShipperPages = (props: any) =>
  props.isProfileDataInitialize ? (
    <Switch>
      <Route
        path={paths.shipperOrderPath()}
        exact
        layout={MenuLayout}
        component={ShipperOrderIndexPage}
      />
      <Route
        path={paths.shipperOrderEditPath(":id")}
        exact
        layout={MenuLayout}
        component={OrderEditPage}
      />
      <Redirect to={paths.shipperOrderPath()} />
    </Switch>
  ) : (
    <Switch>
      <Route
        path={paths.shipperOrderPath()}
        exact
        layout={MenuLayout}
        component={ShipperOrderIndexPage}
      />
      <Route
        path={paths.shipperOrderEditPath(":id")}
        exact
        layout={MenuLayout}
        component={OrderEditPage}
      />

      <Redirect to={paths.shipperOrderPath()} />
    </Switch>
  );

interface RoutesProps {
  initialized: boolean;
  hasFirstAdmin: boolean;
  isAuthorized: boolean;
  isAdmin: boolean;
  isProfileDataInitialize: boolean;
}

// prettier-ignore
const Routes = (props: RoutesProps) => {
  if (!props.initialized) {
    return null;
  } else if (!props.isAuthorized) {
    return <InitialPages />;
  } else if (props.initialized && !props.isAdmin) {
    return <ShipperPages />;
  } else {
    return <MainPages />;
  }
}

export const API = {
  GET_CURRENT_USER: gql`
    query GET_CURRENT_USER {
      currentUser {
        id
        phoneNumber
        ownerData {
          type
          admin {
            id
            firstName
            lastName
          }
          shipper {
            id
            name
          }
        }
      }
    }
  `,
};

const enhancer = compose(
  withHooks(() => {
    const currentUser = useQuery(API.GET_CURRENT_USER, {
      onCompleted: (data) => {
        appStore.setCurrentUser(data.currentUser);
      },
    });

    const initialized = !currentUser.loading;
    const isAuthorized = !currentUser.error && currentUser?.data?.currentUser;
    // const hasFirstAdmin = !!appInfo.data?.info?.hasFirstAdmin;
    const isAdmin = currentUser?.data?.currentUser?.ownerData?.type === "Admin";

    useEffect(() => {
      const $splashScreen = document.getElementById("splash-screen");
      if ($splashScreen) {
        const display = initialized ? "none" : "";
        $splashScreen.style.display = display;
      }
    }, [initialized]);

    return {
      isAdmin,
      initialized,
      isAuthorized,
      // hasFirstAdmin,
      // isProfileDataInitialize,
    };
  })
);

export default enhancer(Routes);

import { Box, Table, Typography, Alert, Hidden } from "components";
import { compose, withHooks, withFormik, defaultProps } from "enhancers";
import { find, reduce, compact } from "lodash";
import { gql, toCurrency } from "utils/helper";

const AddFishSuppliesModal = (props) => (
  <>
    <Typography variant="caption" color="Text/Dark Grey">
      สามารถเลือกได้หลายรายการ (ชนิดปลา,
      วันที่จัดส่งของแต่ละการจองคิวถูกกรองตามลักษณะคำสั่งซื้อเรียบร้อยแล้ว)
    </Typography>
    <Hidden when={!props.factoryFishDemandData}>
      <Box mt={3} mb={3}>
        <Hidden when={props.maxRemain === 0 || props.minRemain === 0}>
          <Alert severity="info">
            {"ต้องการ"}
            {props.factoryFishDemandData?.fishName ?? ""}
            {"อีก " +
              toCurrency(props.minRemain ?? 0, {
                minimumFractionDigits: 0,
              }) +
              " kg เพื่อสร้างคำสั่งซื้อ / " +
              toCurrency(props.maxRemain ?? 0, {
                minimumFractionDigits: 0,
              }) +
              " kg เพื่อให้คำสั่งซื้อสมบูรณ์"}
          </Alert>
        </Hidden>
        <Hidden
          when={
            props.maxRemain === 0 ||
            props.sumAmount < props.factoryFishDemandData?.minPurchaseVolume
          }
        >
          <Alert severity="info">
            {"ต้องการ"}
            {props.factoryFishDemandData?.fishName ?? ""}
            {"อีก " +
              toCurrency(props.maxRemain ?? 0, {
                minimumFractionDigits: 0,
              }) +
              " kg เพื่อให้คำสั่งซื้อสมบูรณ์"}
          </Alert>
        </Hidden>
        <Hidden when={props.sumAmount <= props.factoryFishDemandData?.amount}>
          <Alert severity="warning">
            {"ปริมาณปลาที่รับซื้อมากกว่าที่โรงงานต้องการ (" +
              toCurrency(props.sumAmount ?? 0, {
                minimumFractionDigits: 0,
              })}
            {"/" +
              toCurrency(props.factoryFishDemandData?.amount ?? 0, {
                minimumFractionDigits: 0,
              }) +
              " kg)"}
          </Alert>
        </Hidden>
      </Box>
    </Hidden>
    <Table
      className="FishSuppliesTable"
      columns={props.columns}
      rows={props.tableData}
      loading={props.loading}
      density="compact"
      autoHeight
      disableSelectionOnClick
      onSelectionModelChange={props.onSelectionChange}
      checkboxSelection={true}
      rowCount={props.rowCount}
      initialFilter={props.initialFilter}
    />
  </>
);

export const API = {
  FETCH_BOOKINGS: gql`
    query FETCH_BOOKINGS($filters: JSON) {
      bookingsByFishId(filters: $filters) {
        id
        code
        amount
        status
        maxPurchasePrice
        receiveDate
        fisherman {
          name
          phoneNumber
          googleMapLink
          availableStatus
          address {
            address
            district
            subDistrict
            province
            zipCode
          }
        }
        distanceFromUs
        distanceFromFactory
      }
    }
  `,
  FETCH_FISHES: gql`
    query FETCH_FISHES {
      fishes {
        id
        name
      }
    }
  `,
  FETCH_FACTORIES: gql`
    query FETCH_FACTORIES {
      factories {
        id
        name
      }
    }
  `,
};

const enhancer = compose(
  defaultProps({
    initialFilter: {
      items: [],
    },
  }),
  withFormik({
    mapPropsToValues: () => ({}),
  }),
  withHooks((props, hooks) => {
    const { useQuery, useMemo, useEffect, useCallback, useState } = hooks;
    const {
      selectedSupplyData,
      factoryFishDemandData,
      onSelectFishSupply,
      initialFilter,
      isCreate,
    } = props;

    const { loading, data, error, refetch } = useQuery(API.FETCH_BOOKINGS, {
      variables: {
        filters: {
          fishId: factoryFishDemandData?.fishId,
          factoryFishDemandId: factoryFishDemandData?.id,
          factoryId:
            factoryFishDemandData?.factoryId ??
            factoryFishDemandData?.factory?.id,
          status: "waiting_for_confirm",
        },
      },
    });

    const [sumAmount, setSumAmount] = useState(0);
    const [oldSumAmount, setOldSumAmount] = useState(0);
    const [minRemain, setMinRemain] = useState(
      factoryFishDemandData?.minPurchaseVolume
    );
    const [maxRemain, setMaxRemain] = useState(factoryFishDemandData?.amount);

    useEffect(() => {
      let min = 0;
      let max = 0;
      const sum = reduce(
        selectedSupplyData,
        function (sum, item) {
          return sum + item.amount;
        },
        0
      );
      if (
        sum >= factoryFishDemandData?.minPurchaseVolume &&
        sum <= factoryFishDemandData?.amount
      ) {
        max = factoryFishDemandData?.amount - sum;
      }
      if (sum < factoryFishDemandData?.minPurchaseVolume) {
        min = factoryFishDemandData?.minPurchaseVolume - sum;
        max = factoryFishDemandData?.amount - sum;
      }
      setSumAmount(sum);
      setOldSumAmount(sum);
      setMinRemain(min);
      setMaxRemain(max);
    }, [
      selectedSupplyData,
      factoryFishDemandData?.minPurchaseVolume,
      factoryFishDemandData?.amount,
    ]);

    useEffect(() => {
      if (!factoryFishDemandData) {
        refetch({ filters: {} });
      } else {
        refetch();
      }
    }, [refetch, factoryFishDemandData]);

    const columns = useMemo(() => {
      return [
        { width: 170, field: "fishermanName", headerName: "ชื่อชาวประมง" },
        {
          width: 165,
          field: "amount",
          headerName: "หาปลาได้ (kg)",
          type: "currency",
        },
        {
          width: 185,
          field: "distanceFromUs",
          headerName: "ห่างจากเรา (km)",
          type: "currency",
        },
        {
          width: 215,
          type: "currency",
          field: "distanceFromFactory",
          headerName: "ห่างจากโรงงาน (km)",
        },
        {
          width: 145,
          type: "dateOnly",
          field: "receiveDate",
          headerName: "วันที่ส่งปลา",
        },
      ];
    }, []);

    // const initialOptions = useMemo(() => {
    //   return [];
    // }, []);

    // const factoryOptions = useMemo(() => {
    //   if (factoryLoading || factoryError) {
    //     return initialOptions;
    //   }

    //   return factoryData.factories.map((factory) => {
    //     const { id, name } = factory;

    //     const label = `${name}`;

    //     return {
    //       value: id,
    //       label,
    //     };
    //   });
    // }, [factoryLoading, factoryData, factoryError, initialOptions]);

    // const fishOptions = useMemo(() => {
    //   if (fishLoading || fishError) {
    //     return initialOptions;
    //   }

    //   return fishData.fishes.map((fish) => {
    //     const { id, name } = fish;

    //     return {
    //       value: id,
    //       label: name,
    //     };
    //   });
    // }, [fishLoading, fishData, fishError, initialOptions]);

    const sort = useMemo(() => {
      return [
        {
          value: "1",
          label: "จำนวนที่ต้องการจากมากไปน้อย ",
        },
        {
          value: "2",
          label: "จำนวนที่ต้องการจากน้อยไปมาก ",
        },
        {
          value: "3",
          label: "วันที่ต้องการจากล่าสุด ",
        },
        {
          value: "4",
          label: "วันที่ต้องการจากเก่าสุด ",
        },
      ];
    }, []);

    const initialTableData = useMemo(() => {
      return [];
    }, []);

    const tableData = useMemo(() => {
      if (loading || error) {
        return initialTableData;
      }

      const hasSelectedBooking =
        selectedSupplyData && selectedSupplyData.length > 0;

      const tableData = compact(
        data.bookingsByFishId.map((booking) => {
          const {
            id,
            amount,
            fisherman,
            distanceFromUs,
            distanceFromFactory,
            receiveDate,
          } = booking || {};
          const { name: fishermanName } = fisherman || {};

          if (hasSelectedBooking) {
            const isSelected = selectedSupplyData.find((selectedSupply) =>
              isCreate
                ? selectedSupply.id === id
                : selectedSupply.bookingId === id
            );

            if (isSelected) {
              return null;
            }
          }

          return {
            id,
            fishermanName,
            amount,
            distanceFromUs,
            distanceFromFactory,
            receiveDate,
          };
        })
      );

      return tableData;
    }, [loading, data, error, initialTableData, selectedSupplyData, isCreate]);

    const onSelectionChange = useCallback(
      async (ids) => {
        let newSumAmount = oldSumAmount;
        let newMinRemain = 0;
        let newMaxRemain = 0;
        let selectedDataList = [];

        ids.forEach((id) => {
          const selectedBooking = find(data.bookingsByFishId, { id: id });
          selectedDataList.push(selectedBooking);
          newSumAmount += selectedBooking.amount;
        });

        const { minPurchaseVolume = 0, amount = 0 } =
          factoryFishDemandData || {};

        if (newSumAmount >= minPurchaseVolume && newSumAmount <= amount) {
          newMaxRemain = amount - newSumAmount;
        }
        if (newSumAmount < minPurchaseVolume) {
          newMinRemain = minPurchaseVolume - newSumAmount;
          newMaxRemain = amount - newSumAmount;
        }
        if (newSumAmount > amount) {
        }

        setSumAmount(newSumAmount);
        setMinRemain(newMinRemain);
        setMaxRemain(newMaxRemain);
        onSelectFishSupply(selectedDataList);
      },
      [data, oldSumAmount, onSelectFishSupply, factoryFishDemandData]
    );

    return {
      tableData,
      onSelectionChange,
      sort,
      // loading: loading || factoryLoading || fishLoading,
      loading,
      factoryFishDemandData,
      sumAmount,
      minRemain,
      maxRemain,
      columns,
      initialFilter,
    };
  })
);

export default enhancer(AddFishSuppliesModal);

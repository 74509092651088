import { useEffect } from "react";
import { compose, mapProps } from "recompose";
import * as hooks from "hooks";

const withHooks = (mapHooksToProps) => {
  const enhancer = compose(
    mapProps((props) => {
      return mapHooksToProps(props, {
        ...hooks,
        useHandleSubmit(handleSubmit, dependencies) {
          useEffect(() => {
            props.setPropsToFormikBag({
              handleSubmit,
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, dependencies);
        },
        usePropsToFormikBag(mapPropsToFormikBag, dependencies) {
          useEffect(() => {
            if (props.setPropsToFormikBag && mapPropsToFormikBag) {
              props.setPropsToFormikBag(mapPropsToFormikBag());
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [props.setPropsToFormikBag, ...dependencies]);
        },
        useDebug() {
          hooks.useDebug(props);
        },
      });
    })
  );
  return enhancer;
};

export default withHooks;
